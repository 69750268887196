// Packages
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Images
import MenuImage from '../../assets/img/icons/menu-button.png';

// Helpers
import Api from '../../helpers/Api';

// Routes
import { HOME } from '../../routes';

// Navbar Items
import {
    HomeNavbarItems,
    LogoutNavbarItems,
    FrontendNavbarItems,
    PortalNavbarItems,
    AdminNavbarItems,
} from './navItems';

export default function AdminNavbar() {
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPageVisible, setMenuPageVisible] = useState(false);
    const [menuPortalVisible, setMenuPortalVisible] = useState(false);

    // Redux
    const dispatch = useDispatch();

    // Browser History
    const history = useHistory();

    function handleMenu() {
        setMenuVisible(!menuVisible);
    }

    function handleMenuPageToggle() {
        setMenuPageVisible(!menuPageVisible);
    }

    function handleMenuPortalToggle() {
        setMenuPortalVisible(!menuPortalVisible);
    }

    function handleLogout() {
        Api.get('/logout').then(function (response) {
            if (response.data.success) {
                dispatch({
                    type: 'UNSET_SESSION',
                });

                history.push(HOME);
            }
        });
    }

    return (
        <>
            <div id="page-navbar" className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div
                            id="hamburgerButton"
                            className="d-flex align-items-center justify-content-end my-3 pe-4"
                            onClick={handleMenu}
                        >
                            <span className="me-2 d-block fw-bold text-uppercase text-white">
                                Menu
                            </span>
                            <img
                                src={MenuImage}
                                alt=""
                                className="img-fluid d-block max-height-16"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="custom-draw"
                className={
                    menuVisible ? 'd-block bg-white border-start' : 'd-none'
                }
            >
                <div className="text-end">
                    <button
                        type="button"
                        id="customDrawClose"
                        className="btn btn-wide btn-link text-end"
                        onClick={handleMenu}
                    >
                        <i className="fas fa-times h4"></i>
                    </button>
                </div>

                <div>
                    {HomeNavbarItems &&
                        Object.keys(HomeNavbarItems).length > 0 &&
                        HomeNavbarItems.map(function (item, index) {
                            return (
                                <a
                                    key={index}
                                    href={item.route}
                                    className="tier-1"
                                >
                                    {item.title}
                                </a>
                            );
                        })}

                    <button
                        type="button"
                        className="tier-1"
                        onClick={handleMenuPageToggle}
                    >
                        Pages{' '}
                        <i
                            className={
                                menuPageVisible
                                    ? 'fas fa-angle-up'
                                    : 'fas fa-angle-down'
                            }
                        ></i>
                    </button>

                    <div className={menuPageVisible ? '' : 'collapse'}>
                        {FrontendNavbarItems &&
                            Object.keys(FrontendNavbarItems).length > 0 &&
                            FrontendNavbarItems.map(function (item, index) {
                                return (
                                    <a
                                        key={index}
                                        href={item.route}
                                        className="tier-2"
                                    >
                                        {item.title}
                                    </a>
                                );
                            })}
                    </div>

                    <button
                        type="button"
                        className="tier-1"
                        onClick={handleMenuPortalToggle}
                    >
                        Portal{' '}
                        <i
                            className={
                                menuPortalVisible
                                    ? 'fas fa-angle-up'
                                    : 'fas fa-angle-down'
                            }
                        ></i>
                    </button>

                    <div className={menuPortalVisible ? '' : 'collapse'}>
                        {PortalNavbarItems &&
                            Object.keys(PortalNavbarItems).length > 0 &&
                            PortalNavbarItems.map(function (item, index) {
                                return (
                                    <a
                                        key={index}
                                        href={item.route}
                                        className="tier-2"
                                    >
                                        {item.title}
                                    </a>
                                );
                            })}
                    </div>

                    {AdminNavbarItems &&
                        Object.keys(AdminNavbarItems).length > 0 &&
                        AdminNavbarItems.map(function (item, index) {
                            return (
                                <a
                                    key={index}
                                    href={item.route}
                                    className="tier-1"
                                >
                                    {item.title}
                                </a>
                            );
                        })}

                    {LogoutNavbarItems &&
                        Object.keys(LogoutNavbarItems).length > 0 &&
                        LogoutNavbarItems.map(function (item, index) {
                            return (
                                <button
                                    key={index}
                                    className="tier-1"
                                    onClick={handleLogout}
                                >
                                    {item.title}
                                </button>
                            );
                        })}
                </div>
            </div>
        </>
    );
}
