// Packages
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import MyStoreLogo from '../../../../components/Logos/MyStoreLogo';
import Navbar from '../../../../components/Navbar';

// Images
import MyStoreHeroImage from '../../../../assets/img/mystore/mystore-banner.jpg';

// Routes
import { GOOGLE_MAP, MYSTORE_INDEX } from '../../../../routes';

export default function MyStoreContactPage() {
    return (
        <>
            <Helmet>
                <title>MyStore Contact | Unilever Shopper</title>
                <body className="trader-app" />
            </Helmet>

            <Navbar />

            <MyStoreLogo />

            <section id="mystore-contact" className="bg-orange-medium">
                <div className="container mb-5">
                    <div className="row mb-5">
                        <div className="col-12 col-lg-8 offset-lg-2 text-center">
                            <a href={MYSTORE_INDEX}>
                                <img
                                    src={MyStoreHeroImage}
                                    alt="Spaza Shop"
                                    className="img-fluid mb-5"
                                />
                            </a>

                            <h1 className="mb-3 text-white">Contact Us</h1>

                            <p className="mb-0 text-white">
                                We'll help you find what you’re looking for.
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-3 offset-lg-3 text-center text-lg-start mb-5">
                            <h2 className="mb-4 text-white">Call Us</h2>
                            <p className="mb-1 text-white">
                                We're here to help.
                            </p>
                            <p className="h3 mb-2">
                                <a
                                    href="tel:+27860572861"
                                    className="text-white"
                                >
                                    086 057 2861
                                </a>
                            </p>
                            <p className="mb-0 mt-2 text-white">
                                Mon to Fri 8:00am - 4:30pm
                            </p>
                        </div>
                        <div className="col-12 col-lg-3 text-center text-lg-start mb-5">
                            <h2 className="mb-4 text-white">Write To Us</h2>
                            <p className="mb-0">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={GOOGLE_MAP}
                                    className="text-white"
                                >
                                    15 Nollsworth Crescent,
                                    <br />
                                    Umhlanga
                                    <br />
                                    Kwazulu-Natal
                                    <br />
                                    South Africa, 4051
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
