// Packages
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import DatePicker from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import ReactCrop from 'react-image-crop';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/PortalUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { PORTAL_DASHBOARD } from '../../../routes';

// Variables
let base64Image;

export default function PortalTillSlipCreate() {
    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [upImg, setUpImg] = useState();
    const [crop, setCrop] = useState({
        unit: '%',
        width: 100,
        height: 100,
    });
    const [completedCrop, setCompletedCrop] = useState(null);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);

    // Redux
    const dispatch = useDispatch();
    const retailerList = useSelector((state) => state.retailerList);
    const userStokvel = useSelector((state) => state.userStokvel);
    const userTrader = useSelector((state) => state.userTrader);

    // React Hook Form
    const { handleSubmit, register, reset, errors, control } = useForm({
        defaultValues: {
            image: '',
            retailer: '',
            entityType: '',
            entityName: '',
            dateOfPurchase: '',
        },
    });

    // Component Did Mount
    useEffect(() => {
        Api.get('tillslips/create')
            .then(function (response) {
                if (response.data.success) {
                    dispatch({
                        type: 'SET_RETAILER_LIST',
                        payload: response.data.data.retailers,
                    });

                    dispatch({
                        type: 'SET_USER_STOKVEL',
                        payload: response.data.data.user.stokvels,
                    });

                    dispatch({
                        type: 'SET_USER_TRADER',
                        payload: response.data.data.user.traders,
                    });
                }
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage(error.response.data.message);
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/tillslips', {
                imageBase64: base64Image,
                entityType: data.entityType,
                entityName: data.entityName,
                retailer: data.retailer,
                dateOfPurchase: data.dateOfPurchase,
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    }

                    reset();
                    setUpImg(null);
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    // Read File
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // Callback for image ref
    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    function generateDownload(canvas, crop) {
        if (!crop || !canvas) {
            return;
        }

        base64Image = canvas.toDataURL('image/jpeg', 1.0);
    }

    const dateMax = new Date();

    return (
        <>
            <Helmet>
                <title>Upload Till Slip | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="tillslips-create" className="container mb-5">
                <div className="row">
                    <div className="col-12">
                        <Title title="Upload Till Slip" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-3">
                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <div className="border upload-box">
                                    <label htmlFor="image">
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            className="form-control"
                                            multiple={false}
                                            accept="image/jpeg"
                                            onChange={onSelectFile}
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message:
                                                        'Please upload an image of a till slip.',
                                                },
                                            })}
                                        />
                                    </label>

                                    <div className="wrapper text-center">
                                        <span
                                            data-preset="blue"
                                            className="icon-add"
                                        ></span>
                                        <p className="mb-0">
                                            UPLOAD A<br />
                                            PHOTO OF YOUR
                                            <br />
                                            TILL SLIP
                                        </p>
                                    </div>
                                </div>

                                {upImg && (
                                    <div>
                                        <div className="border p-1 mt-4 mb-3">
                                            <p>
                                                Please crop your till slip here:
                                            </p>
                                            <ReactCrop
                                                ruleOfThirds
                                                src={upImg}
                                                onImageLoaded={onLoad}
                                                crop={crop}
                                                onChange={(c) => setCrop(c)}
                                                onComplete={(c) =>
                                                    setCompletedCrop(c)
                                                }
                                            />
                                        </div>

                                        <div className="border p-1 mb-3">
                                            <p>Till slip preview:</p>
                                            <canvas
                                                ref={previewCanvasRef}
                                                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                                style={{
                                                    width: Math.round(
                                                        completedCrop?.width ??
                                                            0
                                                    ),
                                                    height: Math.round(
                                                        completedCrop?.height ??
                                                            0
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}

                                {errors.image && (
                                    <div className="invalid-feedback">
                                        {errors.image.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="entityType"
                                    className="fw-bold form-label small"
                                >
                                    Are you a Stokvel or Trader? *
                                </label>

                                <select
                                    name="entityType"
                                    id="entityType"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Are you a Stokvel or Trader is required.',
                                        },
                                    })}
                                >
                                    <option value="" disabled>
                                        Choose
                                    </option>
                                    <option value="stokvel">Stokvel</option>
                                    <option value="trader">Trader</option>
                                </select>

                                {errors.entityType && (
                                    <div className="invalid-feedback">
                                        {errors.entityType.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="entityName"
                                    className="fw-bold form-label small"
                                >
                                    Please enter your Stokvel or Trader Name *
                                </label>

                                <input
                                    type="text"
                                    name="entityName"
                                    id="entityName"
                                    className="form-control"
                                    placeholder="Start typing and select your Stokvel or Trader"
                                    list="entityList"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Your Stokvel or Trader Name is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Your Stokvel or Trader Name  must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Your Stokvel or Trader Name  must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                <datalist id="entityList">
                                    {userStokvel &&
                                        userStokvel.map(function (item, index) {
                                            return (
                                                <option
                                                    key={index}
                                                    value={item.stokvel_name}
                                                >
                                                    {item.stokvel_name}
                                                </option>
                                            );
                                        })}
                                    {userTrader &&
                                        userTrader.map(function (item, index) {
                                            return (
                                                <option
                                                    key={index}
                                                    value={item.trader_name}
                                                >
                                                    {item.trader_name}
                                                </option>
                                            );
                                        })}
                                </datalist>

                                {errors.entityName && (
                                    <div className="invalid-feedback">
                                        {errors.entityName.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="retailer"
                                    className="fw-bold form-label small"
                                >
                                    Retailer *
                                </label>

                                <input
                                    type="text"
                                    name="retailer"
                                    id="retailer"
                                    className="form-control"
                                    placeholder="Start typing and select a retailer"
                                    list="retailerList"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: 'Retailer is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Retailer must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Retailer must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                <datalist id="retailerList">
                                    {retailerList &&
                                        retailerList.map(function (
                                            item,
                                            index
                                        ) {
                                            return (
                                                <option
                                                    key={index}
                                                    value={item.retailer_name}
                                                >
                                                    {item.retailer_name}
                                                </option>
                                            );
                                        })}
                                </datalist>

                                {errors.retailer && (
                                    <div className="invalid-feedback">
                                        {errors.retailer.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-5">
                                <label
                                    htmlFor="dateOfPurchase"
                                    className="fw-bold form-label small"
                                >
                                    Date of Purchase *
                                </label>

                                <Controller
                                    name="dateOfPurchase"
                                    className="form-control"
                                    control={control}
                                    valueName="selected"
                                    defaultValue={null}
                                    rules={{
                                        required: {
                                            value: true,
                                            message:
                                                'Date of Purchase is required.',
                                        },
                                    }}
                                    render={({ onChange, value }) => (
                                        <DatePicker
                                            className="form-control"
                                            id="dateOfPurchase"
                                            dateFormat="yyyy-MM-dd"
                                            maxDate={dateMax}
                                            showMonthDropdown
                                            showYearDropdown
                                            selected={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                {errors.dateOfPurchase && (
                                    <div className="invalid-feedback">
                                        {errors.dateOfPurchase.message}
                                    </div>
                                )}
                            </div>

                            <ResponseMessage
                                message={responseMessage}
                                status={responseStatus}
                            />

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Upload Till Slip"
                                    className="btn btn-wide btn-primary"
                                    onClick={() =>
                                        generateDownload(
                                            previewCanvasRef.current,
                                            completedCrop
                                        )
                                    }
                                />

                                <a
                                    href={PORTAL_DASHBOARD}
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
