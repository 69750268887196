// Packages
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Components
import StokvelRegisterLayout from './StokvelRegisterLayout';
import ResponseMessage from '../../../components/ResponseMessage';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { STOKVEL_LOGIN, UNILEVER_LEGAL } from '../../../routes';

const StokvelRegisterStep4 = (props) => {
    // Redux
    const stokvelRegister = useSelector((state) => state.stokvelRegister);
    const dispatch = useDispatch();

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // React Hook Form
    const { handleSubmit, register, errors, reset } = useForm();

    // Submit Form
    const onSubmit = (data) => {
        dispatch({
            type: 'SET_STOKVEL_REGISTER',
            payload: {
                ...stokvelRegister,
                confirmAge: data.confirmAge,
                confirmTerms: data.confirmTerms,
                optUnileverEmail: data.optUnileverEmail,
                optUnileverSms: data.optUnileverSms,
            },
        });

        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/register', {
                user_type: 'stokvel',
                password: stokvelRegister.password,
                user_first_name: stokvelRegister.firstName,
                user_last_name: stokvelRegister.lastName,
                email: stokvelRegister.emailAddress,
                mobile: stokvelRegister.mobileNumber,
                user_dob: stokvelRegister.dateOfBirth,
                user_gender_id: stokvelRegister.genderId,
                user_language_id: stokvelRegister.languageId,
                user_home_address: stokvelRegister.homeAddress,
                user_home_suburb: stokvelRegister.homeSuburb,
                user_home_city: stokvelRegister.homeCity,
                user_home_province_id: stokvelRegister.homeProvinceId,

                stokvel_role: stokvelRegister.stokvelRole,
                stokvel_name: stokvelRegister.stokvelName,
                stokvel_membership_count:
                    stokvelRegister.stokvelMembershipCount,
                stokvel_mobile: stokvelRegister.stokvelMobileNumber,

                user_referral_type_id: stokvelRegister.referralTypeId,
                user_agent_id: 1,
                user_confirmage: data.confirmAge ? 1 : 0,
                user_confirmterms: data.confirmTerms ? 1 : 0,
                user_optunilever_email: data.optUnileverEmail ? 1 : 0,
                user_optunilever_sms: data.optUnileverSms ? 1 : 0,
            })
                .then(function (response) {
                    dispatch({
                        type: 'UNSET_GENDER_LIST',
                    });

                    dispatch({
                        type: 'UNSET_GENDER_LIST',
                    });

                    dispatch({
                        type: 'UNSET_LANGUAGE_LIST',
                    });

                    dispatch({
                        type: 'UNSET_PROVINCE_LIST',
                    });

                    dispatch({
                        type: 'UNSET_REFERAL_TYPE_LIST',
                    });

                    dispatch({
                        type: 'UNSET_STOKVEL_LIST',
                    });

                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');

                        dispatch({
                            type: 'UNSET_STOKVEL_REGISTER',
                        });

                        reset();

                        props.history.push(STOKVEL_LOGIN);
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }
                })
                .catch(function (error) {
                    setResponseMessage(error.response.data.message);
                    setResponseStatus('error');
                });
        });
    };

    return (
        <>
            <StokvelRegisterLayout
                stepName="Confirmation"
                stepNumber="4"
                stepProgress="w-100"
            >
                <ResponseMessage
                    message={responseMessage}
                    status={responseStatus}
                />

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-4">
                        <div className="form-check">
                            <label
                                htmlFor="confirmAge"
                                className="form-check-label small "
                            >
                                <input
                                    type="checkbox"
                                    name="confirmAge"
                                    id="confirmAge"
                                    className="form-check-input"
                                    value={true}
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Please accept that you are over 18 years old.',
                                        },
                                    })}
                                />
                                <span className="fw-bold">Yes</span> - I confirm
                                that I am over 18 years old. *
                            </label>
                        </div>

                        {errors.confirmAge && (
                            <div className="invalid-feedback">
                                {errors.confirmAge.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <div className="form-check">
                            <label
                                htmlFor="optUnileverEmail"
                                className="form-check-label"
                            >
                                <input
                                    type="checkbox"
                                    name="optUnileverEmail"
                                    id="optUnileverEmail"
                                    className="form-check-input"
                                    value={true}
                                    ref={register}
                                />
                                <span className="fw-bold">Yes</span>, I want to
                                receive offers and updates from{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.unilever.co.za/brands/"
                                >
                                    Unilever Brands
                                </a>{' '}
                                via email and online advertising tailored to my
                                interest and preferences.
                            </label>
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="form-check">
                            <label
                                htmlFor="optUnileverSms"
                                className="form-check-label"
                            >
                                <input
                                    type="checkbox"
                                    name="optUnileverSms"
                                    id="optUnileverSms"
                                    className="form-check-input"
                                    value={true}
                                    ref={register}
                                />
                                <span className="fw-bold">Yes</span>, I want to
                                receive offers and updates from{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.unilever.co.za/brands/"
                                >
                                    Unilever Brands
                                </a>{' '}
                                via SMS and online advertising tailored to my
                                interest and preferences.
                            </label>
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="form-check">
                            <label
                                htmlFor="confirmTerms"
                                className="form-check-label small "
                            >
                                <input
                                    type="checkbox"
                                    name="confirmTerms"
                                    id="confirmTerms"
                                    className="form-check-input"
                                    value={true}
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Please accept that you agree to the Terms of Use.',
                                        },
                                    })}
                                />
                                <span className="fw-bold">Yes</span> - I accept
                                the{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={UNILEVER_LEGAL}
                                >
                                    Terms of Use
                                </a>
                                . *
                            </label>
                        </div>

                        {errors.confirmTerms && (
                            <div className="invalid-feedback">
                                {errors.confirmTerms.message}
                            </div>
                        )}
                    </div>

                    <ResponseMessage
                        message={responseMessage}
                        status={responseStatus}
                    />

                    <div className="mt-100 mb-270 text-center">
                        <a
                            href="/stokvel/register/3"
                            className="btn btn-wide btn-secondary btn-lg text-uppercase me-2"
                        >
                            <i className="fas fa-angle-left"></i> Previous
                        </a>

                        <input
                            type="submit"
                            value="Signup"
                            className="btn btn-wide btn-primary btn-lg text-uppercase"
                        />
                    </div>
                </form>
            </StokvelRegisterLayout>
        </>
    );
};

export default withRouter(StokvelRegisterStep4);
