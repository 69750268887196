// Packages
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import MyStoreLogo from '../../../../components/Logos/MyStoreLogo';
import Navbar from '../../../../components/Navbar';
import MyStoreMagazines from '../../../../components/MyStore/Magazines';

// Images
import MyStoreArticle2ImageMobile from '../../../../assets/img/mystore/mystore-article-002-banner-mobile.png';
import MyStoreArticle2ImageDesktop from '../../../../assets/img/mystore/mystore-article-002-banner-desktop.png';

export default function MyStoreArticle2Page() {
    return (
        <>
            <Helmet>
                <title>
                    COVID-19 and your business| MyStore | Unilever Shopper
                </title>
                <body className="trader-app" />
            </Helmet>

            <Navbar />

            <MyStoreLogo />

            <div id="mystore" className="bg-orange-medium">
                <section className="bg-orange-dark py-5">
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-12 text-center">
                                <picture>
                                    <source
                                        media="(max-width:320px)"
                                        srcSet={MyStoreArticle2ImageMobile}
                                        className="img-fluid"
                                    />
                                    <source
                                        media="(min-width:321px)"
                                        srcSet={MyStoreArticle2ImageDesktop}
                                        className="img-fluid"
                                    />
                                    <img
                                        src={MyStoreArticle2ImageDesktop}
                                        alt="COVID-19 and your business"
                                        className="img-fluid"
                                    />
                                </picture>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-lg-8 offset-lg-2 text-center text-white">
                                <h1 className="h1 text-center mb-3">
                                    COVID-19 and your business
                                </h1>

                                <p>
                                    Since the first case of the Covid-19 virus
                                    was confirmed in early March, the country
                                    began its national lockdown on the 27th
                                    March 2020. This caused all non-essential
                                    workers and businesses to close, until a
                                    phased re-opening is announced by the
                                    government.
                                </p>

                                <p>
                                    With many of your regular customers now
                                    forced to stay at home, your business may be
                                    quieter than usual as people are afraid of
                                    the virus and leaving their homes. Many
                                    people are now not working or may have lost
                                    their jobs which means they have less money
                                    than they usually do.
                                </p>

                                <h2 className="h3 my-3">
                                    <b>How can your business help?</b>
                                </h2>

                                <p>
                                    By taking advantage of specials and bulk
                                    buying from your suppliers which enable you
                                    to pass on a small saving or bulk offering
                                    to your customers. Basic food staples are in
                                    high demand along with cleaning products
                                    like soaps and bleaches as everyone is
                                    instructed to constantly wash their hands
                                    and keep surfaces clean.
                                </p>

                                <p className="mb-0">
                                    Why not consider running specials on
                                    Domestos, Lifebuoy, or Handy Andy to help do
                                    your part to aid your customers in the fight
                                    against Covid-19?
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5">
                    <div className="container">
                        <MyStoreMagazines />
                    </div>
                </section>
            </div>
        </>
    );
}
