// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';

// Helpers
import Api from '../../../helpers/Api';

export default function CmsTestimonialEdit() {
    // Redux
    const dispatch = useDispatch();

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [testimonials, setTestimonials] = useState([]);

    // Check URL
    const url = window.location.pathname;
    const urlParams = url.split('/');

    const { handleSubmit, register, reset, errors } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            testimonialName: '',
            testimonialSurname: '',
            testimonialGroupName: '',
            testimonialQuote: '',
            testimonialGroupType: '',
            testimonialIsFeatured: false,
            testimonialIsActive: false,
        },
    });

    // Component Did Mount
    useEffect(() => {
        Api.get('testimonials/' + urlParams[3] + '/edit')
            .then(function (response) {
                dispatch({
                    type: 'SET_TESTIMONIAL',
                    payload: response.data.data.testimonial,
                });
                setTestimonials(response.data.data.testimonial);
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage('Unable to retrieve the form data.');
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, [reset]);

    useEffect(() => {
        reset({
            testimonialName: testimonials.testimonial_first_name ?? '',
            testimonialSurname: testimonials.testimonial_last_name ?? '',
            testimonialGroupName: testimonials.testimonial_group_name,
            testimonialQuote: testimonials.testimonial_quote,
            testimonialGroupType: testimonials.testimonial_group_type,
            testimonialIsFeatured:
                testimonials.testimonial_is_featured === '1' ? true : false,
            testimonialIsActive:
                testimonials.testimonial_is_active === '1' ? true : false,
        });
        // eslint-disable-next-line
    }, [testimonials, reset]);

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('testimonials/' + urlParams[3], {
                _method: 'put',
                testimonial_first_name: data.testimonialName,
                testimonial_last_name: data.testimonialSurname,
                testimonial_group_name: data.testimonialGroupName,
                testimonial_group_type: data.testimonialGroupType,
                testimonial_quote: data.testimonialQuote,
                testimonial_is_featured: data.testimonialIsFeatured,
                testimonial_is_active: data.testimonialIsActive,
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Edit Testimonial | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="stokvel-edit" className="container mb-5">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label
                                    htmlFor="testimonialName"
                                    className="fw-bold form-label small"
                                >
                                    What is your first name?{' '}
                                </label>

                                <input
                                    type="text"
                                    name="testimonialName"
                                    id="testimonialName"
                                    className="form-control"
                                    placeholder="John"
                                    ref={register({
                                        minLength: {
                                            value: 2,
                                            message:
                                                'First Name must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'First Name must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.stokvelName && (
                                    <div className="invalid-feedback">
                                        {errors.testimonialName.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="testimonialSurname"
                                    className="fw-bold form-label small"
                                >
                                    What is your Surname?{' '}
                                </label>

                                <input
                                    type="text"
                                    name="testimonialSurname"
                                    id="testimonialSurname"
                                    className="form-control"
                                    placeholder="Doe"
                                    ref={register({
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Surname must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Surname must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.stokvelName && (
                                    <div className="invalid-feedback">
                                        {errors.testimonialSurname.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="testimonialGroupName"
                                    className="fw-bold form-label small"
                                >
                                    What is your Group Name?{' '}
                                </label>

                                <input
                                    type="text"
                                    name="testimonialGroupName"
                                    id="testimonialGroupName"
                                    className="form-control"
                                    placeholder="Doe"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: 'Group Name is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Group Name must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Group Name must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.stokvelName && (
                                    <div className="invalid-feedback">
                                        {errors.testimonialGroupName.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="testimonialGroupType"
                                    className="fw-bold form-label small"
                                >
                                    Are you a Stokvel or Trader? *
                                </label>

                                <input
                                    type="text"
                                    name="testimonialGroupType"
                                    id="testimonialGroupType"
                                    className="form-control"
                                    list="typeList"
                                    placeholder="Doe"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: 'Group Type is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Group Type must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Group Type must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                <datalist id="typeList">
                                    <option value="Stokvel">Stokvel</option>
                                    <option value="Trader">Trader</option>
                                </datalist>

                                {errors.stokvelName && (
                                    <div className="invalid-feedback">
                                        {errors.testimonialGroupType.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="testimonialQuote"
                                    className="fw-bold form-label small"
                                >
                                    Testimonial Quote
                                </label>
                                <textarea
                                    rows="5"
                                    name="testimonialQuote"
                                    id="testimonialQuote"
                                    className="form-control"
                                    ref={register()}
                                />
                            </div>

                            <div className="mb-4">
                                <div className="form-check">
                                    <label
                                        htmlFor="testimonialIsFeatured"
                                        className="form-check-label"
                                    >
                                        <input
                                            type="checkbox"
                                            name="testimonialIsFeatured"
                                            id="testimonialIsFeatured"
                                            className="form-check-input"
                                            value={true}
                                            ref={register}
                                        />
                                        Is Featured?
                                    </label>
                                </div>
                            </div>

                            <div className="mb-4">
                                <div className="form-check">
                                    <label
                                        htmlFor="testimonialIsActive"
                                        className="form-check-label"
                                    >
                                        <input
                                            type="checkbox"
                                            name="testimonialIsActive"
                                            id="testimonialIsActive"
                                            className="form-check-input"
                                            value={true}
                                            ref={register}
                                        />
                                        Is Active?
                                    </label>
                                </div>
                            </div>

                            <ResponseMessage
                                message={responseMessage}
                                status={responseStatus}
                            />

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Update Testimonial"
                                    className="btn btn-wide btn-primary"
                                />

                                <a
                                    href={'/cms/testimonials'}
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
