// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import Slider from 'react-slick';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

// Components
import Navbar from '../../../components/Navbar/AdminNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { CMS_DASHBOARD } from '../../../routes';

// Slider Configuration
const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    lazyLoad: true,
    infinite: false,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

export default function TillSlipEdit() {
    // Local State
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [tillSlipItems, setTillSlipItems] = useState([]);
    const [totalValue, setTotalValue] = useState(0.0);
    const [tillslip_is_approved, setTillslipIsApproved] = useState(false);
    const [tillslip_approved_note, setTillslipApprovedNote] = useState('');
    const [images, setImages] = useState([]);

    // Redux
    const dispatch = useDispatch();
    const productList = useSelector((state) => state.productList);
    const tillslip = useSelector((state) => state.tillslip);
    const tillslipItemsArrayLsit = useSelector(
        (state) => state.tillslipItemsArrayLsit
    );

    // Check URL
    const url = window.location.pathname;
    const urlParams = url.split('/');

    // React Hook Form
    const { handleSubmit, register, reset, errors } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            product: '',
            price: '',
            quantity: '',
            tillslipIsApproved: false,
            tillslipApprovedNote: '',
        },
    });

    // Component Did Mount
    useEffect(() => {
        Api.get('tillslips/' + urlParams[3] + '/edit')
            .then(function (response) {
                if (response.data.success) {
                    dispatch({
                        type: 'SET_PRODUCT_LIST',
                        payload: response.data.data.product,
                    });

                    dispatch({
                        type: 'SET_TILL_SLIP',
                        payload: response.data.data.tillslip,
                    });

                    dispatch({
                        type: 'SET_TILL_SLIP_ITEMS_ARRAY_LIST',
                        payload: response.data.data.tillSlipItemsArray,
                    });

                    if (response.data.data.tillslip.tillslip_is_approved) {
                        if (
                            response.data.data.tillslip.tillslip_is_approved ===
                            '0'
                        ) {
                            setTillslipIsApproved(false);
                        }

                        if (
                            response.data.data.tillslip.tillslip_is_approved ===
                            '1'
                        ) {
                            setTillslipIsApproved(true);
                        }
                    }

                    if (
                        response.data.data.tillslip.tillslip_approved_note ===
                            '' ||
                        response.data.data.tillslip.tillslip_approved_note ===
                            null
                    ) {
                        setTillslipApprovedNote('');
                    } else {
                        setTillslipApprovedNote(
                            response.data.data.tillslip.tillslip_approved_note
                        );
                    }

                    setImages(response.data.data.image);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage(error.response.data.message);
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, [reset]);

    // Set Reset()
    useEffect(() => {
        reset({
            tillslipIsApproved: tillslip_is_approved,
            tillslipApprovedNote: tillslip_approved_note,
        });
    }, [tillslip_is_approved, tillslip_approved_note, reset]);

    // Submit data to Server
    const submitToServer = () => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/tillslips/' + urlParams[3], {
                _method: 'put',
                tillslipItems: tillSlipItems,
                totalValue: totalValue,
                tillslip_is_approved: tillslip_is_approved,
                tillslip_approved_note: tillslip_approved_note,
            })
                .then(function (response) {
                    window.location.reload(true);
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    // Submit data to Server
    const submitToServerForDelete = (itemId) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/tillslipitem/' + itemId, {
                _method: 'delete',
            })
                .then(function (response) {
                    if (response.data.success) {
                        window.location.reload(true);
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    // Handle row edits
    const handleEdit = (index, data) => {
        reset({
            product: data.product,
            price: data.price,
            quantity: data.quantity,
        });

        let array = [...tillSlipItems];
        array.splice(index, 1);
        setTillSlipItems(array);
    };

    // Handle row deletion
    const handleDelete = (index) => {
        let array = [...tillSlipItems];
        array.splice(index, 1);
        setTillSlipItems(array);
    };

    const handleDeleteFromServer = (index, itemId) => {
        let array = [...tillslipItemsArrayLsit];
        array.splice(index, 1);
        submitToServerForDelete(itemId);
    };

    // Handle row addition
    const onSubmit = (data) => {
        const product = data.product;
        const price = parseFloat(data.price).toFixed(2);
        const quantity = parseInt(data.quantity);
        const total = parseFloat(price * quantity).toFixed(2);
        const dataObject = {
            product: product,
            price: price,
            quantity: quantity,
            total: total,
        };
        const mergeData = [...tillSlipItems, dataObject];
        setTillSlipItems(mergeData);
        reset({
            product: '',
            price: '',
            quantity: '',
        });
    };

    // Update Total Value
    useEffect(() => {
        let totalValueCalculate = 0.0;
        for (let i = 0; i < tillSlipItems.length; i++) {
            totalValueCalculate =
                parseFloat(totalValueCalculate) +
                parseFloat(tillSlipItems[i].total);
        }
        setTotalValue(totalValueCalculate);
    }, [tillSlipItems]);

    return (
        <>
            <Helmet>
                <title>Process Till Slip | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="tillslips-edit" className="container mb-5">
                <div className="row">
                    <div className="col-12">
                        <Title title="Process Till Slip" />
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-12 col-lg-6">
                        <Slider {...settings}>
                            {images &&
                                images.length > 0 &&
                                images.map(function (item, index) {
                                    return (
                                        <div className="slide" key={index}>
                                            <InnerImageZoom
                                                src={item}
                                                zoomSrc={item}
                                                className="img-fluid"
                                            />
                                        </div>
                                    );
                                })}
                        </Slider>
                    </div>

                    <div className="col-12 col-lg-6">
                        <p>
                            <span className="fw-bold">Purchase Date</span>:{' '}
                            {tillslip ? tillslip.tillslip_purchase_date : null}
                        </p>

                        <p>
                            <span className="fw-bold">Retailer</span>:{' '}
                            {tillslip ? tillslip.retailer.retailer_name : null}
                        </p>

                        <p>
                            <span className="fw-bold">Stokvel/Trader</span>:{' '}
                            {tillslip &&
                                tillslip.tillslip_stokvel_id &&
                                tillslip.stokvel.stokvel_name}
                            {tillslip &&
                                tillslip.tillslip_trader_id &&
                                tillslip.trader.trader_name}
                        </p>

                        <div className="mb-4 form-check">
                            <input
                                type="checkbox"
                                name="tillslipIsApproved"
                                id="tillslipIsApproved"
                                className="form-check-input"
                                value={true}
                                onChange={() =>
                                    setTillslipIsApproved(!tillslip_is_approved)
                                }
                                ref={register()}
                            />
                            <label
                                className="form-check-label fw-bold"
                                htmlFor="tillslipIsApproved"
                            >
                                Tillslip Approved
                            </label>
                        </div>

                        <div className="mb-4">
                            <label
                                htmlFor="tillslipApprovedNote"
                                className="fw-bold form-label small"
                            >
                                Disapprove Note
                            </label>
                            <input
                                type="textarea"
                                row="5"
                                name="tillslipApprovedNote"
                                id="tillslipApprovedNote"
                                className="form-control"
                                defaultValue={
                                    tillslip_approved_note === ''
                                        ? ''
                                        : tillslip_approved_note
                                }
                                onChange={(e) =>
                                    setTillslipApprovedNote(e.target.value)
                                }
                                ref={register()}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="mb-5"
                        >
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label
                                        htmlFor="product"
                                        className="fw-bold form-label small"
                                    >
                                        Product
                                    </label>
                                    <input
                                        type="text"
                                        name="product"
                                        id="product"
                                        className="form-control"
                                        list="productList"
                                        ref={register({
                                            required: {
                                                value: true,
                                                message: 'Product is required.',
                                            },
                                            minLength: {
                                                value: 2,
                                                message:
                                                    'Product must be between 2 and 191 characters.',
                                            },
                                            maxLength: {
                                                value: 191,
                                                message:
                                                    'Product must be between 2 and 191 characters.',
                                            },
                                        })}
                                    />

                                    <datalist id="productList">
                                        {productList &&
                                            productList.map(function (
                                                item,
                                                index
                                            ) {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={
                                                            item.product_name
                                                        }
                                                    >
                                                        {item.product_name}
                                                    </option>
                                                );
                                            })}
                                    </datalist>

                                    {errors.product && (
                                        <div className="invalid-feedback">
                                            {errors.product.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 col-lg-2">
                                    <label
                                        htmlFor="price"
                                        className="fw-bold form-label small"
                                    >
                                        Price Per Unit
                                    </label>
                                    <input
                                        type="text"
                                        name="price"
                                        id="price"
                                        className="form-control"
                                        ref={register({
                                            required: {
                                                value: true,
                                                message: 'Price is required.',
                                            },
                                            minLength: {
                                                value: 1,
                                                message:
                                                    'Price must be at least 1 digit.',
                                            },
                                            pattern: {
                                                value: /^[0-9.]+$/,
                                                message:
                                                    'Price is an invalid format.',
                                            },
                                        })}
                                    />

                                    {errors.price && (
                                        <div className="invalid-feedback">
                                            {errors.price.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 col-lg-2">
                                    <label
                                        htmlFor="quantity"
                                        className="fw-bold form-label small"
                                    >
                                        Quantity
                                    </label>
                                    <input
                                        type="text"
                                        name="quantity"
                                        id="quantity"
                                        className="form-control"
                                        ref={register({
                                            required: {
                                                value: true,
                                                message:
                                                    'Quantity is required.',
                                            },
                                            minLength: {
                                                value: 1,
                                                message:
                                                    'Quantity must be at least 1 digit.',
                                            },
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message:
                                                    'Quantity is an invalid format.',
                                            },
                                        })}
                                    />

                                    {errors.quantity && (
                                        <div className="invalid-feedback">
                                            {errors.quantity.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 col-lg-2">
                                    <input
                                        type="submit"
                                        value="Add"
                                        className="btn btn-primary mt-32"
                                    />
                                </div>
                            </div>
                        </form>

                        {tillSlipItems && tillSlipItems.length > 0 && (
                            <div className="table-responsive mb-5">
                                <table
                                    id="table-tillslips"
                                    className="table table-bordered table-sm"
                                >
                                    <thead className="table-light">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Product
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Price Per Unit
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Quantity
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Total
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                &nbsp;
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tillSlipItems &&
                                            Object.keys(tillSlipItems).length >
                                                0 &&
                                            tillSlipItems.map(function (
                                                item,
                                                index
                                            ) {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center align-middle">
                                                            {item.product}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            R{' '}
                                                            {parseFloat(
                                                                item.price
                                                            ).toFixed(2)}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {item.quantity}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            R{' '}
                                                            {parseFloat(
                                                                item.total
                                                            ).toFixed(2)}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    handleEdit(
                                                                        index,
                                                                        item
                                                                    )
                                                                }
                                                                className="btn"
                                                            >
                                                                <i className="fas fa-edit"></i>
                                                            </button>

                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        index
                                                                    )
                                                                }
                                                                className="btn"
                                                            >
                                                                <i className="fas fa-trash text-danger"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td
                                                className="text-center align-middle table-bg-grey"
                                                colSpan={3}
                                            >
                                                &nbsp;
                                            </td>
                                            <td className="text-center align-middle table-bg-grey fw-bold">
                                                R{' '}
                                                {parseFloat(totalValue).toFixed(
                                                    2
                                                )}
                                            </td>
                                            <td className="text-center align-middle table-bg-grey">
                                                &nbsp;
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        )}

                        {tillslipItemsArrayLsit &&
                            tillslipItemsArrayLsit.length > 0 && (
                                <div className="table-responsive mb-5">
                                    <table
                                        id="table-tillslips"
                                        className="table table-bordered table-sm"
                                    >
                                        <thead className="table-light">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Product
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Price Per Unit
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Quantity
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Total
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    &nbsp;
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tillslipItemsArrayLsit &&
                                                Object.keys(
                                                    tillslipItemsArrayLsit
                                                ).length > 0 &&
                                                tillslipItemsArrayLsit.map(
                                                    function (item, index) {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-center align-middle">
                                                                    {
                                                                        item.product
                                                                    }
                                                                </td>
                                                                <td className="text-center align-middle">
                                                                    R{' '}
                                                                    {parseFloat(
                                                                        item.price
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td className="text-center align-middle">
                                                                    {
                                                                        item.quantity
                                                                    }
                                                                </td>
                                                                <td className="text-center align-middle">
                                                                    R{' '}
                                                                    {parseFloat(
                                                                        item.total
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td className="text-center align-middle">
                                                                    <a
                                                                        href={
                                                                            '/cms/tillslipitem/' +
                                                                            item.itemId +
                                                                            '/edit'
                                                                        }
                                                                    >
                                                                        <i className="fa-fw fas fa-edit"></i>
                                                                    </a>

                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            handleDeleteFromServer(
                                                                                index,
                                                                                item.itemId
                                                                            )
                                                                        }
                                                                        className="btn"
                                                                    >
                                                                        <i className="fas fa-trash text-danger"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <div className="text-center">
                            <button
                                type="button"
                                className="btn btn-wide btn-primary"
                                onClick={submitToServer}
                            >
                                Save
                            </button>

                            <a
                                href={CMS_DASHBOARD}
                                className="btn btn-wide btn-secondary ms-2 text-uppercase"
                            >
                                Back
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
