// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import Navbar from '../../../components/Navbar/AdminNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { CMS_DASHBOARD } from '../../../routes';

export default function UserIndex() {
    // Redux
    const dispatch = useDispatch();
    const userList = useSelector((state) => state.userList);

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Component Did Mount
    useEffect(() => {
        Api.get('/cms')
            .then(function (response) {
                if (response.data.success) {
                    dispatch({
                        type: 'SET_USER_LIST',
                        payload: response.data.data.userList,
                    });
                } else {
                    setResponseMessage(response.data.message);
                    setResponseStatus('error');
                }
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage(
                        'An error occurred while retrieving your data.'
                    );
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Users | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="users-index" className="container mb-5">
                <div className="row">
                    <div className="col-12 text-center">
                        <Title title="Users" />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />
                    </div>
                </div>

                {userList && Object.keys(userList).length > 0 ? (
                    <>
                        <div className="row border p-3 mb-5">
                            <div className="col-12 col-lg-6 fw-bold mb-2 mb-lg-0 text-center text-lg-start">
                                Total Users:
                            </div>

                            <div className="col-12 col-lg-6 fw-bold text-center text-lg-end">
                                {userList ? parseInt(userList.length) : 0}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table
                                        id="table-users"
                                        className="mb-0 table table-bordered table-striped table-sm"
                                    >
                                        <thead className="table-light">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    &nbsp;
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Surname
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Email
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Mobile
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userList &&
                                                Object.keys(userList).length >
                                                    0 &&
                                                userList.map(function (
                                                    item,
                                                    index
                                                ) {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-center align-middle">
                                                                <a
                                                                    href={
                                                                        '/cms/users/' +
                                                                        item.id +
                                                                        '/edit'
                                                                    }
                                                                >
                                                                    <i className="fa-fw fas fa-edit"></i>
                                                                </a>
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                <a
                                                                    href={
                                                                        '/cms/users/' +
                                                                        item.id +
                                                                        '/edit'
                                                                    }
                                                                >
                                                                    {
                                                                        item.user_first_name
                                                                    }
                                                                </a>
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                <a
                                                                    href={
                                                                        '/cms/users/' +
                                                                        item.id +
                                                                        '/edit'
                                                                    }
                                                                >
                                                                    {
                                                                        item.user_last_name
                                                                    }
                                                                </a>
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                <a
                                                                    href={
                                                                        'users/' +
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.email}
                                                                </a>
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                <a
                                                                    href={
                                                                        'users/' +
                                                                        item.id
                                                                    }
                                                                >
                                                                    {
                                                                        item.mobile
                                                                    }
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <p className="mb-0 text-center">No records were found.</p>
                )}

                <div className="row mt-5">
                    <div className="col-12 col-lg-8 offset-lg-2 text-start">
                        <p>
                            <a href={CMS_DASHBOARD}>
                                Admin Dashboard{' '}
                                <i className="fas fa-arrow-right float-end"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}
