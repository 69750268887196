// Packages
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Components
import StokvelRegisterLayout from './StokvelRegisterLayout';

const StokvelRegisterStep3 = (props) => {
    // Redux
    const stokvelRegister = useSelector((state) => state.stokvelRegister);
    const referralTypeList = useSelector((state) => state.referralTypeList);
    const stokvelList = useSelector((state) => state.stokvelList);
    const dispatch = useDispatch();

    // React Hook Form
    const { handleSubmit, register, errors, watch } = useForm();
    const stokvelRole = watch('stokvelRole');

    // Submit Form
    const onSubmit = (data) => {
        dispatch({
            type: 'SET_STOKVEL_REGISTER',
            payload: {
                ...stokvelRegister,
                stokvelRole: data.stokvelRole,
                stokvelName: data.stokvelName,
                stokvelMembershipCount: data.stokvelMembershipCount,
                stokvelMobileNumber: data.stokvelMobileNumber,
                referralTypeId: data.referralTypeId,
            },
        });

        props.history.push('/stokvel/register/4');
    };

    return (
        <>
            <StokvelRegisterLayout
                stepName="Stokvel Details"
                stepNumber="3"
                stepProgress="w-75"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-4">
                        <label
                            htmlFor="stokvelRole"
                            className="fw-bold form-label small "
                        >
                            Are you a chairperson or member? *
                        </label>

                        <select
                            name="stokvelRole"
                            id="stokvelRole"
                            className="form-control"
                            defaultValue={stokvelRegister.stokvelRole}
                            ref={register({
                                required: {
                                    value: true,
                                    message:
                                        'Are you a chairperson or member is required.',
                                },
                            })}
                        >
                            <option value="" disabled>
                                Choose
                            </option>
                            <option value="member">Stokvel Member</option>
                            <option value="chairperson">
                                Stokvel Chairperson
                            </option>
                        </select>

                        {errors.stokvelRole && (
                            <div className="invalid-feedback">
                                {errors.stokvelRole.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="stokvelName"
                            className="fw-bold form-label small "
                        >
                            What is the name of your Stokvel? *
                        </label>

                        <input
                            type="text"
                            name="stokvelName"
                            id="stokvelName"
                            className="form-control"
                            placeholder="Team Awesome"
                            defaultValue={stokvelRegister.stokvelName}
                            list="stokvelList"
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Stokvel Name is required.',
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        'Stokvel Name must be between 2 and 191 characters.',
                                },
                                maxLength: {
                                    value: 191,
                                    message:
                                        'Stokvel Name must be between 2 and 191 characters.',
                                },
                            })}
                        />

                        <datalist id="stokvelList">
                            {stokvelList &&
                                stokvelList.map(function (item, index) {
                                    return (
                                        <option
                                            key={index}
                                            value={item.stokvel_name}
                                        >
                                            {item.stokvel_name}
                                        </option>
                                    );
                                })}
                        </datalist>

                        {errors.stokvelName && (
                            <div className="invalid-feedback">
                                {errors.stokvelName.message}
                            </div>
                        )}
                    </div>

                    {stokvelRole === 'chairperson' && (
                        <div id="chairperson-section">
                            <div className="mb-4">
                                <label
                                    htmlFor="stokvelMembershipCount"
                                    className="fw-bold form-label small "
                                >
                                    What is the number of members in your
                                    Stokvel? *
                                </label>

                                <input
                                    type="text"
                                    name="stokvelMembershipCount"
                                    id="stokvelMembershipCount"
                                    className="form-control"
                                    placeholder="16"
                                    defaultValue={
                                        stokvelRegister.stokvelMembershipCount
                                    }
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'What is the number of members in your Stokvel is required.',
                                        },
                                        min: {
                                            value: 1,
                                            message:
                                                'What is the number of members in your Stokvel must be between 1 and 999.',
                                        },
                                        max: {
                                            value: 999,
                                            message:
                                                'What is the number of members in your Stokvel must be between 1 and 999.',
                                        },
                                        pattern: {
                                            value: /([1-9]|[1-9][0-9]|[1-9][0-9][0-9])/g,
                                            message:
                                                'What is the number of members in your Stokvel is an invalid format.',
                                        },
                                    })}
                                />

                                {errors.stokvelMembershipCount && (
                                    <div className="invalid-feedback">
                                        {errors.stokvelMembershipCount.message}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {stokvelRole === 'member' && (
                        <div id="member-section">
                            <div className="mb-4">
                                <label
                                    htmlFor="stokvelMobileNumber"
                                    className="fw-bold form-label small "
                                >
                                    What is your Stokvel Chairperson Mobile
                                    Number? *
                                </label>

                                <input
                                    type="text"
                                    name="stokvelMobileNumber"
                                    id="stokvelMobileNumber"
                                    className="form-control"
                                    placeholder="0660714504"
                                    defaultValue={
                                        stokvelRegister.stokvelMobileNumber
                                    }
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'What is your Stokvel Chairperson Mobile Number is required.',
                                        },
                                        minLength: {
                                            value: 10,
                                            message:
                                                'What is your Stokvel Chairperson Mobile Number must be 10 digits.',
                                        },
                                        maxLength: {
                                            value: 10,
                                            message:
                                                'What is your Stokvel Chairperson Mobile Number must be 10 digits.',
                                        },
                                        pattern: {
                                            value: /^([0]{1})([1-9]{1})([0-9]{8})$/g,
                                            message:
                                                'What is your Stokvel Chairperson Mobile Number is an invalid format.',
                                        },
                                    })}
                                />

                                {errors.stokvelMobileNumber && (
                                    <div className="invalid-feedback">
                                        {errors.stokvelMobileNumber.message}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="mb-4">
                        <label
                            htmlFor="referralTypeId"
                            className="fw-bold form-label small "
                        >
                            Where did you hear about us? *
                        </label>

                        <select
                            name="referralTypeId"
                            id="referralTypeId"
                            className="form-control"
                            defaultValue={stokvelRegister.referralTypeId}
                            ref={register({
                                required: {
                                    value: true,
                                    message:
                                        'Where did you hear about us is required.',
                                },
                            })}
                        >
                            <option value="" disabled>
                                Choose
                            </option>
                            {referralTypeList &&
                                referralTypeList.map(function (item, index) {
                                    return item.referral_type_is_active_stokvel ===
                                        '1' ? (
                                        <option
                                            key={index}
                                            value={item.referral_type_id}
                                        >
                                            {item.referral_type_name}
                                        </option>
                                    ) : null;
                                })}
                        </select>

                        {errors.referralTypeId && (
                            <div className="invalid-feedback">
                                {errors.referralTypeId.message}
                            </div>
                        )}
                    </div>

                    <div className="text-center mt-100 mb-270">
                        <a
                            href="/stokvel/register/2"
                            className="btn btn-wide btn-secondary btn-lg text-uppercase me-2"
                        >
                            <i className="fas fa-angle-left"></i> Previous
                        </a>

                        <button
                            type="submit"
                            className="next btn btn-wide btn-primary btn-lg text-uppercase"
                        >
                            Next <i className="fas fa-angle-right"></i>
                        </button>
                    </div>
                </form>
            </StokvelRegisterLayout>
        </>
    );
};

export default withRouter(StokvelRegisterStep3);
