// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';

// Components
import Hero from '../../../components/Hero';
import Navbar from '../../../components/Navbar/HomeNavbar';
import Testimonial from './Testimonial';

// Helpers
import Api from '../../../helpers/Api';

// Images
import HeroDesktop from '../../../assets/img/page-banners/banner-home-desktop.png';
import HeroMobile from '../../../assets/img/page-banners/banner-home-mobile.png';
import AboutImage from '../../../assets/img/home/about.png';
import StokvelLogo300 from '../../../assets/img/logos/stokvel-logo-300.png';
import TraderLogo300 from '../../../assets/img/logos/traders-logo-300.png';
import VaselineFirmingImage from '../../../assets/img/product-highlights/vaseline-firming.png';
import VaselineAloeImage from '../../../assets/img/product-highlights/vaseline-aloe.png';
import VaselineAdvancedRepairImage from '../../../assets/img/product-highlights/vaseline-advanced-repair.png';
import VaselineOrangeEvenToneImage from '../../../assets/img/product-highlights/vaseline-orange-even-tone.png';
import KnorroxSoyaMinceImage from '../../../assets/img/product-highlights/knorrox-soya-mince.png';
import LifebouyHandwashImage from '../../../assets/img/product-highlights/lifebouy-handwash.png';
import LifebouySoapImage from '../../../assets/img/product-highlights/lifebouy-soap.png';
import LifebouyBodywashImage from '../../../assets/img/product-highlights/lifebouy-bodywash.png';

// Routes
import {
    STOKVEL_REGISTER,
    STOKVEL_LOGIN,
    TRADER_REGISTER,
    TRADER_LOGIN,
    HOME,
    PRODUCT_HIGHLIGHTS,
    TESTIMONIALS,
} from '../../../routes';

// Slider
const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    lazyLoad: false,
    infinite: false,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

export default function HomePage() {
    const [promoSlider, setPromoSlider] = useState(0);
    const [faq, setFaq] = useState([
        {
            id: 1,
            visible: false,
            question: 'How do I register?',
            answer: '<ul class="mb-0"><li>First, sign up your Stokvel or store to Wozani Nizothenga Stokvel Programme or <strong>Buyamali Rewards Programme</strong>.</li><li>Follow the easy steps to register your Stokvel or store and include a picture.</li><li>All signed up? Thumela iTill Slips zakho with all the Unilever products you’ve purchased.</li><li>Get points for every Unilever product on your uploaded slips!</li><li>Look out for a detailed SMS letting you know how many points you’ve earned.</li></ul>',
        },
        {
            id: 2,
            visible: false,
            question: 'What is the difference between iStokvel and Trader?',
            answer: '<ul class="mb-0"><li>Our <strong>Buyamali Rewards Programme</strong> is designed to let shop traders earn rewards on all the Unilever products they purchase, whenever they stock up their stores or spaza shops.</li><li>Our <strong>Wozani Nizothenga Stokvel Programme</strong> rewards you and your Stokvel  for the Unilever products you purchase as umndeni throughout the year.</li></ul>',
        },
        {
            id: 3,
            visible: false,
            question: 'What are the benefits of Unilever Shopper?',
            answer: '<ul class="mb-0"><li>As a digital platform, the <strong>Wozani Nizothenga Stokvel</strong> and <strong>Buyamali Rewards Programme</strong> easily tracks rewards accumulated. This ensures that you get all the rewards you have earned.</li><li><a href="/">Unilever Shopper</a> lets you earn rewards for the shopping you were already doing anyway!</li><li>You pay no monthly management or service fees as a Stokvel or Trader.</li><li>Stokvels and Traders get to earn rewards and stand to win exciting prizes throughout the year.</li></ul>',
        },
    ]);
    const [faqAll, setFaqAll] = useState(false);
    const [featuredTestimonialsArray, setFeaturedTestimonialsArray] = useState(
        []
    );

    function handlePromoSlider(id) {
        setPromoSlider(id);
    }

    function handleFAQ(index) {
        let newFAQ = [...faq];
        newFAQ[index] = {
            ...newFAQ[index],
            visible: !newFAQ[index].visible,
        };
        setFaq(newFAQ);
    }

    function handleFAQAll() {
        let newFAQ = [...faq];
        if (faqAll) {
            for (let faqIndex = 0; faqIndex < newFAQ.length; faqIndex++) {
                newFAQ[faqIndex] = {
                    ...newFAQ[faqIndex],
                    visible: false,
                };
                setFaq(newFAQ);
            }
            setFaqAll(false);
        } else {
            for (let faqIndex = 0; faqIndex < newFAQ.length; faqIndex++) {
                newFAQ[faqIndex] = {
                    ...newFAQ[faqIndex],
                    visible: true,
                };
                setFaq(newFAQ);
            }
            setFaqAll(true);
        }
    }

    // Redux
    const dispatch = useDispatch();

    // Component Did Mount
    useEffect(() => {
        Api.get('/featured-testimonials').then(function (response) {
            if (response.data.success) {
                dispatch({
                    type: 'SET_TESTIMONIALS_FEATURED_LIST',
                    payload: response.data.data.featuredTestimonials,
                });
                setFeaturedTestimonialsArray(
                    response.data.data.featuredTestimonials
                );
            }
        });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Welcome | Unilever Shopper</title>
                <body className="stokvel-app" />
                <meta
                    name="description"
                    content="Welcome to the Unilever Shopper home page. From here you can find out about our exciting rewards programme, how to join, promos, testimonials and FAQs."
                />
            </Helmet>

            <Navbar />

            <section id="home">
                <Hero
                    title="Welcome to Unilever Shopper"
                    heroMobile={HeroMobile}
                    heroDesktop={HeroDesktop}
                />

                <section id="about" className="bg-white py-5">
                    <div className="container">
                        <div className="row equal-height">
                            <div className="col-12 col-lg-6 order-2 order-lg-1">
                                <img
                                    src={AboutImage}
                                    alt=""
                                    className="img-fluid"
                                />
                            </div>

                            <div className="col-12 col-lg-6 d-lg-flex flex-lg-column justify-content-lg-center order-1 order-lg-2">
                                <h2 className=" text-center text-lg-start">
                                    <div className="h6 mb-2">About</div>
                                    Unilever
                                    <br />
                                    Shopper
                                </h2>

                                <p className=" text-center text-lg-start">
                                    On the hunt for a Stokvel{' '}
                                    <strong>rewards programme</strong> that
                                    offers you cash-back and loads of amazing
                                    prizes? Want to earn points for simply
                                    shopping? Join umndeni wethu weStokvel or
                                    Trader today on{' '}
                                    <a href={HOME}>Unilever Shopper</a>.
                                </p>

                                <p className="mb-4 text-center text-lg-start">
                                    If you’re signing up as a member or
                                    chairperson, please select Sign up for
                                    iStokvel. If you are signing up as a Trader,
                                    please select Sign up as a Trader.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="join" className="bg-grey-light pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-5">
                                <h2 className="text-center h1-lg">
                                    How to join
                                </h2>

                                <p className="fw-book text-center">
                                    To register umndeni wakho to our{' '}
                                    <strong>online Stokvel programme</strong>.
                                    All you’ll need to do is to create a group,
                                    register igama lomndeni wakho, sign up and
                                    start earning points by simply uploading
                                    your till slips whenever you purchase any
                                    Unilever products! uKhumbule, the more you
                                    buy, the more rewards you earn for you and
                                    your stokvel.
                                </p>

                                <p className="mb-4 fw-book text-center">
                                    Trying to join our{' '}
                                    <strong>Buyamali Rewards Programme</strong>{' '}
                                    as a Trader? All you need to do is sign up
                                    and upload your till slips whenever you
                                    stock up your store with any Unilever
                                    products.
                                </p>
                            </div>
                        </div>

                        <div className="row equal-height">
                            <div className="col-12 col-lg-6 pb-5">
                                <div className="bg-blue-dark h-100 p-5 text-center">
                                    <div className="stokvel-logo-container">
                                        <img
                                            src={StokvelLogo300}
                                            alt="Stokvel"
                                            className="img-fluid"
                                        />
                                    </div>

                                    <div>
                                        <a
                                            href={STOKVEL_LOGIN}
                                            className="btn btn-wide btn-primary btn-lg text-uppercase"
                                        >
                                            Login
                                        </a>
                                    </div>

                                    <div className="mt-3 small">
                                        <span className="border-bottom pb-1">
                                            <a
                                                href={STOKVEL_REGISTER}
                                                className="fw-bold text-decoration-none text-white"
                                            >
                                                Sign Up
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6 pb-5">
                                <div className="bg-orange-dark h-100 p-5 text-center">
                                    <div className="trader-logo-container">
                                        <img
                                            src={TraderLogo300}
                                            alt="Trader"
                                            className="img-fluid"
                                        />
                                    </div>

                                    <div>
                                        <a
                                            href={TRADER_LOGIN}
                                            className="btn btn-wide btn-primary btn-lg text-uppercase"
                                        >
                                            Login
                                        </a>
                                    </div>

                                    <div className="mt-3 small">
                                        <span className="border-bottom pb-1">
                                            <a
                                                href={TRADER_REGISTER}
                                                className="fw-bold text-decoration-none text-white"
                                            >
                                                Sign Up
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="promotions" className="bg-white py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-5">
                                <h1 className=" text-center text-lg-start">
                                    Product Promotions
                                </h1>
                            </div>

                            <div className="col-12 mb-5">
                                <div
                                    className={
                                        promoSlider === 0
                                            ? 'border-bottom border-blue-dark d-inline-block me-3 pb-1'
                                            : 'd-inline-block me-3 pb-1'
                                    }
                                >
                                    <span
                                        className="cursor-hand fw-bold"
                                        onClick={() => handlePromoSlider(0)}
                                    >
                                        Vaseline
                                    </span>
                                </div>

                                <div
                                    className={
                                        promoSlider === 1
                                            ? 'border-bottom border-blue-dark d-inline-block me-3 pb-1'
                                            : 'd-inline-block me-3 pb-1'
                                    }
                                >
                                    <span
                                        className="cursor-hand fw-bold"
                                        onClick={() => handlePromoSlider(1)}
                                    >
                                        Knorrox
                                    </span>
                                </div>

                                <div
                                    className={
                                        promoSlider === 2
                                            ? 'border-bottom border-blue-dark d-inline-block me-3 pb-1'
                                            : 'd-inline-block me-3 pb-1'
                                    }
                                >
                                    <span
                                        className="cursor-hand fw-bold"
                                        onClick={() => handlePromoSlider(2)}
                                    >
                                        Lifebuoy
                                    </span>
                                </div>

                                <div className="d-md-inline pb-1 pt-4 pt-md-0 float-md-end fw-bold">
                                    <a
                                        href={PRODUCT_HIGHLIGHTS}
                                        className="p-0 p-md-3 text-decoration-none"
                                    >
                                        View All{' '}
                                        <i
                                            className="fa fa-arrow-right ps-3 float-end float-md-none"
                                            aria-hidden="true"
                                        ></i>
                                    </a>
                                </div>
                            </div>

                            <div
                                id="vaseline-slider"
                                className={
                                    promoSlider === 0 ? 'col-12' : 'd-none'
                                }
                            >
                                <Slider {...settings}>
                                    <div className="slide">
                                        <div className="bg-white h-100 text-black text-center">
                                            <div className="shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={VaselineFirmingImage}
                                                    alt="Vaseline&reg; Intensive Care&trade; Firming Body Lotion"
                                                />
                                            </div>

                                            <p className="h6 mb-0 mt-3 text-start">
                                                Vaseline&reg; Intensive
                                                Care&trade; Firming Body Lotion​
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="bg-white h-100 text-black text-center">
                                            <div className="shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={VaselineAloeImage}
                                                    alt="Vaseline&reg; Intensive Care&trade; Aloe Soothe Lotion"
                                                />
                                            </div>

                                            <p className="h6 mb-0 mt-3 text-start">
                                                Vaseline&reg; Intensive
                                                Care&trade; Aloe Soothe Lotion
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="bg-white h-100 text-black text-center">
                                            <div className="shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineAdvancedRepairImage
                                                    }
                                                    alt="Vaseline&reg; Intensive Care&trade; Advanced Repair Unscented Lotions"
                                                />
                                            </div>

                                            <p className="h6 mb-0 mt-3 text-start">
                                                Vaseline&reg; Intensive
                                                Care&trade; Advanced Repair
                                                Unscented Lotion
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="bg-white h-100 text-black text-center">
                                            <div className="shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineOrangeEvenToneImage
                                                    }
                                                    alt="Vaseline&reg; Intensive Care&trade; Healthy Even Tone Body Lotion"
                                                />
                                            </div>

                                            <p className="h6 mb-0 mt-3 text-start">
                                                Vaseline&reg; Intensive
                                                Care&trade; Healthy Even Tone
                                                Body Lotion
                                            </p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>

                            <div
                                id="knorrox-slider"
                                className={
                                    promoSlider === 1 ? 'col-12' : 'd-none'
                                }
                            >
                                <Slider {...settings}>
                                    <div className="slide">
                                        <div className="bg-white h-100 text-black text-center">
                                            <div className="shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={KnorroxSoyaMinceImage}
                                                    alt="Knorrox Savoury Soya Mince 200g"
                                                />
                                            </div>

                                            <p className="h6 mb-0 mt-3 text-start">
                                                Knorrox Savoury Soya Mince 200g
                                            </p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>

                            <div
                                id="lifebuoy-slider"
                                className={
                                    promoSlider === 2 ? 'col-12' : 'd-none'
                                }
                            >
                                <Slider {...settings}>
                                    <div className="slide">
                                        <div className="bg-white h-100 text-black text-center">
                                            <div className="shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={LifebouyHandwashImage}
                                                    alt="Lifebuoy Total 10 Hand Wash"
                                                />
                                            </div>

                                            <p className="h6 mb-0 mt-3 text-start">
                                                Lifebuoy Total 10 Hand Wash
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="bg-white h-100 text-black text-center">
                                            <div className="shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={LifebouySoapImage}
                                                    alt="Lifebuoy Total 10 Bar Soap"
                                                />
                                            </div>

                                            <p className="h6 mb-0 mt-3 text-start">
                                                Lifebuoy Total 10 Bar Soap
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="bg-white h-100 text-black text-center">
                                            <div className="shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={LifebouyBodywashImage}
                                                    alt="Lifebuoy Total 10 Body Wash 750ml"
                                                />
                                            </div>

                                            <p className="h6 mb-0 mt-3 text-start">
                                                Lifebuoy Total 10 Body Wash
                                                750ml
                                            </p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="testimonials" className="bg-grey-light py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-5">
                                <h1 className="d-lg-inline text-center text-lg-start">
                                    What people have said
                                </h1>

                                <div className="d-lg-inline pb-1 pt-4 pt-lg-0 float-lg-end fw-bold">
                                    <a
                                        href={TESTIMONIALS}
                                        className="d-lg-inline-block p-0 p-lg-3 text-decoration-none"
                                    >
                                        View All{' '}
                                        <i
                                            className="fa fa-arrow-right ps-3 float-end float-lg-none"
                                            aria-hidden="true"
                                        ></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="row" id="testimonial-slider">
                            <div className="col-12">
                                <Slider {...settings}>
                                    {featuredTestimonialsArray &&
                                        Object.keys(featuredTestimonialsArray)
                                            .length > 0 &&
                                        featuredTestimonialsArray.map(function (
                                            item
                                        ) {
                                            return (
                                                <div
                                                    key={item.testimonial_id}
                                                    className="slide shadow-all h-100"
                                                >
                                                    <Testimonial item={item} />
                                                </div>
                                            );
                                        })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="faq" className="bg-blue-light text-white py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <h1 className="d-lg-inline text-center text-lg-start">
                                    Questions
                                </h1>

                                <div className="d-lg-inline pb-1 pt-4 pt-lg-0 float-lg-end fw-bold">
                                    <span
                                        className="d-lg-inline-block p-0 p-lg-3 text-decoration-none cursor-hand"
                                        onClick={handleFAQAll}
                                    >
                                        <span>
                                            {faqAll ? 'Close All' : 'Open All'}
                                        </span>{' '}
                                        <i
                                            className={
                                                faqAll
                                                    ? 'fa fa-arrow-up ps-3 float-end float-lg-none'
                                                    : 'fa fa-arrow-down ps-3 float-end float-lg-none'
                                            }
                                            aria-hidden="true"
                                        ></i>
                                    </span>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="bg-white text-black p-2 p-lg-5">
                                    {faq.map((item, index) => {
                                        return (
                                            <div
                                                className="faq-container mb-3"
                                                key={index}
                                            >
                                                <div
                                                    className="faq-question d-flex flex-row pb-1 cursor-hand"
                                                    onClick={() =>
                                                        handleFAQ(index)
                                                    }
                                                >
                                                    <div className="faq-question-icon flex-shrink-0">
                                                        <i
                                                            className={
                                                                item.visible
                                                                    ? 'fa fa-fw fa-lg fa-chevron-up pt-2 text-blue-light'
                                                                    : 'fa fa-fw fa-lg fa-chevron-down pt-2 text-blue-light'
                                                            }
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>

                                                    <div className="faq-question-text flex-grow-1">
                                                        <h3>{item.question}</h3>
                                                    </div>
                                                </div>

                                                <div
                                                    className={
                                                        item.visible
                                                            ? 'faq-answer d-block'
                                                            : 'faq-answer d-none'
                                                    }
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.answer,
                                                    }}
                                                ></div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}
