// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

export default function CmsTraderEdit() {
    // Redux
    const dispatch = useDispatch();
    const provinceList = useSelector((state) => state.provinceList);
    const trader = useSelector((state) => state.trader);

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Check URL
    const url = window.location.pathname;
    const urlParams = url.split('/');

    // React Form Hook
    const { handleSubmit, register, reset, errors } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            traderName: '',
            traderStoreAmount: '',
            traderStoreType: '',
            traderMobile: '',
            traderAddress: '',
            traderCity: '',
            traderSuburb: '',
            traderProvinceId: '',
            traderPostalCode: '',
            traderRetailer: '',
        },
    });

    useEffect(() => {
        Api.get('/traders/' + urlParams[3] + '/edit')
            .then(function (response) {
                dispatch({
                    type: 'SET_TRADER',
                    payload: response.data.data.trader,
                });

                dispatch({
                    type: 'SET_PROVINCE_LIST',
                    payload: response.data.data.provinces,
                });
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage('Unable to retrieve the form data.');
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        reset({
            traderName: trader.trader_name,
            traderStoreAmount: trader.traderStoreAmount,
            traderStoreType: trader.traderStoreType,
            traderMobile: trader.trader_mobile,
            traderAddress: trader.trader_address,
            traderCity: trader.trader_city,
            traderSuburb: trader.trader_suburb,
            traderProvinceId: trader.trader_province_id,
        });
        // eslint-disable-next-line
    }, [reset]);

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/traders/' + urlParams[3], {
                _method: 'put',
                trader_name: data.traderName,
                trader_store_amount: data.traderStoreAmount,
                trader_store_type: data.traderStoreType,
                trader_mobile: data.traderMobile,
                trader_address: data.traderAddress,
                trader_suburb: data.traderSuburb,
                trader_city: data.traderCity,
                trader_province_id: data.traderProvinceId,
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Edit Trader | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="trader-edit" className="container mb-5">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <Title
                            title={
                                trader
                                    ? 'Edit ' + trader.trader_name
                                    : 'Edit Trader'
                            }
                        />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label
                                    htmlFor="traderName"
                                    className="fw-bold form-label small"
                                >
                                    What is the name of your Store? *
                                </label>

                                <input
                                    type="text"
                                    name="traderName"
                                    id="traderName"
                                    className="form-control"
                                    placeholder="Awesome Trading"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: 'Trader Name is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Trader Name must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Trader Name must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.traderName && (
                                    <div className="invalid-feedback">
                                        {errors.traderName.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="traderStoreAmount"
                                    className="fw-bold form-label small "
                                >
                                    How many stores do you own? *
                                </label>

                                <select
                                    name="traderStoreAmount"
                                    id="traderStoreAmount"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'How many stores do you own is required.',
                                        },
                                    })}
                                >
                                    <option value="" disabled>
                                        Choose
                                    </option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                </select>

                                {errors.traderStoreAmount && (
                                    <div className="invalid-feedback">
                                        {errors.traderStoreAmount.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="traderStoreType"
                                    className="fw-bold form-label small "
                                >
                                    Store type/size? *
                                </label>

                                <select
                                    name="traderStoreType"
                                    id="traderStoreType"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Store type/size is required.',
                                        },
                                    })}
                                >
                                    <option value="" disabled>
                                        Choose
                                    </option>
                                    <option value="Small (Tier 1)">
                                        Small (Tier 1)
                                    </option>
                                    <option value="Medium (Tier 2)">
                                        Medium (Tier 2)
                                    </option>
                                    <option value="Large (Tier 3)">
                                        Large (Tier 3)
                                    </option>
                                </select>

                                {errors.traderStoreType && (
                                    <div className="invalid-feedback">
                                        {errors.traderStoreType.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="traderMobile"
                                    className="fw-bold form-label small"
                                >
                                    Trader's Mobile Number? *
                                </label>

                                <input
                                    type="text"
                                    name="traderMobile"
                                    id="traderMobile"
                                    className="form-control"
                                    placeholder="0660714504"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                "Trader's Mobile Number is required.",
                                        },
                                        minLength: {
                                            value: 10,
                                            message:
                                                "Trader's Mobile Number must be 10 digits.",
                                        },
                                        maxLength: {
                                            value: 10,
                                            message:
                                                "Trader's Mobile Number must be 10 digits.",
                                        },
                                        pattern: {
                                            value: /^([0]{1})([1-9]{1})([0-9]{8})$/g,
                                            message:
                                                'What is your Trader Chairperson Mobile Number is an invalid format.',
                                        },
                                    })}
                                />

                                {errors.traderMobile && (
                                    <div className="invalid-feedback">
                                        {errors.traderMobile.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="traderAddress"
                                    className="fw-bold form-label small"
                                >
                                    Your Store's Address *
                                </label>

                                <input
                                    type="text"
                                    name="traderAddress"
                                    id="traderAddress"
                                    className="form-control"
                                    placeholder="15 Nollsworth Crescent"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                "Trader's Address is required.",
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                "Trader's Address must be between 2 and 191 characters.",
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                "Trader's Address must be between 2 and 191 characters.",
                                        },
                                    })}
                                />

                                {errors.traderAddress && (
                                    <div className="invalid-feedback">
                                        {errors.traderAddress.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="traderSuburb"
                                    className="fw-bold form-label small"
                                >
                                    Your Store's Suburb *
                                </label>

                                <input
                                    type="text"
                                    name="traderSuburb"
                                    id="traderSuburb"
                                    className="form-control"
                                    placeholder="Umhlanga"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                "Trader's Suburb is required.",
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                "Trader's Suburb must be between 2 and 191 characters.",
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                "Trader's Suburb must be between 2 and 191 characters.",
                                        },
                                    })}
                                />

                                {errors.traderSuburb && (
                                    <div className="invalid-feedback">
                                        {errors.traderSuburb.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="traderCity"
                                    className="fw-bold form-label small"
                                >
                                    Your Store's City *
                                </label>

                                <input
                                    type="text"
                                    name="traderCity"
                                    id="traderCity"
                                    className="form-control"
                                    placeholder="Durban"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                "Trader's City is required.",
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                "Trader's City must be between 2 and 191 characters.",
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                "Trader's City must be between 2 and 191 characters.",
                                        },
                                    })}
                                />

                                {errors.traderCity && (
                                    <div className="invalid-feedback">
                                        {errors.traderCity.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="traderProvinceId"
                                    className="fw-bold form-label small"
                                >
                                    Your Store's Province *
                                </label>

                                <select
                                    id="traderProvinceId"
                                    name="traderProvinceId"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                "Trader's Province is required.",
                                        },
                                    })}
                                >
                                    <option value="" disabled>
                                        Choose
                                    </option>
                                    {provinceList &&
                                        provinceList.map(function (
                                            item,
                                            index
                                        ) {
                                            return (
                                                <option
                                                    key={index}
                                                    value={item.province_id}
                                                >
                                                    {item.province_name}
                                                </option>
                                            );
                                        })}
                                </select>

                                {errors.traderProvinceId && (
                                    <div className="invalid-feedback">
                                        {errors.traderProvinceId.message}
                                    </div>
                                )}
                            </div>

                            <ResponseMessage
                                message={responseMessage}
                                status={responseStatus}
                            />

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Update Trader"
                                    className="btn btn-wide btn-primary"
                                />

                                <a
                                    href={
                                        urlParams[1] === 'cms'
                                            ? '/cms/traders'
                                            : '/portal/traders/' + urlParams[3]
                                    }
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
