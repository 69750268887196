// Packages
import React from 'react';
import Slider from 'react-slick';

// Images and PDFs
import Bundle1Image from '../../../assets/img/mystore/mystore-product-list-001.png';
import Bundle1PDF from '../../../assets/pdf/mystore/mystore-product-list-001.pdf';
import Magazine1Image from '../../../assets/img/mystore/mystore-issue-001.png';
import Magazine1PDF from '../../../assets/pdf/mystore/mystore-issue-001.pdf';
import Magazine2Image from '../../../assets/img/mystore/mystore-issue-002.png';
import Magazine2PDF from '../../../assets/pdf/mystore/mystore-issue-002.pdf';
import Magazine3Image from '../../../assets/img/mystore/mystore-issue-003.png';
import Magazine3PDF from '../../../assets/pdf/mystore/mystore-issue-003.pdf';

export default function MyStoreMagazine() {
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        lazyLoad: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <div className="row">
                <div className="col-12 col-lg-8 offset-lg-2 text-center">
                    <h2 className="h1 mb-3 text-center text-white">
                        MyStore Magazines
                    </h2>

                    <Slider {...settings}>
                        <div className="slide">
                            <div className="bg-white d-flex justify-content-center align-items-center h-350">
                                <img
                                    className="img-fluid"
                                    src={Bundle1Image}
                                    alt="MyStore Edition #1"
                                />
                            </div>

                            <p className="h6 my-3 text-white">
                                Unilever Product List
                            </p>

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={Bundle1PDF}
                                className="small text-white"
                            >
                                View / Download
                            </a>
                        </div>

                        <div className="slide">
                            <div className="bg-white d-flex justify-content-center align-items-center h-350">
                                <img
                                    className="img-fluid"
                                    src={Magazine1Image}
                                    alt="MyStore Edition #1"
                                />
                            </div>

                            <p className="h6 my-3 text-white">
                                MyStore Edition #1
                            </p>

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={Magazine1PDF}
                                className="small text-white"
                            >
                                View / Download
                            </a>
                        </div>

                        <div className="slide">
                            <div className="bg-white d-flex justify-content-center align-items-center h-350">
                                <img
                                    className="img-fluid"
                                    src={Magazine2Image}
                                    alt="MyStore Edition #2"
                                />
                            </div>

                            <p className="h6 my-3 text-white">
                                MyStore Edition #2
                            </p>

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={Magazine2PDF}
                                className="small text-white"
                            >
                                View / Download
                            </a>
                        </div>

                        <div className="slide">
                            <div className="bg-white d-flex justify-content-center align-items-center h-350">
                                <img
                                    className="img-fluid"
                                    src={Magazine3Image}
                                    alt="MyStore Edition #3"
                                />
                            </div>

                            <p className="h6 my-3 text-white">
                                MyStore Edition #3
                            </p>

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={Magazine3PDF}
                                className="small text-white"
                            >
                                View / Download
                            </a>
                        </div>
                    </Slider>
                </div>
            </div>
        </>
    );
}
