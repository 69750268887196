// Packages
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Navbar from '../../../components/Navbar/AdminNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { CMS_DASHBOARD } from '../../../routes';

export default function CmsSmsCreatePage() {
    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // React Hook Form
    const { handleSubmit, register, reset, errors } = useForm({
        defaultValues: {
            smsMessage: '',
        },
    });

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/send-sms', {
                smsMessage: data.smsMessage,
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');

                        reset();
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Send SMS | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="sms-create" className="container mb-5">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <Title title="Send SMS" />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label htmlFor="smsMessage">
                                    SMS Message *
                                </label>
                                <textarea
                                    name="smsMessage"
                                    id="smsMessage"
                                    rows="10"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: 'SMS message is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'SMS message must be between 2 and 160 characters.',
                                        },
                                        maxLength: {
                                            value: 160,
                                            message:
                                                'SMS message must be between 2 and 160 characters.',
                                        },
                                    })}
                                ></textarea>

                                {errors.smsMessage && (
                                    <div className="invalid-feedback">
                                        {errors.smsMessage.message}
                                    </div>
                                )}
                            </div>

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Send"
                                    className="btn btn-wide btn-primary"
                                />

                                <a
                                    href={CMS_DASHBOARD}
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
