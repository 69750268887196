// Packages
import React from 'react';

// Images
import UnileverLogoImage from '../../assets/img/logos/unilever-logo.png';

import { CMS_DASHBOARD } from '../../routes';

export default function CmsUnileverLogo() {
    return (
        <nav className="container">
            <div className="row">
                <div className="col-12 my-4 text-center">
                    <a href={CMS_DASHBOARD}>
                        <img
                            src={UnileverLogoImage}
                            alt="Unilever Logo"
                            className="img-fluid unilever-logo"
                        />
                    </a>
                </div>
            </div>
        </nav>
    );
}
