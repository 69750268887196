// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

export default function CmsStokvelEdit() {
    // Redux
    const dispatch = useDispatch();
    const provinceList = useSelector((state) => state.provinceList);
    const stokvel = useSelector((state) => state.stokvel);

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Check URL
    const url = window.location.pathname;
    const urlParams = url.split('/');

    const { handleSubmit, register, reset, errors } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            stokvelName: '',
            stokvelMembershipCount: 1,
            stokvelMobile: '',
            stokvelAddress: '',
            stokvelCity: '',
            stokvelSuburb: '',
            stokvelProvinceId: '',
        },
    });

    // Component Did Mount
    useEffect(() => {
        Api.get('stokvels/' + urlParams[3] + '/edit')
            .then(function (response) {
                dispatch({
                    type: 'SET_STOKVEL',
                    payload: response.data.data.stokvel,
                });

                dispatch({
                    type: 'SET_PROVINCE_LIST',
                    payload: response.data.data.provinces,
                });
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage('Unable to retrieve the form data.');
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        reset({
            stokvelName: stokvel.stokvel_name,
            stokvelMembershipCount: stokvel.stokvel_membership_count,
            stokvelMobile: stokvel.stokvel_mobile,
            stokvelAddress: stokvel.stokvel_address,
            stokvelCity: stokvel.stokvel_city,
            stokvelSuburb: stokvel.stokvel_suburb,
            stokvelProvinceId: stokvel.stokvel_province_id,
        });
        // eslint-disable-next-line
    }, [reset]);

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('stokvels/' + urlParams[3], {
                _method: 'put',
                stokvel_name: data.stokvelName,
                stokvel_mobile: data.stokvelMobile,
                stokvel_membership_count: data.stokvelMembershipCount,
                stokvel_address: data.stokvelAddress,
                stokvel_suburb: data.stokvelSuburb,
                stokvel_city: data.stokvelCity,
                stokvel_province_id: data.stokvelProvinceId,
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Edit Stokvel | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="stokvel-edit" className="container mb-5">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <Title
                            title={
                                stokvel
                                    ? 'Edit ' + stokvel.stokvel_name
                                    : 'Edit Stokvel'
                            }
                        />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label
                                    htmlFor="stokvelName"
                                    className="fw-bold form-label small"
                                >
                                    What is the name of your Stokvel? *
                                </label>

                                <input
                                    type="text"
                                    name="stokvelName"
                                    id="stokvelName"
                                    className="form-control"
                                    placeholder="Team Awesome"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Stokvel Name is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Stokvel Name must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Stokvel Name must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.stokvelName && (
                                    <div className="invalid-feedback">
                                        {errors.stokvelName.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="stokvelMembershipCount"
                                    className="fw-bold form-label small"
                                >
                                    What is the number of members in your
                                    Stokvel? *
                                </label>

                                <input
                                    type="text"
                                    name="stokvelMembershipCount"
                                    id="stokvelMembershipCount"
                                    className="form-control"
                                    placeholder="16"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'What is the number of members in your Stokvel is required.',
                                        },
                                        min: {
                                            value: 1,
                                            message:
                                                'What is the number of members in your Stokvel must be between 1 and 999.',
                                        },
                                        max: {
                                            value: 999,
                                            message:
                                                'What is the number of members in your Stokvel must be between 1 and 999.',
                                        },
                                        pattern: {
                                            value: /([1-9]|[1-9][0-9]|[1-9][0-9][0-9])/g,
                                            message:
                                                'What is the number of members in your Stokvel is an invalid format.',
                                        },
                                    })}
                                />

                                {errors.stokvelMembershipCount && (
                                    <div className="invalid-feedback">
                                        {errors.stokvelMembershipCount.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="stokvelMobile"
                                    className="fw-bold form-label small"
                                >
                                    What is your Stokvel Chairperson Mobile
                                    Number? *
                                </label>

                                <input
                                    type="text"
                                    name="stokvelMobile"
                                    id="stokvelMobile"
                                    className="form-control"
                                    placeholder="0660714504"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'What is your Stokvel Chairperson Mobile Number is required.',
                                        },
                                        minLength: {
                                            value: 10,
                                            message:
                                                'What is your Stokvel Chairperson Mobile Number must be 10 digits.',
                                        },
                                        maxLength: {
                                            value: 10,
                                            message:
                                                'What is your Stokvel Chairperson Mobile Number must be 10 digits.',
                                        },
                                        pattern: {
                                            value: /^([0]{1})([1-9]{1})([0-9]{8})$/g,
                                            message:
                                                'What is your Stokvel Chairperson Mobile Number is an invalid format.',
                                        },
                                    })}
                                />

                                {errors.stokvelMobile && (
                                    <div className="invalid-feedback">
                                        {errors.stokvelMobile.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="stokvelAddress"
                                    className="fw-bold form-label small"
                                >
                                    Stokvel's Address *
                                </label>

                                <input
                                    type="text"
                                    name="stokvelAddress"
                                    id="stokvelAddress"
                                    className="form-control"
                                    placeholder="15 Nollsworth Crescent"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                "Stokvel's Address is required.",
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                "Stokvel's Address must be between 2 and 191 characters.",
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                "Stokvel's Address must be between 2 and 191 characters.",
                                        },
                                    })}
                                />

                                {errors.stokvelAddress && (
                                    <div className="invalid-feedback">
                                        {errors.stokvelAddress.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="stokvelSuburb"
                                    className="fw-bold form-label small"
                                >
                                    Stokvel's Suburb *
                                </label>

                                <input
                                    type="text"
                                    name="stokvelSuburb"
                                    id="stokvelSuburb"
                                    className="form-control"
                                    placeholder="Umhlanga"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                "Stokvel's Suburb is required.",
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                "Stokvel's Suburb must be between 2 and 191 characters.",
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                "Stokvel's Suburb must be between 2 and 191 characters.",
                                        },
                                    })}
                                />

                                {errors.stokvelSuburb && (
                                    <div className="invalid-feedback">
                                        {errors.stokvelSuburb.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="stokvelCity"
                                    className="fw-bold form-label small"
                                >
                                    Stokvel City *
                                </label>

                                <input
                                    type="text"
                                    name="stokvelCity"
                                    id="stokvelCity"
                                    className="form-control"
                                    placeholder="Durban"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Stokvel City is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Stokvel City must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Stokvel City must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.stokvelCity && (
                                    <div className="invalid-feedback">
                                        {errors.stokvelCity.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="stokvelProvinceId"
                                    className="fw-bold form-label small"
                                >
                                    Stokvel Province *
                                </label>

                                <select
                                    id="stokvelProvinceId"
                                    name="stokvelProvinceId"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Stokvel Province is required.',
                                        },
                                    })}
                                >
                                    <option value="" disabled>
                                        Choose
                                    </option>
                                    {provinceList &&
                                        provinceList.map(function (
                                            item,
                                            index
                                        ) {
                                            return (
                                                <option
                                                    key={index}
                                                    value={item.province_id}
                                                >
                                                    {item.province_name}
                                                </option>
                                            );
                                        })}
                                </select>

                                {errors.stokvelProvinceId && (
                                    <div className="invalid-feedback">
                                        {errors.stokvelProvinceId.message}
                                    </div>
                                )}
                            </div>

                            <ResponseMessage
                                message={responseMessage}
                                status={responseStatus}
                            />

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Update Stokvel"
                                    className="btn btn-wide btn-primary"
                                />

                                <a
                                    href={
                                        urlParams[1] === 'cms'
                                            ? '/cms/stokvels'
                                            : '/portal/stokvels/' + urlParams[3]
                                    }
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
