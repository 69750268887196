import React from 'react';

// Icons
import NoIcon from '../../../components/Icons/NoIcon';
import YesIcon from '../../../components/Icons/YesIcon';

const Products = (props) => {
    const {
        index,
        product_id,
        product_name,
        product_value,
        product_is_active,
    } = props.data;

    return (
        <tr key={index}>
            <td className="text-center align-middle">
                <a href={'/cms/products/' + product_id + '/edit'}>
                    <i className="fa-fw fas fa-edit"></i>
                </a>
            </td>
            <td className="text-center align-middle">
                <a href={'/cms/products/' + product_id + '/edit'}>
                    {product_name}
                </a>
            </td>
            <td className="text-center align-middle">
                {parseFloat(product_value).toFixed(2)}
            </td>
            <td className="text-center align-middle">
                {product_is_active === '1' ? <YesIcon /> : <NoIcon />}
            </td>
        </tr>
    );
};

export default Products;
