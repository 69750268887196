// Packages
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/PortalUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { CMS_DASHBOARD, PORTAL_DASHBOARD } from '../../../routes';

export default function PortalReferAFriendCreate() {
    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Check URL
    const url = window.location.pathname;
    const urlParams = url.split('/');

    // React Hook Form
    const { handleSubmit, register, reset, errors } = useForm({
        defaultValues: {
            firstName: '',
            mobileNumber: '',
        },
    });

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('refer-a-friend', {
                friend_name: data.firstName,
                friend_mobile: data.mobileNumber,
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }

                    reset();
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Refer A Friend | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="refer-a-friend" className="container mb-5">
                <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-3">
                        <Title title="Refer a Friend" />

                        <p className="mb-5">
                            Get your friend to join the Wozani Nizothenga
                            Loyalty Programme mdeni and you could win R10.00
                            airtime. Simply fill in your friend's details and
                            stand to win.
                        </p>

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label
                                    htmlFor="friend_name"
                                    className="fw-bold form-label small"
                                >
                                    Your Friend's First Name *
                                </label>

                                <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    className="form-control"
                                    placeholder="Jane"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: 'First Name is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'First Name must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'First Name must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.firstName && (
                                    <div className="invalid-feedback">
                                        {errors.firstName.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="mobileNumber"
                                    className="fw-bold form-label small"
                                >
                                    Your Friend's Mobile Number *
                                </label>

                                <input
                                    type="text"
                                    name="mobileNumber"
                                    id="mobileNumber"
                                    className="form-control"
                                    placeholder="0660714504"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Mobile Number is required.',
                                        },
                                        minLength: {
                                            value: 10,
                                            message:
                                                'Mobile Number must be 10 digits.',
                                        },
                                        maxLength: {
                                            value: 10,
                                            message:
                                                'Mobile Number must be 10 digits.',
                                        },
                                        pattern: {
                                            value: /^([0]{1})([1-9]{1})([0-9]{8})$/g,
                                            message:
                                                'Mobile Number is an invalid format.',
                                        },
                                    })}
                                />

                                {errors.mobileNumber && (
                                    <div className="invalid-feedback">
                                        {errors.mobileNumber.message}
                                    </div>
                                )}
                            </div>

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Send Referral"
                                    className="btn btn-wide btn-primary"
                                />
                                <a
                                    href={
                                        urlParams[1] === 'cms'
                                            ? CMS_DASHBOARD
                                            : PORTAL_DASHBOARD
                                    }
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
