// Packages
import React, { useState } from 'react';

// Components
import { retrieveToken } from '../../helpers/retrieveToken';

// Images
import MenuImage from '../../assets/img/icons/menu-button.png';

// Navbar Items
import {
    HomeNavbarItems,
    RegisterLoginNavbarItems,
    FrontendNavbarItems,
    UserDashboardNavbarItems,
} from './navItems';

export default function Navbar() {
    const [menuVisible, setMenuVisible] = useState(false);

    function handleMenu() {
        setMenuVisible(!menuVisible);
    }

    const accessToken = retrieveToken();

    return (
        <>
            <div id="page-navbar" className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div
                            id="hamburgerButton"
                            className="d-flex align-items-center justify-content-end my-3 pe-4"
                            onClick={handleMenu}
                        >
                            <span className="me-2 d-block fw-bold text-uppercase text-white">
                                Menu
                            </span>
                            <img
                                src={MenuImage}
                                alt=""
                                className="img-fluid d-block max-height-16"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="custom-draw"
                className={
                    menuVisible ? 'd-block bg-white border-start' : 'd-none'
                }
            >
                <div className="text-end">
                    <button
                        type="button"
                        id="customDrawClose"
                        className="btn btn-wide btn-link text-end"
                        onClick={handleMenu}
                    >
                        <i className="fas fa-times h4"></i>
                    </button>
                </div>

                <div>
                    {HomeNavbarItems &&
                        Object.keys(HomeNavbarItems).length > 0 &&
                        HomeNavbarItems.map(function (item, index) {
                            return (
                                <a
                                    key={index}
                                    href={item.route}
                                    className="tier-1"
                                >
                                    {item.title}
                                </a>
                            );
                        })}

                    {FrontendNavbarItems &&
                        Object.keys(FrontendNavbarItems).length > 0 &&
                        FrontendNavbarItems.map(function (item, index) {
                            return (
                                <a
                                    key={index}
                                    href={item.route}
                                    className="tier-1"
                                >
                                    {item.title}
                                </a>
                            );
                        })}

                    {!accessToken
                        ? RegisterLoginNavbarItems &&
                          Object.keys(RegisterLoginNavbarItems).length > 0 &&
                          RegisterLoginNavbarItems.map(function (item, index) {
                              return (
                                  <a
                                      key={index}
                                      href={item.route}
                                      className="tier-1"
                                  >
                                      {item.title}
                                  </a>
                              );
                          })
                        : UserDashboardNavbarItems &&
                          Object.keys(UserDashboardNavbarItems).length > 0 &&
                          UserDashboardNavbarItems.map(function (item, index) {
                              return (
                                  <a
                                      key={index}
                                      href={item.route}
                                      className="tier-1"
                                  >
                                      {item.title}
                                  </a>
                              );
                          })}
                </div>
            </div>
        </>
    );
}
