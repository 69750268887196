// Packages
import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

// Components
import { retrieveToken } from '../../helpers/retrieveToken';

// Images
import MenuImage from '../../assets/img/icons/menu-button.png';

// Navbar Items
import {
    HomeNavbarItems,
    RegisterLoginNavbarItems,
    FrontendNavbarItems,
    UserDashboardNavbarItems,
} from './navItems';

export default function HomeNavbar() {
    const [menuVisible, setMenuVisible] = useState(false);

    function handleMenu() {
        setMenuVisible(!menuVisible);
    }

    function closeMenu() {
        setMenuVisible(false);
    }

    const accessToken = retrieveToken();

    return (
        <>
            <div id="page-navbar" className="container-fluid">
                <div className="row">
                    <div className="col-12 d-flex align-items-center">
                        <div className="d-none d-md-flex">
                            <BrowserRouter>
                                <HashLink
                                    className="text-decoration-none text-white ps-4 pe-4"
                                    to="#about"
                                >
                                    About
                                </HashLink>

                                <HashLink
                                    className="text-decoration-none text-white ps-0 pe-4"
                                    to="#join"
                                >
                                    Joining
                                </HashLink>

                                <HashLink
                                    className="text-decoration-none text-white ps-0 pe-4"
                                    to="#promotions"
                                >
                                    Promos
                                </HashLink>

                                <HashLink
                                    className="text-decoration-none text-white ps-0 pe-4"
                                    to="#testimonials"
                                >
                                    Testimonials
                                </HashLink>

                                <HashLink
                                    className="text-decoration-none text-white ps-0 pe-4"
                                    to="#faq"
                                >
                                    FAQs
                                </HashLink>

                                <HashLink
                                    className="text-decoration-none text-yellow ps-0 pe-4"
                                    to="#join"
                                >
                                    Login/Sign Up
                                </HashLink>
                            </BrowserRouter>
                        </div>

                        <div
                            id="hamburgerButton"
                            className="d-flex flex-fill justify-content-end my-3 pe-4"
                            onClick={handleMenu}
                        >
                            <span className="me-2 d-block fw-bold text-uppercase text-white">
                                Menu
                            </span>
                            <img
                                src={MenuImage}
                                alt=""
                                className="img-fluid d-block max-height-16"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="custom-draw"
                className={
                    menuVisible ? 'd-block bg-white border-start' : 'd-none'
                }
            >
                <div className="text-end">
                    <button
                        type="button"
                        id="customDrawClose"
                        className="btn btn-wide btn-link text-end"
                        onClick={handleMenu}
                    >
                        <i className="fas fa-times h4"></i>
                    </button>
                </div>

                <div>
                    {HomeNavbarItems &&
                        Object.keys(HomeNavbarItems).length > 0 &&
                        HomeNavbarItems.map(function (item, index) {
                            return (
                                <a
                                    key={index}
                                    href={item.route}
                                    className="tier-1"
                                >
                                    {item.title}
                                </a>
                            );
                        })}

                    <HashLink
                        className="tier-1"
                        to="#about"
                        onClick={closeMenu}
                    >
                        Home &gt; About
                    </HashLink>
                    <HashLink className="tier-1" to="#join" onClick={closeMenu}>
                        Home &gt; Joining
                    </HashLink>
                    <HashLink
                        className="tier-1"
                        to="#promotions"
                        onClick={closeMenu}
                    >
                        Home &gt; Promos
                    </HashLink>
                    <HashLink
                        className="tier-1"
                        to="#testimonials"
                        onClick={closeMenu}
                    >
                        Home &gt; Testimonials
                    </HashLink>
                    <HashLink className="tier-1" to="#faq" onClick={closeMenu}>
                        Home &gt; FAQs
                    </HashLink>
                    <HashLink className="tier-1" to="#join" onClick={closeMenu}>
                        Home &gt; Login/Sign Up
                    </HashLink>
                    {FrontendNavbarItems &&
                        Object.keys(FrontendNavbarItems).length > 0 &&
                        FrontendNavbarItems.map(function (item, index) {
                            return (
                                <a
                                    key={index}
                                    href={item.route}
                                    className="tier-1"
                                >
                                    {item.title}
                                </a>
                            );
                        })}
                    {!accessToken
                        ? RegisterLoginNavbarItems &&
                          Object.keys(RegisterLoginNavbarItems).length > 0 &&
                          RegisterLoginNavbarItems.map(function (item, index) {
                              return (
                                  <a
                                      key={index}
                                      href={item.route}
                                      className="tier-1"
                                  >
                                      {item.title}
                                  </a>
                              );
                          })
                        : UserDashboardNavbarItems &&
                          Object.keys(UserDashboardNavbarItems).length > 0 &&
                          UserDashboardNavbarItems.map(function (item, index) {
                              return (
                                  <a
                                      key={index}
                                      href={item.route}
                                      className="tier-1"
                                  >
                                      {item.title}
                                  </a>
                              );
                          })}
                </div>
            </div>
        </>
    );
}
