import React from 'react';

// Icons
import NoIcon from '../../../components/Icons/NoIcon';
import YesIcon from '../../../components/Icons/YesIcon';

const Testimonial = (props) => {
    const {
        index,
        testimonial_id,
        testimonial_first_name,
        testimonial_last_name,
        testimonial_group_name,
        testimonial_group_type,
        testimonial_quote,
        testimonial_is_featured,
        testimonial_is_active,
    } = props.data;

    return (
        <tr key={index}>
            <td className="text-center align-middle">
                <a href={'/cms/testimonials/' + testimonial_id + '/edit'}>
                    <i className="fa-fw fas fa-edit"></i>
                </a>
            </td>
            <td className="text-center align-middle">
                {(testimonial_first_name ? testimonial_first_name : '') +
                    ' ' +
                    (testimonial_last_name ? testimonial_last_name : '')}
            </td>
            <td className="text-center align-middle">
                {testimonial_group_name}
            </td>
            <td className="text-center align-middle">
                {testimonial_group_type}
            </td>
            <td className="text-center align-middle">
                {testimonial_quote.split(' ').slice(0, 8).join(' ') + '...'}
            </td>
            <td className="text-center align-middle">
                {testimonial_is_featured === '1' ? <YesIcon /> : <NoIcon />}
            </td>
            <td className="text-center align-middle">
                {testimonial_is_active === '1' ? <YesIcon /> : <NoIcon />}
            </td>
        </tr>
    );
};

export default Testimonial;
