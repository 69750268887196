import React from 'react';

const Testimonial = (props) => {
    const {
        testimonial_group_name,
        testimonial_group_type,
        testimonial_quote,
    } = props.col;

    return (
        <div className={`col-12 col-lg-4 mb-4 mb-lg-0`}>
            <div className="bg-white shadow-all text-black p-5 h-100 text-center">
                <div className="testimonial-title-container mb-3">
                    <div className="testimonial-title">
                        <h2 className="h4 mt-3">{testimonial_group_name}</h2>
                    </div>
                    <div className="testimonial-type mt-1">
                        <h3 className="h6 mb-3 text-blue-dark">
                            {testimonial_group_type}
                        </h3>
                    </div>
                </div>
                <div className="testimonial-quote">
                    <p className="mb-0">"{testimonial_quote}"</p>
                </div>
            </div>
        </div>
    );
};
export default Testimonial;
