// Packages
import React from 'react';

// Images
import MyStoreLogoImage from '../../assets/img/logos/mystore-logo.png';

// Routes
import { HOME } from '../../routes';

export default function MyStoreLogo() {
    return (
        <nav className="bg-orange-medium">
            <div className="container">
                <div className="row">
                    <div className="col-12 my-4 text-center">
                        <a href={HOME}>
                            <img
                                src={MyStoreLogoImage}
                                alt="MyStore Logo"
                                className="img-fluid mystore-logo"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
}
