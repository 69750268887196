// Packages
import React, { useState } from 'react';

export default function Loader() {
    // Local state
    const [loading, setLoading] = useState(false);

    if (loading) {
        return (
            <div id="loading-indicator-container">
                <div id="loading-indicator">
                    <span className="fa-stack fa-2x">
                        <i className="fas fa-circle fa-stack-2x text-white"></i>
                        <i className="fas fa-spinner fa-pulse fa-stack-1x"></i>
                    </span>
                </div>
            </div>
        );
    } else {
        return null;
    }
}
