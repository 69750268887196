// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/PortalUnileverLogo';
import Title from '../../../components/Title';
import UploadTillSlipButton from '../../../components/Buttons/UploadTillSlip';

// Images
import DesktopBanner from '../../../assets/img/campaigns/2022/q4-cashback/2022-q4-banner-desktop.png';
import MobileBanner from '../../../assets/img/campaigns/2022/q4-cashback/2022-q4-banner-desktop.png';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { PORTAL_TILLSLIP_UPLOAD, PORTAL_DASHBOARD } from '../../../routes';

export default function PortalStokvelShow() {
    // Redux
    const dispatch = useDispatch();
    const stokvel = useSelector((state) => state.stokvel);
    const user = useSelector((state) => state.user);

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Check URL
    const url = window.location.pathname;
    const urlParams = url.split('/');

    // Component Did Mount
    useEffect(() => {
        Api.get('/stokvels/' + urlParams[3])
            .then(function (response) {
                if (response.data.success) {
                    dispatch({
                        type: 'SET_USER',
                        payload: response.data.data.user,
                    });

                    dispatch({
                        type: 'SET_STOKVEL',
                        payload: response.data.data.stokvel,
                    });
                } else {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            status: 'error',
                            message: response.data.message,
                        },
                    });
                }
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage(
                        'An error occurred while retrieving your data.'
                    );
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    // Calculate Total Till Slips Value
    function overallTillSlipValue(items) {
        let totalValue = 0.0;
        for (let index1 = 0; index1 < items.length; index1++) {
            totalValue += parseFloat(items[index1].tillslip_total_amount);
        }
        return parseFloat(totalValue);
    }

    // Calculate Total Till Slips by User
    function totalTillSlipsByUser(tillslips, userId) {
        let totalSlips = 0;
        for (let index2 = 0; index2 < tillslips.length; index2++) {
            // eslint-disable-next-line
            if (tillslips[index2].tillslip_user_id == userId) {
                totalSlips += 1;
            }
        }
        return parseInt(totalSlips);
    }

    // Calculate Total Till Slip Value by User
    function totalTillSlipValueByUser(tillslips, userId) {
        let totalSlipValue = 0.0;
        for (let index3 = 0; index3 < tillslips.length; index3++) {
            // eslint-disable-next-line
            if (tillslips[index3].tillslip_user_id == userId) {
                totalSlipValue += parseFloat(
                    tillslips[index3].tillslip_total_amount
                );
            }
        }
        return parseFloat(totalSlipValue);
    }

    // Calculate campaign total
    function campaignTotalTillSlipValue(tillslips) {
        // Calculate campaign till slip value
        let totalSlipValue = 0.0;
        for (let index = 0; index < tillslips.length; index++) {
            if (
                tillslips[index].tillslip_is_processed === '1' &&
                moment(tillslips[index].tillslip_purchase_date).isBetween(
                    '2021-10-31',
                    '2022-01-17'
                )
            ) {
                totalSlipValue += parseFloat(
                    tillslips[index].tillslip_total_amount
                );
            }
        }
        return parseFloat(totalSlipValue);
    }

    function campaignGoal(totalSlipValue) {
        // Calculate next goal
        const ratio = parseFloat(totalSlipValue) / 5000;
        const goal = parseInt(ratio + 1) * 5000;
        return goal;
    }

    // Calculate Points progress
    function rewardProgress(total, goal) {
        let progress = total / goal;
        let progressPercent = progress * 100;
        return progressPercent;
    }

    return (
        <>
            <Helmet>
                <title>Stokvel Dashboard | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="stokvel-dashboard" className="container mb-5">
                <div className="row">
                    <div className="col-12">
                        <Title
                            title={stokvel ? stokvel.stokvel_name : 'Stokvel'}
                        />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <UploadTillSlipButton />

                        <div className="border p-3 mb-5">
                            <div className="row">
                                <div className="col-12 mb-3 text-center">
                                    <picture>
                                        <source
                                            media="(max-width:768px)"
                                            srcSet={MobileBanner}
                                            className="img-fluid"
                                        />
                                        <source
                                            media="(min-width:769px)"
                                            srcSet={DesktopBanner}
                                            className="img-fluid"
                                        />
                                        <img
                                            src={DesktopBanner}
                                            alt="Purchase Unilever products to the value of R5000 and receive R500 cash back. Valid 17 November 2022 to 31 January 2023."
                                            className="img-fluid"
                                        />
                                    </picture>
                                </div>

                                <div className="col-12 col-lg-8 offset-lg-2 text-center">
                                    <p>
                                        Start uploading your till slips and get
                                        a guaranteed R500 cash back for every R5
                                        000 spent on Unilever products between
                                        17 November 2022 and 31 January 2023.
                                    </p>

                                    <p>
                                        Please view our{' '}
                                        <a
                                            target="_blank"
                                            href="/terms-and-conditions"
                                        >
                                            Terms and Conditions
                                        </a>{' '}
                                        for further information.
                                    </p>

                                    <h3 className="mb-3">
                                        {stokvel
                                            ? stokvel.stokvel_name
                                            : 'Stokvel'}
                                        's Progress
                                    </h3>

                                    <div className="progress rounded">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{
                                                width:
                                                    rewardProgress(
                                                        campaignTotalTillSlipValue(
                                                            stokvel.till_slips
                                                        ),
                                                        campaignGoal(
                                                            campaignTotalTillSlipValue(
                                                                stokvel.till_slips
                                                            )
                                                        )
                                                    ) + '%',
                                            }}
                                            aria-valuenow={rewardProgress(
                                                campaignTotalTillSlipValue(
                                                    stokvel.till_slips
                                                ),
                                                campaignGoal(
                                                    campaignTotalTillSlipValue(
                                                        stokvel.till_slips
                                                    )
                                                )
                                            )}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            R{' '}
                                            {campaignTotalTillSlipValue(
                                                stokvel.till_slips
                                            )}{' '}
                                            spent so far
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border p-3">
                            <div className="row">
                                <div className="col-12 col-lg-6 fw-bold mb-2 text-center text-lg-start">
                                    Members:
                                </div>

                                <div className="col-12 col-lg-6 fw-bold mb-2 text-center text-lg-end">
                                    {stokvel
                                        ? stokvel.stokvel_membership_count
                                        : null}
                                </div>

                                <div className="col-12 col-lg-6 fw-bold mb-2 text-center text-lg-start">
                                    Total Till Slips:
                                </div>

                                <div className="col-12 col-lg-6 fw-bold mb-2 text-center text-lg-end">
                                    {stokvel ? stokvel.till_slips.length : null}
                                </div>

                                <div className="col-12 col-lg-6 fw-bold mb-2 mb-lg-0 text-center text-lg-start">
                                    Total Value:
                                </div>

                                <div className="col-12 col-lg-6 fw-bold text-center text-lg-end">
                                    R{' '}
                                    {stokvel && stokvel.till_slips.length > 0
                                        ? parseFloat(
                                              overallTillSlipValue(
                                                  stokvel.till_slips
                                              )
                                          ).toFixed(2)
                                        : '0.00'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="table-responsive mt-5">
                            <table
                                id="table"
                                className="table table-sm table-bordered"
                            >
                                <thead className="table-light">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="text-center align-middle"
                                        >
                                            First Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle"
                                        >
                                            Last Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle"
                                        >
                                            Role
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle"
                                        >
                                            Till Slips
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle"
                                        >
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stokvel.users &&
                                        Object.keys(stokvel.users).length > 0 &&
                                        stokvel.users.map(function (
                                            item,
                                            index
                                        ) {
                                            return (
                                                <tr key={index}>
                                                    <td className="text-center align-middle">
                                                        {item.user_first_name}
                                                    </td>
                                                    <td className="text-center align-middle">
                                                        {item.user_last_name}
                                                    </td>
                                                    <td className="text-center align-middle">
                                                        {item.mobile ===
                                                        stokvel.stokvel_mobile
                                                            ? 'Chairperson'
                                                            : 'Member'}
                                                    </td>
                                                    <td className="text-center align-middle">
                                                        {stokvel.till_slips &&
                                                        Object.keys(
                                                            stokvel.till_slips
                                                        ).length > 0
                                                            ? parseInt(
                                                                  totalTillSlipsByUser(
                                                                      stokvel.till_slips,
                                                                      item.id
                                                                  )
                                                              )
                                                            : '0'}
                                                    </td>
                                                    <td className="text-center align-middle">
                                                        {stokvel.till_slips &&
                                                        Object.keys(
                                                            stokvel.till_slips
                                                        ).length > 0
                                                            ? 'R' +
                                                              parseFloat(
                                                                  totalTillSlipValueByUser(
                                                                      stokvel.till_slips,
                                                                      item.id
                                                                  )
                                                              ).toFixed(2)
                                                            : 'R 0.00'}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2 mt-5 text-start">
                        <p>
                            <a href={PORTAL_DASHBOARD}>
                                User Dashboard{' '}
                                <i className="fas fa-arrow-right float-end"></i>
                            </a>
                        </p>

                        {stokvel &&
                            user &&
                            stokvel.stokvel_mobile === user.mobile && (
                                <p>
                                    <a
                                        href={
                                            '/portal/stokvels/' +
                                            urlParams[3] +
                                            '/edit'
                                        }
                                    >
                                        Edit Stokvel{' '}
                                        <i className="fas fa-arrow-right float-end"></i>
                                    </a>
                                </p>
                            )}

                        <p>
                            <a href={PORTAL_TILLSLIP_UPLOAD}>
                                Upload Till Slip{' '}
                                <i className="fas fa-arrow-right float-end"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}
