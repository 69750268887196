// Packages
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Navbar from '../../../components/Navbar/AdminNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { CMS_DASHBOARD } from '../../../routes';

export default function CmsAirtimeCreatePage() {
    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState([]);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    // React Hook Form
    const { handleSubmit, register, reset, errors } = useForm({
        defaultValues: {
            csvFile: '',
        },
    });

    // Submit Form
    const onSubmit = () => {
        const formData = new FormData(this);
        formData.append('file', selectedFile);
        formData.append('action', 'upload csv');

        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/send-airtime', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');

                        reset();
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Send Airtime | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="airtime-create" className="container mb-5">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <Title title="Send Airtime" />

                        <p className="mb-5">
                            Please upload a CSV file with no field name and only
                            mobile numbers in the 27.. format.
                        </p>

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        name="csvFile"
                                        id="csvFile"
                                        className="custom-file-input"
                                        onChange={changeHandler}
                                        accept=".csv,text/csv"
                                        ref={register({
                                            required: {
                                                value: true,
                                                message:
                                                    'CSV File is required.',
                                            },
                                        })}
                                    />
                                    <label
                                        className="custom-file-label"
                                        htmlFor="csvFile"
                                    >
                                        Upload CSV file *
                                    </label>
                                </div>

                                {errors.csvFile && (
                                    <div className="invalid-feedback">
                                        {errors.csvFile.message}
                                    </div>
                                )}
                            </div>

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Upload"
                                    className="btn btn-wide btn-primary"
                                />

                                <a
                                    href={CMS_DASHBOARD}
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
