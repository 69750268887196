// Packages
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/PortalUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { CMS_STOKVEL_INDEX } from '../../../routes';

export default function PortalStokvelCreate() {
    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [provinces, setProvinces] = useState(null);
    const [stokvels, setStokvels] = useState(null);

    // React Hook Form
    const { handleSubmit, register, reset, errors, watch } = useForm({
        defaultValues: {
            stokvelRole: 'member',
            stokvelName: '',
            stokvelMembershipCount: 1,
            stokvelMobileNumber: '',
            stokvelRetailer: '',
            stokvelAddress: '',
            stokvelSuburb: '',
            stokvelCity: '',
            stokvelProvinceId: '',
        },
    });
    const stokvelRole = watch('stokvelRole');

    // Component Did Mount
    useEffect(() => {
        Api.get('/stokvels')
            .then(function (response) {
                setProvinces(response.data.data.provinces);
                setStokvels(response.data.data.stokvels);
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage('Unable to retrieve the form data.');
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/stokvels/create', {
                stokvel_role: data.stokvelRole,
                stokvel_name: data.stokvelName,
                stokvel_membership_count: data.stokvelMembershipCount,
                stokvel_mobile: data.stokvelMobileNumber,
                stokvel_retailer: data.stokvelRetailer,
                stokvel_address: data.stokvelAddress,
                stokvel_suburb: data.stokvelSuburb,
                stokvel_city: data.stokvelCity,
                stokvel_province_id: data.stokvelProvinceId,
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }
                    reset();
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Create Stokvel | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="stokvel-create" className="container mb-5">
                <div className="row">
                    <div className="col-12">
                        <Title title="Create Stokvel" />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-3">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label
                                    htmlFor="stokvelRole"
                                    className="fw-bold form-label small"
                                >
                                    Are you a chairperson or member? *
                                </label>

                                <select
                                    name="stokvelRole"
                                    id="stokvelRole"
                                    className="form-control"
                                    ref={register()}
                                >
                                    <option value="" disabled>
                                        Choose
                                    </option>
                                    <option value="member">
                                        Stokvel Member
                                    </option>
                                    <option value="chairperson">
                                        Stokvel Chairperson
                                    </option>
                                </select>
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="stokvelName"
                                    className="fw-bold form-label small"
                                >
                                    What is the name of your Stokvel? *
                                </label>

                                <input
                                    type="text"
                                    name="stokvelName"
                                    id="stokvelName"
                                    className="form-control"
                                    placeholder="Team Awesome"
                                    list="stokvelList"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Stokvel Name is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Stokvel Name must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Stokvel Name must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                <datalist id="stokvelList">
                                    {stokvels &&
                                        stokvels.map(function (item, index) {
                                            return (
                                                <option
                                                    key={index}
                                                    value={item.stokvel_name}
                                                >
                                                    {item.stokvel_name}
                                                </option>
                                            );
                                        })}
                                </datalist>

                                {errors.stokvelName && (
                                    <div className="invalid-feedback">
                                        {errors.stokvelName.message}
                                    </div>
                                )}
                            </div>

                            {stokvelRole === 'chairperson' && (
                                <div id="chairperson-section">
                                    <div className="mb-4">
                                        <label
                                            htmlFor="stokvelMembershipCount"
                                            className="fw-bold form-label small"
                                        >
                                            What is the number of members in
                                            your Stokvel? *
                                        </label>

                                        <input
                                            type="text"
                                            name="stokvelMembershipCount"
                                            id="stokvelMembershipCount"
                                            className="form-control"
                                            placeholder="16"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message:
                                                        'What is the number of members in your Stokvel is required.',
                                                },
                                                min: {
                                                    value: 1,
                                                    message:
                                                        'What is the number of members in your Stokvel must be between 1 and 999.',
                                                },
                                                max: {
                                                    value: 999,
                                                    message:
                                                        'What is the number of members in your Stokvel must be between 1 and 999.',
                                                },
                                                pattern: {
                                                    value: /([1-9]|[1-9][0-9]|[1-9][0-9][0-9])/g,
                                                    message:
                                                        'What is the number of members in your Stokvel is an invalid format.',
                                                },
                                            })}
                                        />

                                        {errors.stokvelMembershipCount && (
                                            <div className="invalid-feedback">
                                                {
                                                    errors
                                                        .stokvelMembershipCount
                                                        .message
                                                }
                                            </div>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label
                                            htmlFor="homeAddress"
                                            className="fw-bold form-label small"
                                        >
                                            Home Address *
                                        </label>

                                        <input
                                            type="text"
                                            name="homeAddress"
                                            id="homeAddress"
                                            className="form-control"
                                            placeholder="15 Nollsworth Crescent"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message:
                                                        'Home Address is required.',
                                                },
                                                minLength: {
                                                    value: 2,
                                                    message:
                                                        'Home Address must be between 2 and 191 characters.',
                                                },
                                                maxLength: {
                                                    value: 191,
                                                    message:
                                                        'Home Address must be between 2 and 191 characters.',
                                                },
                                            })}
                                        />

                                        {errors.homeAddress && (
                                            <div className="invalid-feedback">
                                                {errors.homeAddress.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label
                                            htmlFor="homeSuburb"
                                            className="fw-bold form-label small"
                                        >
                                            Home Suburb *
                                        </label>

                                        <input
                                            type="text"
                                            name="homeSuburb"
                                            id="homeSuburb"
                                            className="form-control"
                                            placeholder="Umhlanga"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message:
                                                        'Home Suburb is required.',
                                                },
                                                minLength: {
                                                    value: 2,
                                                    message:
                                                        'Home Suburb must be between 2 and 191 characters.',
                                                },
                                                maxLength: {
                                                    value: 191,
                                                    message:
                                                        'Home Suburb must be between 2 and 191 characters.',
                                                },
                                            })}
                                        />

                                        {errors.homeSuburb && (
                                            <div className="invalid-feedback">
                                                {errors.homeSuburb.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label
                                            htmlFor="homeCity"
                                            className="fw-bold form-label small"
                                        >
                                            Home City *
                                        </label>

                                        <input
                                            type="text"
                                            name="homeCity"
                                            id="homeCity"
                                            className="form-control"
                                            placeholder="Durban"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message:
                                                        'Home City is required.',
                                                },
                                                minLength: {
                                                    value: 2,
                                                    message:
                                                        'Home City must be between 2 and 191 characters.',
                                                },
                                                maxLength: {
                                                    value: 191,
                                                    message:
                                                        'Home City must be between 2 and 191 characters.',
                                                },
                                            })}
                                        />

                                        {errors.homeCity && (
                                            <div className="invalid-feedback">
                                                {errors.homeCity.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label
                                            htmlFor="homeProvinceId"
                                            className="fw-bold form-label small"
                                        >
                                            Home Province *
                                        </label>

                                        <select
                                            name="homeProvinceId"
                                            id="homeProvinceId"
                                            className="form-control"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message:
                                                        'Home Province is required.',
                                                },
                                            })}
                                        >
                                            <option value="" disabled>
                                                Choose
                                            </option>
                                            {provinces &&
                                                provinces.map(function (
                                                    item,
                                                    index
                                                ) {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={
                                                                item.province_id
                                                            }
                                                        >
                                                            {item.province_name}
                                                        </option>
                                                    );
                                                })}
                                        </select>

                                        {errors.homeProvinceId && (
                                            <div className="invalid-feedback">
                                                {errors.homeProvinceId.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {stokvelRole === 'member' && (
                                <div id="member-section">
                                    <div className="mb-5">
                                        <label
                                            htmlFor="stokvelMobileNumber"
                                            className="fw-bold form-label small"
                                        >
                                            What is your Stokvel Chairperson
                                            Mobile Number? *
                                        </label>

                                        <input
                                            type="text"
                                            name="stokvelMobileNumber"
                                            id="stokvelMobileNumber"
                                            className="form-control"
                                            placeholder="0660714504"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message:
                                                        'What is your Stokvel Chairperson Mobile Number is required.',
                                                },
                                                minLength: {
                                                    value: 10,
                                                    message:
                                                        'What is your Stokvel Chairperson Mobile Number must be 10 digits.',
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message:
                                                        'What is your Stokvel Chairperson Mobile Number must be 10 digits.',
                                                },
                                                pattern: {
                                                    value: /^([0]{1})([1-9]{1})([0-9]{8})$/g,
                                                    message:
                                                        'What is your Stokvel Chairperson Mobile Number is an invalid format.',
                                                },
                                            })}
                                        />

                                        {errors.stokvelMobileNumber && (
                                            <div className="invalid-feedback">
                                                {
                                                    errors.stokvelMobileNumber
                                                        .message
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            <ResponseMessage
                                message={responseMessage}
                                status={responseStatus}
                            />

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Create"
                                    className="btn btn-wide btn-primary"
                                />

                                <a
                                    href={CMS_STOKVEL_INDEX}
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
