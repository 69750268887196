// Packages
import React from 'react';

// Routes
import {
    UNILEVER_ACCESSIBILITY,
    UNILEVER_CONTACT,
    UNILEVER_COOKIE,
    UNILEVER_LEGAL,
    UNILEVER_PRIVACY,
    UNILEVER_RSS,
    UNILEVER_WEBSITE,
    TERMS_AND_CONDITIONS,
} from '../../routes';

export default function Footer() {
    return (
        <footer id="page-footer" className="mt-auto text-white">
            <div className="container">
                <div className="row py-4">
                    <div className="col-12 col-lg-10 text-center text-lg-start">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={UNILEVER_PRIVACY}
                            className="text-decoration-none small fw-light d-block mb-3 mb-lg-0 me-lg-3 d-lg-inline-block"
                        >
                            Privacy Notice
                        </a>

                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={UNILEVER_LEGAL}
                            className="text-decoration-none small fw-light d-block mb-3 mb-lg-0 me-lg-3 d-lg-inline-block"
                        >
                            Terms of Use
                        </a>

                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={UNILEVER_COOKIE}
                            className="text-decoration-none small fw-light d-block mb-3 mb-lg-0 me-lg-3 d-lg-inline-block"
                        >
                            Cookie Notice
                        </a>

                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={UNILEVER_ACCESSIBILITY}
                            className="text-decoration-none small fw-light d-block mb-3 mb-lg-0 me-lg-3 d-lg-inline-block"
                        >
                            Accessibility
                        </a>

                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={UNILEVER_RSS}
                            className="text-decoration-none small fw-light d-block mb-3 mb-lg-0 me-lg-3 d-lg-inline-block"
                        >
                            RSS
                        </a>

                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={UNILEVER_CONTACT}
                            className="text-decoration-none small fw-light d-block mb-3 mb-lg-0 me-lg-3 d-lg-inline-block"
                        >
                            Contact Us
                        </a>

                        <a
                            href={TERMS_AND_CONDITIONS}
                            className="text-decoration-none small fw-light d-block mb-3 mb-lg-0 me-lg-3 d-lg-inline-block"
                        >
                            Terms and Conditions
                        </a>
                    </div>

                    <div className="col-12 col-lg-2 text-center text-lg-end">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={UNILEVER_WEBSITE}
                            className="text-decoration-none small fw-bold d-block d-lg-inline-block text-yellow"
                        >
                            &copy; Unilever {new Date().getFullYear()}
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
