// Packages
import React from 'react';

export default function Hero(props) {
    return (
        <section id="hero">
            <h1 className="visually-hidden visually-hidden-focusable">
                {props.title}
            </h1>
            <picture>
                <source
                    media="(max-width:320px)"
                    srcSet={props.heroMobile}
                    className="img-fluid"
                />
                <source
                    media="(min-width:321px)"
                    srcSet={props.heroDesktop}
                    className="img-fluid"
                />
                <img src={props.heroDesktop} alt="" className="img-fluid" />
            </picture>
        </section>
    );
}
