// Packages
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import MyStoreLogo from '../../../../components/Logos/MyStoreLogo';
import Navbar from '../../../../components/Navbar';
import MyStoreMagazines from '../../../../components/MyStore/Magazines';

// Images
import MyStoreArticle1ImageMobile from '../../../../assets/img/mystore/mystore-article-001-banner-mobile.png';
import MyStoreArticle1ImageDesktop from '../../../../assets/img/mystore/mystore-article-001-banner-desktop.png';

export default function MyStoreArticle1Page() {
    return (
        <>
            <Helmet>
                <title>
                    Turn your customers into fans| MyStore | Unilever Shopper
                </title>
                <body className="trader-app" />
            </Helmet>

            <Navbar />

            <MyStoreLogo />

            <div id="mystore" className="bg-orange-medium">
                <section className="bg-orange-dark py-5">
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-12 text-center">
                                <picture>
                                    <source
                                        media="(max-width:320px)"
                                        srcSet={MyStoreArticle1ImageMobile}
                                        className="img-fluid"
                                    />
                                    <source
                                        media="(min-width:321px)"
                                        srcSet={MyStoreArticle1ImageDesktop}
                                        className="img-fluid"
                                    />
                                    <img
                                        src={MyStoreArticle1ImageDesktop}
                                        alt="Turn your customers into fans"
                                        className="img-fluid"
                                    />
                                </picture>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-lg-8 offset-lg-2 text-center text-white">
                                <h1 className="h1 text-center">
                                    Turn your customers into fans
                                </h1>

                                <h2 className="h3 text-center my-3">
                                    Customers vs fans: what’s the difference?
                                </h2>

                                <p>
                                    It is a simple concept, a customer may come
                                    in your store once or once in a while. A fan
                                    is a returning customer that continues to
                                    support you and your business - they see
                                    themselves as fans of your business or else
                                    they would not continually support your
                                    business.
                                </p>

                                <p>
                                    What you provide to your customers on a
                                    daily basis is something that plays a vital
                                    role in their lives, the staples they need
                                    whether washing, cooking or cleaning. Always
                                    pay special attention to what people really
                                    need, whether it’s a range of more
                                    affordable products, longer business hours
                                    or simply offering something they cannot get
                                    anywhere else in the area.
                                </p>

                                <p>
                                    <b>Make customers feel truly valued:</b>{' '}
                                    People will always return to a store or
                                    business they like, who doesn’t like being
                                    remembered? Try and get to know your regular
                                    customers, try to learn their names or what
                                    they usually purchase. It’s an easy way to
                                    show them that you value their support.
                                </p>

                                <p>
                                    <b>Always offer a consistent experience:</b>{' '}
                                    People feel like locals when it always feels
                                    the same. Whether your customer comes in to
                                    purchase a box of tea in the morning or five
                                    minutes before you close always ensure their
                                    experience is the same.
                                </p>

                                <p>
                                    <b>Own your uniqueness:</b> A store or
                                    business that offers something slightly
                                    different can really set you apart from
                                    other stores in the local community. If
                                    everyone closes their shop at 6pm, be the
                                    local business that offers extra convenience
                                    till 7pm. People will always remember you
                                    have longer hours and support you.
                                </p>

                                <p>
                                    <b>Go the extra mile:</b> As a small
                                    business, always try to have that 1 product
                                    that your locals need but cannot always find
                                    everywhere else. People love businesses who
                                    will do that little bit extra for them -
                                    something that larger stores won't do.
                                </p>

                                <p>
                                    <b>Focus on the little things:</b> If you
                                    have free samples or products to trial make
                                    sure to let your regular customers know.
                                    Your customers will always appreciate a
                                    little something extra.
                                </p>

                                <p className="mb-0">
                                    Without customers coming back you can’t grow
                                    your business, appreciate your customers and
                                    they will appreciate you and continue to
                                    support your business.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5">
                    <div className="container">
                        <MyStoreMagazines />
                    </div>
                </section>
            </div>
        </>
    );
}
