// Packages
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// Redux Action Types
import {
    SET_ACCESS_TOKEN,
    SET_GENDER_LIST,
    SET_LANGUAGE_LIST,
    SET_PRODUCT_LIST,
    SET_PROVINCE_LIST,
    SET_REFERAL_TYPE_LIST,
    SET_RETAILER_LIST,
    SET_STORE_AMOUNT_LIST,
    SET_STORE_TYPE_LIST,
    SET_STOKVEL_LIST,
    SET_STOKVEL_REGISTER,
    SET_TRADER_REGISTER,
    SET_PRINTADTYPE,
    SET_PRINTADTYPES_LIST,
    SET_PRODUCT,
    SET_STOKVEL,
    SET_TRADER,
    SET_USER,
    SET_USER_STOKVEL,
    SET_TESTIMONIAL,
    SET_TESTIMONIALS_LIST,
    SET_TESTIMONIALS_ACTIVE_LIST,
    SET_TESTIMONIALS_FEATURED_LIST,
    SET_TILL_SLIP,
    SET_TILL_SLIP_ITEM,
    SET_TILL_SLIP_LIST,
    SET_TILL_SLIP_ITEMS_ARRAY_LIST,
    SET_USER_TRADER,
    SET_USER_LIST,
    UNSET_SESSION,
    UNSET_ACCESS_TOKEN,
    UNSET_GENDER_LIST,
    UNSET_LANGUAGE_LIST,
    UNSET_PROVINCE_LIST,
    UNSET_PRODUCT_LIST,
    UNSET_REFERAL_TYPE_LIST,
    UNSET_RETAILER_LIST,
    UNSET_STORE_AMOUNT_LIST,
    UNSET_STORE_TYPE_LIST,
    UNSET_STOKVEL_LIST,
    UNSET_STOKVEL_REGISTER,
    UNSET_TRADER_REGISTER,
    UNSET_PRINTADTYPE,
    UNSET_PRINTADTYPES_LIST,
    UNSET_PRODUCT,
    UNSET_STOKVEL,
    UNSET_TESTIMONIAL,
    UNSET_TESTIMONIALS_LIST,
    UNSET_TESTIMONIALS_ACTIVE_LIST,
    UNSET_TESTIMONIALS_FEATURED_LIST,
    UNSET_TILL_SLIP,
    UNSET_TILL_SLIP_ITEM,
    UNSET_TILL_SLIP_LIST,
    UNSET_TILL_SLIP_ITEMS_ARRAY_LIST,
    UNSET_TRADER,
    UNSET_USER,
    UNSET_USER_STOKVEL,
    UNSET_USER_TRADER,
    UNSET_USER_LIST,
    SET_TRADER_LIST,
    UNSET_TRADER_LIST,
    SET_TRADER_BUNDLE_PROMOTION_LIST,
    UNSET_TRADER_BUNDLE_PROMOTION_LIST,
    SET_TRADER_BUNDLE_PROMOTION,
    UNSET_TRADER_BUNDLE_PROMOTION,
} from './types';

// Initial State
const initialState = {
    accessToken: false,

    genderList: null,
    languageList: null,
    provinceList: null,
    referralTypeList: null,
    storeAmountList: null,
    storeTypeList: null,
    stokvelList: null,
    testimonialsList: null,
    tillslipImage: null,
    tillslipItem: null,
    tillSlipList: null,
    tillslipItemsArrayLsit: null,
    traderList: null,
    userList: null,

    stokvelRegister: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        mobileNumber: '',
        dateOfBirth: '',
        password: '',
        passwordConfirm: '',
        genderId: '',
        languageId: '',
        homeAddress: '',
        homeSuburb: '',
        homeCity: '',
        homeProvinceId: '',
        stokvelRole: '',
        stokvelName: '',
        stokvelMembershipCount: '',
        stokvelMobileNumber: '',
        referralTypeId: '',
        agentId: 1,
        confirmAge: false,
        optUnileverEmail: false,
        optUnileverSms: false,
        confirmTerms: false,
    },
    traderRegister: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        mobileNumber: '',
        dateOfBirth: '',
        password: '',
        passwordConfirm: '',
        genderId: '',
        languageId: '',
        customerNumber: '',
        homeAddress: '',
        homeSuburb: '',
        homeCity: '',
        homeProvinceId: '',
        traderName: '',
        traderAddress: '',
        traderSuburb: '',
        traderCity: '',
        traderProvinceId: '',
        traderStoreAmount: '',
        traderStoreType: '',
        referralTypeId: '',
        agentId: 1,
        confirmAge: false,
        optUnileverEmail: false,
        optUnileverSms: false,
        confirmTerms: false,
    },

    stokvel: {
        stokvel_name: '',
        stokvel_membership_count: 1,
        stokvel_mobile: '',
        stokvel_address: '',
        stokvel_city: '',
        stokvel_suburb: '',
        stokvel_province_id: '',
        till_slips: [],
        users: [],
    },
    tillslip: null,
    trader: {
        trader_name: '',
        trader_store_amount_id: '',
        trader_store_type_id: '',
        trader_mobile: '',
        trader_address: '',
        trader_city: '',
        trader_suburb: '',
        trader_province_id: '',
        till_slips: [],
        users: [],
    },

    user: {
        user_first_name: '',
        user_last_name: '',
        email: '',
        mobile: '',
        user_dob: '',
        user_gender_id: '',
        user_language_id: '',
        user_home_address: '',
        user_home_suburb: '',
        user_home_city: '',
        user_home_province_id: '',
        user_home_postalcode: '',
        user_confirmage: false,
        user_optunilever_email: false,
        user_optunilever_sms: false,
        user_confirmterms: false,
    },
    userStokvel: null,
    userTrader: null,
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        // Reset session
        case UNSET_SESSION:
            return {
                ...state,
                accessToken: false,

                genderList: null,
                languageList: null,
                productList: null,
                provinceList: null,
                referralTypeList: null,
                storeAmountList: null,
                storeTypeList: null,
                stokvelList: null,
                testimonialsList: null,
                tillslipImage: null,
                tillslipItem: null,
                tillSlipList: null,
                tillslipItemsArrayLsit: null,
                traderList: null,
                userList: null,

                stokvelRegister: {
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    mobileNumber: '',
                    dateOfBirth: '',
                    password: '',
                    passwordConfirm: '',
                    genderId: '',
                    languageId: '',
                    homeAddress: '',
                    homeSuburb: '',
                    homeCity: '',
                    homeProvinceId: '',
                    stokvelRole: '',
                    stokvelName: '',
                    stokvelMembershipCount: '',
                    stokvelMobileNumber: '',
                    referralTypeId: '',
                    agentId: 1,
                    confirmAge: false,
                    optUnileverEmail: false,
                    optUnileverSms: false,
                    confirmTerms: false,
                },
                tillslip: null,
                traderRegister: {
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    mobileNumber: '',
                    dateOfBirth: '',
                    password: '',
                    passwordConfirm: '',
                    genderId: '',
                    languageId: '',
                    customerNumber: '',
                    homeAddress: '',
                    homeSuburb: '',
                    homeCity: '',
                    homeProvinceId: '',
                    traderName: '',
                    traderAddress: '',
                    traderSuburb: '',
                    traderCity: '',
                    traderProvinceId: '',
                    traderStoreAmount: '',
                    traderStoreType: '',
                    referralTypeId: '',
                    agentId: 1,
                    confirmAge: false,
                    optUnileverEmail: false,
                    optUnileverSms: false,
                    confirmTerms: false,
                },

                stokvel: {
                    stokvel_name: '',
                    stokvel_membership_count: '',
                    stokvel_mobile: '',
                    stokvel_address: '',
                    stokvel_city: '',
                    stokvel_suburb: '',
                    stokvel_province_id: '',
                    till_slips: [],
                },
                trader: {
                    trader_name: '',
                    trader_store_amount_id: '',
                    trader_store_type_id: '',
                    trader_mobile: '',
                    trader_address: '',
                    trader_city: '',
                    trader_suburb: '',
                    trader_province_id: '',
                    till_slips: [],
                },

                user: {
                    user_first_name: '',
                    user_last_name: '',
                    email: '',
                    mobile: '',
                    user_dob: '',
                    user_gender_id: '',
                    user_language_id: '',
                    user_home_address: '',
                    user_home_suburb: '',
                    user_home_city: '',
                    user_home_province_id: '',
                    user_home_postalcode: '',
                    user_confirmage: false,
                    user_optunilever_email: false,
                    user_optunilever_sms: false,
                    user_confirmterms: false,
                },
                userStokvel: null,
                userTrader: null,
            };

        // Access Token
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload,
            };

        case UNSET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: false,
            };

        // Lists
        case SET_GENDER_LIST:
            return {
                ...state,
                genderList: action.payload,
            };

        case UNSET_GENDER_LIST:
            return {
                ...state,
                genderList: null,
            };

        case SET_LANGUAGE_LIST:
            return {
                ...state,
                languageList: action.payload,
            };

        case UNSET_LANGUAGE_LIST:
            return {
                ...state,
                languageList: null,
            };
        case SET_PRINTADTYPE:
            return {
                ...state,
                printAdType: action.payload,
            };

        case UNSET_PRINTADTYPE:
            return {
                ...state,
                printAdType: null,
            };
        case SET_PRINTADTYPES_LIST:
            return {
                ...state,
                printAdTypeList: action.payload,
            };

        case UNSET_PRINTADTYPES_LIST:
            return {
                ...state,
                printAdTypeList: null,
            };
        case SET_PRODUCT_LIST:
            return {
                ...state,
                productList: action.payload,
            };

        case UNSET_PRODUCT_LIST:
            return {
                ...state,
                productList: null,
            };

        case SET_PROVINCE_LIST:
            return {
                ...state,
                provinceList: action.payload,
            };

        case UNSET_PROVINCE_LIST:
            return {
                ...state,
                provinceList: null,
            };

        case SET_REFERAL_TYPE_LIST:
            return {
                ...state,
                referralTypeList: action.payload,
            };

        case UNSET_REFERAL_TYPE_LIST:
            return {
                ...state,
                referralTypeList: null,
            };

        case SET_RETAILER_LIST:
            return {
                ...state,
                retailerList: action.payload,
            };

        case UNSET_RETAILER_LIST:
            return {
                ...state,
                retailerList: null,
            };

        case SET_STORE_AMOUNT_LIST:
            return {
                ...state,
                storeAmountList: action.payload,
            };

        case UNSET_STORE_AMOUNT_LIST:
            return {
                ...state,
                storeAmountList: null,
            };

        case SET_STORE_TYPE_LIST:
            return {
                ...state,
                storeTypeList: action.payload,
            };

        case UNSET_STORE_TYPE_LIST:
            return {
                ...state,
                storeTypeList: null,
            };

        case SET_STOKVEL_LIST:
            return {
                ...state,
                stokvelList: action.payload,
            };

        case UNSET_STOKVEL_LIST:
            return {
                ...state,
                stokvelList: null,
            };

        case SET_TRADER_LIST:
            return {
                ...state,
                traderList: action.payload,
            };

        case UNSET_TRADER_LIST:
            return {
                ...state,
                traderList: null,
            };

        case SET_TRADER_BUNDLE_PROMOTION_LIST:
            return {
                ...state,
                traderBundlePromotionList: action.payload,
            };

        case UNSET_TRADER_BUNDLE_PROMOTION_LIST:
            return {
                ...state,
                traderBundlePromotionList: null,
            };

        case SET_TESTIMONIALS_LIST:
            return {
                ...state,
                testimonialsList: action.payload,
            };

        case UNSET_TESTIMONIALS_LIST:
            return {
                ...state,
                testimonialsList: null,
            };

        case SET_TESTIMONIALS_ACTIVE_LIST:
            return {
                ...state,
                activeTestimonialsList: action.payload,
            };

        case UNSET_TESTIMONIALS_ACTIVE_LIST:
            return {
                ...state,
                activeTestimonialsList: null,
            };

        case SET_TESTIMONIALS_FEATURED_LIST:
            return {
                ...state,
                featuredTestimonialsList: action.payload,
            };

        case UNSET_TESTIMONIALS_FEATURED_LIST:
            return {
                ...state,
                featuredTestimonialsList: null,
            };

        case SET_TILL_SLIP_LIST:
            return {
                ...state,
                tillSlipList: action.payload,
            };

        case UNSET_TILL_SLIP_LIST:
            return {
                ...state,
                tillSlipList: null,
            };

        case SET_TILL_SLIP_ITEMS_ARRAY_LIST:
            return {
                ...state,
                tillslipItemsArrayLsit: action.payload,
            };

        case UNSET_TILL_SLIP_ITEMS_ARRAY_LIST:
            return {
                ...state,
                tillslipItemsArrayLsit: null,
            };

        case SET_USER_LIST:
            return {
                ...state,
                userList: action.payload,
            };

        case UNSET_USER_LIST:
            return {
                ...state,
                userList: null,
            };

        // Register - Stokvel
        case SET_STOKVEL_REGISTER:
            return {
                ...state,
                stokvelRegister: action.payload,
            };

        case UNSET_STOKVEL_REGISTER:
            return {
                ...state,
                stokvelRegister: {
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    mobileNumber: '',
                    dateOfBirth: '',
                    password: '',
                    passwordConfirm: '',
                    genderId: '',
                    languageId: '',
                    homeAddress: '',
                    homeSuburb: '',
                    homeCity: '',
                    homeProvinceId: '',
                    stokvelRole: 'member',
                    stokvelName: '',
                    stokvelMembershipCount: 1,
                    stokvelMobileNumber: '',
                    referralTypeId: '',
                    agentId: 1,
                    confirmAge: false,
                    optUnileverEmail: false,
                    optUnileverSms: false,
                    confirmTerms: false,
                },
            };

        // Register - Trader
        case SET_TRADER_REGISTER:
            return {
                ...state,
                traderRegister: action.payload,
            };

        case UNSET_TRADER_REGISTER:
            return {
                ...state,
                traderRegister: {
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    mobileNumber: '',
                    dateOfBirth: '',
                    password: '',
                    passwordConfirm: '',
                    genderId: '',
                    languageId: '',
                    customerNumber: '',
                    homeAddress: '',
                    homeSuburb: '',
                    homeCity: '',
                    homeProvinceId: '',
                    traderName: '',
                    traderAddress: '',
                    traderSuburb: '',
                    traderCity: '',
                    traderProvinceId: '',
                    traderStoreAmount: '',
                    traderStoreType: '',
                    referralTypeId: '',
                    agentId: 1,
                    confirmAge: false,
                    optUnileverEmail: false,
                    optUnileverSms: false,
                    confirmTerms: false,
                },
            };

        // Individual Product
        case SET_PRODUCT:
            return {
                ...state,
                product: action.payload,
            };

        case UNSET_PRODUCT:
            return {
                ...state,
                product: null,
            };

        // Individual Stokvel
        case SET_STOKVEL:
            return {
                ...state,
                stokvel: action.payload,
            };

        case UNSET_STOKVEL:
            return {
                ...state,
                stokvel: {
                    stokvel_name: '',
                    stokvel_membership_count: 1,
                    stokvel_mobile: '',
                    stokvel_address: '',
                    stokvel_city: '',
                    stokvel_suburb: '',
                    stokvel_province_id: '',
                    till_slips: [],
                    users: [],
                },
            };

        //individual testimonial
        case SET_TESTIMONIAL:
            return {
                ...state,
                testimonial: action.payload,
            };

        case UNSET_TESTIMONIAL:
            return {
                ...state,
                testimonial: null,
            };

        // Individual Till Slip
        case SET_TILL_SLIP:
            return {
                ...state,
                tillslip: action.payload,
            };

        case UNSET_TILL_SLIP:
            return {
                ...state,
                tillslip: null,
            };

        // Individual Till Slip Item
        case SET_TILL_SLIP_ITEM:
            return {
                ...state,
                tillslipItem: action.payload,
            };

        case UNSET_TILL_SLIP_ITEM:
            return {
                ...state,
                tillslipItem: null,
            };

        // Individual Trader
        case SET_TRADER:
            return {
                ...state,
                trader: action.payload,
            };

        case UNSET_TRADER:
            return {
                ...state,
                trader: {
                    trader_name: '',
                    trader_store_amount_id: '',
                    trader_store_type_id: '',
                    trader_mobile: '',
                    trader_address: '',
                    trader_city: '',
                    trader_suburb: '',
                    trader_province_id: '',
                    till_slips: [],
                    users: [],
                },
            };

        // Individual Trader's Promotions and Bundles
        case SET_TRADER_BUNDLE_PROMOTION:
            return {
                ...state,
                traderBundlePromotion: action.payload,
            };

        case UNSET_TRADER_BUNDLE_PROMOTION:
            return {
                ...state,
                traderBundlePromotion: null,
            };

        // User Profile
        case SET_USER:
            return {
                ...state,
                user: action.payload,
            };

        case UNSET_USER:
            return {
                ...state,
                user: {
                    user_first_name: '',
                    user_last_name: '',
                    email: '',
                    mobile: '',
                    user_dob: '',
                    user_gender_id: '',
                    user_language_id: '',
                    user_home_address: '',
                    user_home_suburb: '',
                    user_home_city: '',
                    user_home_province_id: '',
                    user_home_postalcode: '',
                    user_confirmage: false,
                    user_optunilever_email: false,
                    user_optunilever_sms: false,
                    user_confirmterms: false,
                },
            };

        // Stokvels owned by User
        case SET_USER_STOKVEL:
            return {
                ...state,
                userStokvel: action.payload,
            };

        case UNSET_USER_STOKVEL:
            return {
                ...state,
                userStokvel: null,
            };

        // Traders owned by User
        case SET_USER_TRADER:
            return {
                ...state,
                userTrader: action.payload,
            };

        case UNSET_USER_TRADER:
            return {
                ...state,
                userTrader: null,
            };

        default:
            return state;
    }
};

// Redux Persist
const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export const persistor = persistStore(store);
