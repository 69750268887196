// Packages
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Components
import TraderRegisterLayout from './TraderRegisterLayout';
import ResponseMessage from '../../../components/ResponseMessage';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { TRADER_LOGIN, UNILEVER_LEGAL } from '../../../routes';

const AuthRegisterStokvelStep4 = (props) => {
    // Redux
    const traderRegister = useSelector((state) => state.traderRegister);
    const dispatch = useDispatch();

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // React Hook Form
    const { handleSubmit, register, errors, reset } = useForm();

    // Form Submit
    const onSubmit = (data) => {
        dispatch({
            type: 'SET_TRADER_REGISTER',
            payload: {
                ...traderRegister,
                confirmAge: data.confirmAge,
                optUnileverEmail: data.optUnileverEmail,
                optUnileverSms: data.optUnileverSms,
                confirmTerms: data.confirmTerms,
            },
        });

        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/register', {
                user_type: 'trader',
                password: traderRegister.password,
                user_customer_number: traderRegister.customerNumber,
                user_first_name: traderRegister.firstName,
                user_last_name: traderRegister.lastName,
                email: traderRegister.emailAddress,
                mobile: traderRegister.mobileNumber,
                user_dob: traderRegister.dateOfBirth,
                user_gender_id: traderRegister.genderId,
                user_language_id: traderRegister.languageId,
                user_home_address: traderRegister.homeAddress,
                user_home_suburb: traderRegister.homeSuburb,
                user_home_city: traderRegister.homeCity,
                user_home_province_id: traderRegister.homeProvinceId,

                trader_name: traderRegister.traderName,
                trader_address: traderRegister.traderAddress,
                trader_suburb: traderRegister.traderSuburb,
                trader_city: traderRegister.traderCity,
                trader_province_id: traderRegister.traderProvinceId,
                trader_retailer: traderRegister.traderRetailer,
                trader_store_amount: traderRegister.traderStoreAmount,
                trader_store_type: traderRegister.traderStoreType,
                referral_type_id: traderRegister.referralTypeId,

                user_referral_type_id: traderRegister.referralTypeId,
                user_agent_id: 1,
                user_confirmage: data.confirmAge ? 1 : 0,
                user_optunilever_email: data.optUnileverEmail ? 1 : 0,
                user_optunilever_sms: data.optUnileverSms ? 1 : 0,
                user_confirmterms: data.confirmTerms ? 1 : 0,
            })
                .then(function (response) {
                    dispatch({
                        type: 'UNSET_GENDER_LIST',
                    });

                    dispatch({
                        type: 'UNSET_GENDER_LIST',
                    });

                    dispatch({
                        type: 'UNSET_LANGUAGE_LIST',
                    });

                    dispatch({
                        type: 'UNSET_PROVINCE_LIST',
                    });

                    dispatch({
                        type: 'UNSET_REFERAL_TYPE_LIST',
                    });

                    dispatch({
                        type: 'UNSET_TRADER_LIST',
                    });

                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');

                        dispatch({
                            type: 'UNSET_TRADER_REGISTER',
                        });

                        reset();

                        props.history.push(TRADER_LOGIN);
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }
                })
                .catch(function (error) {
                    setResponseMessage(error.response.data.message);
                    setResponseStatus('error');
                });
        });
    };

    return (
        <>
            <TraderRegisterLayout
                stepName="Confirmation"
                stepNumber="4"
                stepProgress="w-100"
            >
                <ResponseMessage
                    message={responseMessage}
                    status={responseStatus}
                />

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-4">
                        <div className="form-check">
                            <label
                                htmlFor="confirmAge"
                                className="form-check-label small "
                            >
                                <input
                                    type="checkbox"
                                    name="confirmAge"
                                    id="confirmAge"
                                    className="form-check-input"
                                    value={true}
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Please accept that you are over 18 years old.',
                                        },
                                    })}
                                />
                                <span className="fw-bold">Yes</span> - I confirm
                                that I am over 18 years old. *
                            </label>
                        </div>

                        {errors.confirmAge && (
                            <div className="invalid-feedback">
                                {errors.confirmAge.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <div className="form-check">
                            <label
                                htmlFor="optUnileverEmail"
                                className="form-check-label"
                            >
                                <input
                                    type="checkbox"
                                    name="optUnileverEmail"
                                    id="optUnileverEmail"
                                    className="form-check-input"
                                    value={true}
                                    ref={register}
                                />
                                <span className="fw-bold">Yes</span>, I want to
                                receive offers and updates from{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.unilever.co.za/brands/"
                                >
                                    Unilever Brands
                                </a>{' '}
                                via email and online advertising tailored to my
                                interest and preferences.
                            </label>
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="form-check">
                            <label
                                htmlFor="optUnileverSms"
                                className="form-check-label"
                            >
                                <input
                                    type="checkbox"
                                    name="optUnileverSms"
                                    id="optUnileverSms"
                                    className="form-check-input"
                                    value={true}
                                    ref={register}
                                />
                                <span className="fw-bold">Yes</span>, I want to
                                receive offers and updates from{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.unilever.co.za/brands/"
                                >
                                    Unilever Brands
                                </a>{' '}
                                via SMS and online advertising tailored to my
                                interest and preferences.
                            </label>
                        </div>
                    </div>

                    <div className="mb-5">
                        <div className="form-check">
                            <label
                                htmlFor="confirmTerms"
                                className="form-check-label small "
                            >
                                <input
                                    type="checkbox"
                                    name="confirmTerms"
                                    id="confirmTerms"
                                    className="form-check-input"
                                    value={true}
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Please accept that you agree to the Terms of Use.',
                                        },
                                    })}
                                />
                                <span className="fw-bold">Yes</span> - I accept
                                the{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={UNILEVER_LEGAL}
                                >
                                    Terms of Use
                                </a>
                                . *
                            </label>
                        </div>

                        {errors.confirmTerms && (
                            <div className="invalid-feedback">
                                {errors.confirmTerms.message}
                            </div>
                        )}
                    </div>

                    <ResponseMessage
                        message={responseMessage}
                        status={responseStatus}
                    />

                    <div className="mt-100 mb-270 text-center">
                        <a
                            href="/trader/register/3"
                            className="btn btn-wide btn-secondary btn-lg text-uppercase me-2"
                        >
                            <i className="fas fa-angle-left"></i> Previous
                        </a>

                        <input
                            type="submit"
                            value="Signup"
                            className="btn btn-wide btn-primary btn-lg text-uppercase"
                        />
                    </div>
                </form>
            </TraderRegisterLayout>
        </>
    );
};

export default withRouter(AuthRegisterStokvelStep4);
