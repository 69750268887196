import React from 'react';

const Traders = (props) => {
    const {
        index,
        trader_id,
        trader_name,
        trader_membership_count,
        till_slips,
    } = props.data;

    // Calculate each Trader Till Slip Value
    function eachTraderTillSlipValue(items) {
        let totalValue = 0.0;
        for (let i = 0; i < items.length; i++) {
            totalValue += parseFloat(items[i].tillslip_total_amount);
        }
        return totalValue;
    }

    return (
        <tr key={index}>
            <td className="text-center align-middle">
                <a href={'traders/' + trader_id + '/edit'}>
                    <i className="fa-fw fas fa-edit"></i>
                </a>
            </td>
            <td className="text-center align-middle">
                <a href={'traders/' + trader_id + '/edit'}>{trader_name}</a>
            </td>
            <td className="text-center align-middle">
                {trader_membership_count}
            </td>
            <td className="text-center align-middle">{till_slips.length}</td>
            <td className="text-center align-middle">
                R{' '}
                {till_slips.length > 0
                    ? parseFloat(eachTraderTillSlipValue(till_slips)).toFixed(2)
                    : 0.0}
            </td>
        </tr>
    );
};

export default Traders;
