// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import Pagination from '../../../helpers/Pagination';
import Products from './Products';
import Navbar from '../../../components/Navbar/AdminNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { CMS_DASHBOARD, CMS_PRODUCT_CREATE } from '../../../routes';

export default function TraderIndex() {
    // Redux
    const dispatch = useDispatch();
    const productList = useSelector((state) => state.productList);

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Component Did Mount
    useEffect(() => {
        Api.get('/products')
            .then(function (response) {
                if (response.data.success) {
                    dispatch({
                        type: 'SET_PRODUCT_LIST',
                        payload: response.data.data.products,
                    });
                } else {
                    setResponseMessage(response.data.message);
                    setResponseStatus('error');
                }
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage(
                        'An error occurred while retrieving your data.'
                    );
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Products | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="products-index" className="container mb-5">
                <div className="row">
                    <div className="col-12 mb-5 text-center">
                        <Title title="Products" />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <a
                            href={CMS_PRODUCT_CREATE}
                            className="btn btn-primary text-uppercase"
                        >
                            Create Product
                        </a>
                    </div>
                </div>

                {productList && Object.keys(productList).length > 0 ? (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table
                                        id="table-traders"
                                        className="mb-0 table table-bordered table-striped table-sm"
                                    >
                                        <thead className="table-light">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    &nbsp;
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Product Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Product Price
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Active
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <Pagination
                                                data={productList}
                                                RenderComponent={Products}
                                                pageLimit={5}
                                                dataLimit={50}
                                            />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <p className="mb-0 text-center">No records were found.</p>
                )}

                <div className="row mt-5">
                    <div className="col-12 col-lg-8 offset-lg-2 text-start">
                        <p>
                            <a href={CMS_DASHBOARD}>
                                Admin Dashboard{' '}
                                <i className="fas fa-arrow-right float-end"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}
