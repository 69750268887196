// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import DatePicker from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/PortalUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { PORTAL_DASHBOARD, UNILEVER_LEGAL } from '../../../routes';

export default function PortalUserUpdateProfile() {
    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Datepicker
    const dateMin = moment().subtract(118, 'years').toDate();
    const dateMax = moment().subtract(18, 'years').toDate();

    // Redux
    const dispatch = useDispatch();
    const genderList = useSelector((state) => state.genderList);
    const languageList = useSelector((state) => state.languageList);
    const provinceList = useSelector((state) => state.provinceList);
    const user = useSelector((state) => state.user);

    // React Hook Form
    const { handleSubmit, register, reset, errors, control } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            mobileNumber: '',
            dateOfBirth: '',
            genderId: '',
            languageId: '',
            homeAddress: '',
            homeSuburb: '',
            homeCity: '',
            homeProvinceId: '',
            homePostalCode: '',
            confirmAge: false,
            optStokvel: false,
            optUnilever: false,
            optPromotional: false,
            confirmTerms: false,
        },
    });

    // Component Did Mount
    useEffect(() => {
        Api.get('/users/update-profile')
            .then(function (response) {
                dispatch({
                    type: 'SET_GENDER_LIST',
                    payload: response.data.data.genders,
                });

                dispatch({
                    type: 'SET_LANGUAGE_LIST',
                    payload: response.data.data.languages,
                });

                dispatch({
                    type: 'SET_PROVINCE_LIST',
                    payload: response.data.data.provinces,
                });

                dispatch({
                    type: 'SET_USER',
                    payload: response.data.data.user,
                });

                reset({
                    firstName: user.user_first_name,
                    lastName: user.user_last_name,
                    emailAddress: user.email,
                    mobileNumber: user.mobile,
                    dateOfBirth: user.user_dob,
                    genderId: user.user_gender_id,
                    languageId: user.user_language_id,
                    homeAddress: user.user_home_address,
                    homeSuburb: user.user_home_suburb,
                    homeCity: user.user_home_city,
                    homeProvinceId: user.user_home_province_id,
                    homePostalCode: user.user_home_postalcode,

                    confirmAge: user.user_confirmage === '1' ? true : false,
                    optUnileverEmail:
                        user.user_optunilever_email === '1' ? true : false,
                    optUnileverSms:
                        user.user_optunilever_sms === '1' ? true : false,
                    confirmTerms: user.user_confirmterms === '1' ? true : false,
                });
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage('Unable to retrieve your profile.');
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, [reset]);

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/users/update-profile', {
                user_first_name: data.firstName,
                user_last_name: data.lastName,
                email: data.emailAddress,
                mobile: data.mobileNumber,
                user_dob: data.dateOfBirth,
                user_gender_id: data.genderId,
                user_language_id: data.languageId,
                user_home_address: data.homeAddress,
                user_home_suburb: data.homeSuburb,
                user_home_city: data.homeCity,
                user_home_province_id: data.homeProvinceId,
                user_home_postalcode: data.homePostalCode,

                user_confirmage: data.confirmAge === 'true' ? 1 : 0,
                user_optunilever_email:
                    data.optUnileverEmail === 'true' ? 1 : 0,
                user_optunilever_sms: data.optUnileverSms === 'true' ? 1 : 0,
                user_confirmterms: data.confirmTerms === 'true' ? 1 : 0,
            })
                .then(function (response) {
                    if (response.data.success) {
                        dispatch({
                            type: 'SET_USER',
                            payload: response.data.data.user,
                        });

                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Update Your Profile | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="update-profile" className="container mb-5">
                <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-3">
                        <Title title="Update Your Profile" />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label
                                    htmlFor="firstName"
                                    className="fw-bold form-label small"
                                >
                                    First Name *
                                </label>

                                <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    className="form-control"
                                    placeholder="Jane"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: 'First Name is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'First Name must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'First Name must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.firstName && (
                                    <div className="invalid-feedback">
                                        {errors.firstName.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="lastName"
                                    className="fw-bold form-label small"
                                >
                                    Last Name *
                                </label>

                                <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    className="form-control"
                                    placeholder="Doe"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: 'Last Name is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Last Name must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Last Name must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.lastName && (
                                    <div className="invalid-feedback">
                                        {errors.lastName.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="emailAddress"
                                    className="fw-bold form-label small"
                                >
                                    Email Address
                                </label>

                                <input
                                    type="email"
                                    name="emailAddress"
                                    id="emailAddress"
                                    className="form-control"
                                    placeholder="someone@company.co.za"
                                    ref={register({
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Email Address must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Email Address must be between 2 and 191 characters.',
                                        },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message:
                                                'Email Address is an invalid format.',
                                        },
                                    })}
                                />
                                {errors.emailAddress && (
                                    <div className="invalid-feedback">
                                        {errors.emailAddress.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="mobileNumber"
                                    className="fw-bold form-label small"
                                >
                                    Mobile Number *
                                </label>

                                <input
                                    type="text"
                                    name="mobileNumber"
                                    id="mobileNumber"
                                    className="form-control"
                                    placeholder="0660714504"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Mobile Number is required.',
                                        },
                                        minLength: {
                                            value: 10,
                                            message:
                                                'Mobile Number must be 10 digits.',
                                        },
                                        maxLength: {
                                            value: 10,
                                            message:
                                                'Mobile Number must be 10 digits.',
                                        },
                                        pattern: {
                                            value: /^([0]{1})([1-9]{1})([0-9]{8})$/g,
                                            message:
                                                'Mobile Number is an invalid format.',
                                        },
                                    })}
                                />

                                {errors.mobileNumber && (
                                    <div className="invalid-feedback">
                                        {errors.mobileNumber.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="dateOfBirth"
                                    className="fw-bold form-label small"
                                >
                                    Date of Birth *
                                </label>

                                <Controller
                                    name="dateOfBirth"
                                    className="form-control"
                                    control={control}
                                    valueName="selected"
                                    defaultValue={
                                        user.user_dob === ''
                                            ? ''
                                            : moment(user.user_dob).toDate()
                                    }
                                    rules={{
                                        required: {
                                            value: true,
                                            message:
                                                'Date of Birth is required.',
                                        },
                                    }}
                                    render={({ onChange, value }) => (
                                        <DatePicker
                                            className="form-control"
                                            id="dateOfBirth"
                                            dateFormat="yyyy-MM-dd"
                                            minDate={dateMin}
                                            maxDate={dateMax}
                                            showMonthDropdown
                                            showYearDropdown
                                            selected={
                                                value === ''
                                                    ? ''
                                                    : moment(value).toDate()
                                            }
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                {errors.dateOfBirth && (
                                    <div className="invalid-feedback">
                                        {errors.dateOfBirth.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="genderId"
                                    className="fw-bold form-label small"
                                >
                                    Gender *
                                </label>

                                <select
                                    name="genderId"
                                    id="genderId"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: 'Gender is required.',
                                        },
                                    })}
                                >
                                    <option value="" disabled>
                                        Choose
                                    </option>
                                    {genderList &&
                                        genderList.map(function (item, index) {
                                            return (
                                                <option
                                                    key={index}
                                                    value={item.gender_id}
                                                >
                                                    {item.gender_name}
                                                </option>
                                            );
                                        })}
                                </select>

                                {errors.genderId && (
                                    <div className="invalid-feedback">
                                        {errors.genderId.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="languageId"
                                    className="fw-bold form-label small"
                                >
                                    Home Language *
                                </label>

                                <select
                                    name="languageId"
                                    id="languageId"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Home Language is required.',
                                        },
                                    })}
                                >
                                    <option value="" disabled>
                                        Choose
                                    </option>
                                    {languageList &&
                                        languageList.map(function (
                                            item,
                                            index
                                        ) {
                                            return (
                                                <option
                                                    key={index}
                                                    value={item.language_id}
                                                >
                                                    {item.language_name}
                                                </option>
                                            );
                                        })}
                                </select>

                                {errors.languageId && (
                                    <div className="invalid-feedback">
                                        {errors.languageId.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="homeAddress"
                                    className="fw-bold form-label small"
                                >
                                    Home Address *
                                </label>

                                <input
                                    type="text"
                                    name="homeAddress"
                                    id="homeAddress"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Home Address is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Home Address must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Home Address must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.homeAddress && (
                                    <div className="invalid-feedback">
                                        {errors.homeAddress.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="homeSuburb"
                                    className="fw-bold form-label small"
                                >
                                    Home Suburb *
                                </label>

                                <input
                                    type="text"
                                    name="homeSuburb"
                                    id="homeSuburb"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: 'Home Suburb is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Home Suburb must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Home Suburb must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.homeSuburb && (
                                    <div className="invalid-feedback">
                                        {errors.homeSuburb.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="homeCity"
                                    className="fw-bold form-label small"
                                >
                                    Home City *
                                </label>

                                <input
                                    type="text"
                                    name="homeCity"
                                    id="homeCity"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: 'Home City is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Home City must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Home City must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.homeCity && (
                                    <div className="invalid-feedback">
                                        {errors.homeCity.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="homeProvinceId"
                                    className="fw-bold form-label small"
                                >
                                    Home Province *
                                </label>

                                <select
                                    name="homeProvinceId"
                                    id="homeProvinceId"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Home Province is required.',
                                        },
                                    })}
                                >
                                    <option value="" disabled>
                                        Choose
                                    </option>
                                    {provinceList &&
                                        provinceList.map(function (
                                            item,
                                            index
                                        ) {
                                            return (
                                                <option
                                                    key={index}
                                                    value={item.province_id}
                                                >
                                                    {item.province_name}
                                                </option>
                                            );
                                        })}
                                </select>

                                {errors.homeProvinceId && (
                                    <div className="invalid-feedback">
                                        {errors.homeProvinceId.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <div className="form-check">
                                    <label
                                        htmlFor="confirmAge"
                                        className="form-check-label"
                                    >
                                        <input
                                            type="checkbox"
                                            name="confirmAge"
                                            id="confirmAge"
                                            className="form-check-input"
                                            value={true}
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message:
                                                        'Please confirm that you are over 18 years old.',
                                                },
                                            })}
                                        />
                                        <span className="fw-bold">Yes</span> - I
                                        confirm that I am over 18 years old. *
                                    </label>
                                </div>

                                {errors.confirmAge && (
                                    <div className="invalid-feedback">
                                        {errors.confirmAge.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <div className="form-check">
                                    <label
                                        htmlFor="optUnileverEmail"
                                        className="form-check-label"
                                    >
                                        <input
                                            type="checkbox"
                                            name="optUnileverEmail"
                                            id="optUnileverEmail"
                                            className="form-check-input"
                                            value={true}
                                            ref={register}
                                        />
                                        <span className="fw-bold">Yes</span>, I
                                        want to receive offers and updates from{' '}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.unilever.co.za/brands/"
                                        >
                                            Unilever Brands
                                        </a>{' '}
                                        via email and online advertising
                                        tailored to my interest and preferences.
                                    </label>
                                </div>
                            </div>

                            <div className="mb-4">
                                <div className="form-check">
                                    <label
                                        htmlFor="optUnileverSms"
                                        className="form-check-label"
                                    >
                                        <input
                                            type="checkbox"
                                            name="optUnileverSms"
                                            id="optUnileverSms"
                                            className="form-check-input"
                                            value={true}
                                            ref={register}
                                        />
                                        <span className="fw-bold">Yes</span>, I
                                        want to receive offers and updates from{' '}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.unilever.co.za/brands/"
                                        >
                                            Unilever Brands
                                        </a>{' '}
                                        via SMS and online advertising tailored
                                        to my interest and preferences.
                                    </label>
                                </div>
                            </div>

                            <div className="mb-4">
                                <div className="form-check">
                                    <label
                                        htmlFor="confirmTerms"
                                        className="form-check-label"
                                    >
                                        <input
                                            type="checkbox"
                                            name="confirmTerms"
                                            id="confirmTerms"
                                            className="form-check-input"
                                            value={true}
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message:
                                                        'Please confirm that you accept the Terms of Use.',
                                                },
                                            })}
                                        />
                                        <span className="fw-bold">Yes</span> - I
                                        accept the{' '}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={UNILEVER_LEGAL}
                                        >
                                            Terms of Use
                                        </a>
                                        . *
                                    </label>
                                </div>

                                {errors.confirmAge && (
                                    <div className="invalid-feedback">
                                        {errors.confirmAge.message}
                                    </div>
                                )}
                            </div>

                            <ResponseMessage
                                message={responseMessage}
                                status={responseStatus}
                            />

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Update Profile"
                                    className="btn btn-wide btn-primary"
                                />
                                <a
                                    href={PORTAL_DASHBOARD}
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
