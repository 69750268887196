import React from 'react';

// Icons
import NoIcon from '../../../components/Icons/NoIcon';
import YesIcon from '../../../components/Icons/YesIcon';

const PrintAd = (props) => {
    const { print_ad_type_id, print_ad_type_name, print_ad_type_is_active } =
        props.data;

    return (
        <tr key={print_ad_type_id}>
            <td className="text-center align-middle">
                <a href={'/cms/printadtypes/' + print_ad_type_id + '/edit'}>
                    <i className="fa-fw fas fa-edit"></i>
                </a>
            </td>
            <td className="text-center align-middle">{print_ad_type_name}</td>
            <td className="text-center align-middle">
                {print_ad_type_is_active === '1' ? <YesIcon /> : <NoIcon />}
            </td>
        </tr>
    );
};

export default PrintAd;
