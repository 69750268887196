// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import Pagination from '../../../helpers/Pagination';
import Stokvels from './Stokvels';
import Navbar from '../../../components/Navbar/AdminNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { CMS_DASHBOARD } from '../../../routes';

export default function StokvelIndex() {
    // Redux
    const dispatch = useDispatch();
    const stokvelList = useSelector((state) => state.stokvelList);

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Calculate Total Members
    function overallTotalMembers(items) {
        let overallTotalMembersValue = 0;
        for (let i = 0; i < items.length; i++) {
            overallTotalMembersValue += parseInt(
                items[i].stokvel_membership_count
            );
        }
        return overallTotalMembersValue;
    }

    // Calculate Total Till Slips
    function overallTillSlipCount(items) {
        let overallTotalTillSlipCount = 0;
        for (let i = 0; i < items.length; i++) {
            overallTotalTillSlipCount += parseInt(items[i].till_slips.length);
        }
        return overallTotalTillSlipCount;
    }

    // Calculate Total Till Slip Value
    function overallTillSlipValue(items) {
        let overallTotalTillSlipValue = 0.0;
        for (let i = 0; i < items.length; i++) {
            for (let i2 = 0; i2 < items[i].till_slips.length; i2++) {
                overallTotalTillSlipValue += parseFloat(
                    items[i].till_slips[i2].tillslip_total_amount
                );
            }
        }
        return overallTotalTillSlipValue;
    }

    // Component Did Mount
    useEffect(() => {
        Api.get('/cms')
            .then(function (response) {
                if (response.data.success) {
                    dispatch({
                        type: 'SET_STOKVEL_LIST',
                        payload: response.data.data.stokvelList,
                    });
                } else {
                    setResponseMessage(response.data.message);
                    setResponseStatus('error');
                }
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage(
                        'An error occurred while retrieving your data.'
                    );
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Stokvels | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="stokvels-index" className="container mb-5">
                <div className="row">
                    <div className="col-12 text-center">
                        <Title title="Stokvels" />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />
                    </div>
                </div>

                {stokvelList && Object.keys(stokvelList).length > 0 ? (
                    <>
                        <div className="row border p-3 mb-5">
                            <div className="col-12 col-lg-6 fw-bold mb-2 text-center text-lg-start">
                                Total Stokvels:
                            </div>

                            <div className="col-12 col-lg-6 fw-bold mb-2 text-center text-lg-end">
                                {stokvelList ? parseInt(stokvelList.length) : 0}
                            </div>

                            <div className="col-12 col-lg-6 fw-bold mb-2 text-center text-lg-start">
                                Total Members:
                            </div>

                            <div className="col-12 col-lg-6 fw-bold mb-2 text-center text-lg-end">
                                {stokvelList
                                    ? parseInt(overallTotalMembers(stokvelList))
                                    : 0}
                            </div>

                            <div className="col-12 col-lg-6 fw-bold mb-2 text-center text-lg-start">
                                Total Till Slips:
                            </div>

                            <div className="col-12 col-lg-6 fw-bold mb-2 text-center text-lg-end">
                                {stokvelList
                                    ? parseInt(
                                          overallTillSlipCount(stokvelList)
                                      )
                                    : 0}
                            </div>

                            <div className="col-12 col-lg-6 fw-bold mb-2 mb-lg-0 text-center text-lg-start">
                                Total Value:
                            </div>

                            <div className="col-12 col-lg-6 fw-bold text-center text-lg-end">
                                R{' '}
                                {stokvelList
                                    ? parseFloat(
                                          overallTillSlipValue(stokvelList)
                                      ).toFixed(2)
                                    : '0.00'}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table
                                        id="table-stokvels"
                                        className="mb-0 table table-bordered table-striped table-sm"
                                    >
                                        <thead className="table-light">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    &nbsp;
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Stokvel Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Members
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Till Slips
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <Pagination
                                                data={stokvelList}
                                                RenderComponent={Stokvels}
                                                pageLimit={5}
                                                dataLimit={10}
                                            />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <p className="mb-0 text-center">No records were found.</p>
                )}

                <div className="row mt-5">
                    <div className="col-12 col-lg-8 offset-lg-2 text-start">
                        <p>
                            <a href={CMS_DASHBOARD}>
                                Admin Dashboard{' '}
                                <i className="fas fa-arrow-right float-end"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}
