import React, { useState } from 'react';

const Pagination = ({ data, RenderComponent, pageLimit, dataLimit }) => {
    const [pages] = useState(
        Math.round(data.length / dataLimit) < 1
            ? 1
            : Math.ceil(data.length / dataLimit)
    );
    const [currentPage, setCurrentPage] = useState(1);

    function goToNextPage() {
        setCurrentPage((page) => page + 1);
    }

    function goToPreviousPage() {
        setCurrentPage((page) => page - 1);
    }

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    }

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return data.slice(startIndex, endIndex);
    };

    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
    };

    return (
        <>
            {/* show the entries, 10 enties at a time */}
            {getPaginatedData().map((d, idx) => (
                <RenderComponent key={idx} data={d} />
            ))}

            {/* show the pagiantion
         it consists of next and previous buttons
         along with page numbers, in our case, 5 page
         numbers at a time
      */}
            <tr className="pagination button-pagination-section">
                {/* previous button */}
                <td className="tillslips-button">
                    <button
                        onClick={goToPreviousPage}
                        className={`btn btn-primary me-1 prev`}
                        disabled={currentPage === 1 ? true : false}
                    >
                        prev
                    </button>

                    {/* show page numbers */}
                    {getPaginationGroup().map((item, index) => (
                        <button
                            key={index}
                            onClick={changePage}
                            className={`btn btn-primary me-1 pagination-ttem ${
                                currentPage === item ? 'active' : null
                            }`}
                            style={{
                                display: item > pages ? 'none' : 'inline-block',
                            }}
                        >
                            <span>{item}</span>
                        </button>
                    ))}

                    {/* next button */}
                    <button
                        onClick={goToNextPage}
                        className={`btn btn-primary next`}
                        disabled={currentPage === pages ? true : false}
                    >
                        next
                    </button>
                </td>
            </tr>
        </>
    );
};

export default Pagination;
