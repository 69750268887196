// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import Hero from '../../../../components/Hero';
import Navbar from '../../../../components/Navbar';
import Testimonial from './Testimonial';

// Images
import HeroDesktop from '../../../../assets/img/page-banners/banner-testimonials-desktop.png';
import HeroMobile from '../../../../assets/img/page-banners/banner-testimonials-mobile.png';

// Helpers
import Api from '../../../../helpers/Api';

export default function TestimonialsPage() {
    // Local state
    const [screenWidth, setScreenWidth] = useState(null);
    const [showHidden, setShowHidden] = useState(false);
    const [showHiddenClass, setShowHiddenClass] = useState(null);
    const [showHiddenClassWithBg, setShowHiddenClassWithBg] = useState(null);
    const [viewAllClass, setViewAllClass] = useState(null);
    const [activeTestimonialsArray, setActiveTestimonialList] = useState([]);

    // Redux
    const dispatch = useDispatch();

    //create arrays with 3 elements each in them for the bootstrap div rows
    let rows = activeTestimonialsArray
        .map((x, i) => {
            return i % 3 === 0 ? activeTestimonialsArray.slice(i, i + 3) : null;
        })
        .filter((x) => x != null);

    //create an array for the first row. this is especially for the mobile view.
    const removedRows = rows.splice(0, 1);

    // Component Did Mount
    useEffect(() => {
        Api.get('/active-testimonials').then(function (response) {
            if (response.data.success) {
                dispatch({
                    type: 'SET_TESTIMONIALS_ACTIVE_LIST',
                    payload: response.data.data.activeTestimonials,
                });
                setActiveTestimonialList(response.data.data.activeTestimonials);
            }
        });
        // eslint-disable-next-line
    }, []);

    function viewAll() {
        setShowHidden(true);
    }

    useEffect(() => {
        function updateWindowDimensions() {
            setScreenWidth(window.innerWidth);
            if (screenWidth > 960) {
                setShowHidden(true);
            } else {
                setShowHidden(false);
            }
        }

        if (showHidden) {
            setShowHiddenClass('bg-grey-light d-block py-5');
            setShowHiddenClassWithBg('bg-white d-block py-5');
            setViewAllClass('bg-white d-none py-5');
        } else {
            setShowHiddenClass('bg-grey-light d-none d-lg-block py-5');
            setShowHiddenClassWithBg('bg-white d-none d-lg-block py-5');
            setViewAllClass('bg-white d-lg-none py-5');
        }

        window.addEventListener('resize', updateWindowDimensions);

        return (_) => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, [screenWidth, showHidden]);

    return (
        <>
            <Helmet>
                <title>Testimonials | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <section id="testimonials">
                <Hero
                    title="Testimonials"
                    heroMobile={HeroMobile}
                    heroDesktop={HeroDesktop}
                />

                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 my-5  text-center">
                                <h1 className="mb-3">
                                    See what some of our Stokvel ladies have to
                                    say
                                </h1>

                                <p>
                                    During these unexpected and difficult times
                                    that COVID19 has brought along, we asked our
                                    communities to register their stokvels to
                                    stand a chance to win R2500.
                                </p>

                                <p className="mb-0">
                                    The response was overwhelming with many
                                    registrations. Below are some of the kind
                                    words our winners shared with us.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {removedRows &&
                    Object.keys(removedRows).length > 0 &&
                    removedRows.map(function (item) {
                        return (
                            <div
                                key={
                                    Date.now().toString(36) +
                                    Math.random().toString(36).substring(2)
                                }
                                className={`bg-grey-light d-block py-5`}
                            >
                                <div className="container">
                                    <div className="row testimonial-row">
                                        {item.map((col) => (
                                            <Testimonial
                                                col={col}
                                                key={col.testimonial_id}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                {rows &&
                    Object.keys(rows).length > 0 &&
                    rows.map(function (item, index) {
                        return (
                            <div
                                key={
                                    Date.now().toString(36) +
                                    Math.random().toString(36).substring(2)
                                }
                                className={`${
                                    index % 2 === 0
                                        ? showHiddenClassWithBg
                                        : showHiddenClass
                                }`}
                            >
                                <div className="container">
                                    <div className="row testimonial-row">
                                        {item.map((col) => (
                                            <Testimonial
                                                col={col}
                                                key={col.testimonial_id}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                <div className={viewAllClass}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <button
                                    className="btn btn-wide btn-primary"
                                    onClick={viewAll}
                                >
                                    View More
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
