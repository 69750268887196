// Packages
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import MyStoreLogo from '../../../../components/Logos/MyStoreLogo';
import Magazines from '../../../../components/MyStore/Magazines';
import Navbar from '../../../../components/Navbar';

// Images
import MyStoreHeroImage from '../../../../assets/img/mystore/mystore-banner.jpg';
import Article1Image from '../../../../assets/img/mystore/mystore-article-001.png';
import Article2Image from '../../../../assets/img/mystore/mystore-article-002.png';

// Routes
import {
    MYSTORE_ARTICLE1,
    MYSTORE_ARTICLE2,
    MYSTORE_CREATE,
} from '../../../../routes';

export default function MyStorePage() {
    return (
        <>
            <Helmet>
                <title>MyStore | Unilever Shopper</title>
                <body className="trader-app" />
            </Helmet>

            <Navbar />

            <MyStoreLogo />

            <section id="mystore" className="bg-orange-medium">
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-12 col-lg-8 offset-lg-2 text-center">
                            <a href="/mystore">
                                <img
                                    src={MyStoreHeroImage}
                                    alt="Spaza Shop"
                                    className="img-fluid mb-5"
                                />
                            </a>

                            <h1 className="mb-3 text-white">About</h1>

                            <p className="text-white">
                                We aim to engage with small business owners,
                                help better your business and be in the know
                                about the Unilever products your customers are
                                looking for.
                            </p>

                            <p className="mb-0 text-white">
                                A place to learn from each other and grow your
                                business from strength to strength.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-orange-dark">
                    <div className="container pt-5">
                        <div className="row">
                            <div className="col-12 col-lg-8 offset-lg-2">
                                <h2 className="h1 mb-3 text-center text-white">
                                    All Articles
                                </h2>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-lg-4 offset-lg-2 mb-5">
                                <div className="text-center">
                                    <img
                                        src={Article1Image}
                                        alt="Turn your customers into fans"
                                        className="d-inline-block img-fluid mb-3"
                                    />
                                </div>
                                <div className="text-white">
                                    <h3 className="h6">
                                        Turn your customers into fans
                                    </h3>
                                    <p className="mb-0">
                                        Customers vs fans: what’s the
                                        difference? It is a simple concept, a
                                        customer may come in your store once or
                                        once in a while. A fan is a returning
                                        customer that continues to support you
                                        and your business&#8230;
                                    </p>
                                </div>
                                <div className="mt-2">
                                    <a
                                        href={MYSTORE_ARTICLE1}
                                        className="small readmore text-white"
                                    >
                                        Read more
                                    </a>
                                </div>
                            </div>

                            <div className="col-12 col-lg-4 mb-5">
                                <div className="text-center">
                                    <img
                                        src={Article2Image}
                                        alt="COVID-19 and your business"
                                        className="d-inline-block img-fluid mb-3"
                                    />
                                </div>
                                <div className="text-white">
                                    <h3 className="h6">
                                        COVID-19 and your business
                                    </h3>
                                    <p className="mb-0">
                                        Since the first case of the Covid-19
                                        virus was confirmed in early March, the
                                        country began its national lockdown on
                                        the 27th March 2020. This caused all
                                        non-essential workers and businesses to
                                        close&#8230;
                                    </p>
                                </div>
                                <div className="mt-2">
                                    <a
                                        href={MYSTORE_ARTICLE2}
                                        className="small readmore text-white"
                                    >
                                        Read more
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container my-5">
                    <Magazines />

                    <div className="row mt-5">
                        <div className="col-12 text-center">
                            <a
                                href={MYSTORE_CREATE}
                                className="btn btn-wide btn-primary"
                            >
                                Get in touch
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
