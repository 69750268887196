import React from 'react';

const Testimonial = (props) => {
    const {
        testimonial_group_name,
        testimonial_group_type,
        testimonial_quote,
    } = props.item;

    return (
        <div className="bg-white text-black p-5 h-100 text-center">
            <div className="testimonial-title-container">
                <div className="testimonial-title">
                    <h2 className="h4">{testimonial_group_name}</h2>
                </div>

                <div className="testimonial-type">
                    <h3 className="h6 text-blue-dark">
                        {testimonial_group_type}
                    </h3>
                </div>
            </div>

            <div className="testimonial-quote pt-3">
                <p className="mb-0 text-start">"{testimonial_quote}"</p>
            </div>
        </div>
    );
};
export default Testimonial;
