import React from 'react';

const Stokvels = (props) => {
    const {
        index,
        stokvel_id,
        stokvel_name,
        stokvel_membership_count,
        till_slips,
    } = props.data;

    // Calculate each Stokvel Till Slip Value
    function eachStokvelTillSlipValue(items) {
        let totalValue = 0.0;
        for (let i = 0; i < items.length; i++) {
            totalValue += parseFloat(items[i].tillslip_total_amount);
        }
        return totalValue;
    }

    return (
        <tr key={index}>
            <td className="text-center align-middle">
                <a href={'stokvels/' + stokvel_id + '/edit'}>
                    <i className="fa-fw fas fa-edit"></i>
                </a>
            </td>
            <td className="text-center align-middle">
                <a href={'stokvels/' + stokvel_id + '/edit'}>{stokvel_name}</a>
            </td>
            <td className="text-center align-middle">
                {stokvel_membership_count}
            </td>
            <td className="text-center align-middle">{till_slips.length}</td>
            <td className="text-center align-middle">
                R{' '}
                {till_slips.length > 0
                    ? parseFloat(eachStokvelTillSlipValue(till_slips)).toFixed(
                          2
                      )
                    : 0.0}
            </td>
        </tr>
    );
};

export default Stokvels;
