// Packages
import React from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';

// Components
import Hero from '../../../../components/Hero';
import Navbar from '../../../../components/Navbar';

// Images
import HeroDesktop from '../../../../assets/img/page-banners/banner-product-highlights-desktop.png';
import HeroMobile from '../../../../assets/img/page-banners/banner-product-highlights-mobile.png';
import GermHeroImage from '../../../../assets/img/product-highlights/germ-hero-image.png';
import SunlightImage from '../../../../assets/img/product-highlights/sunlight.png';
import DomestosImage from '../../../../assets/img/product-highlights/domestos.png';
import HandyAndyImage from '../../../../assets/img/product-highlights/handy-andy.png';
import LifebouyHeroImage from '../../../../assets/img/product-highlights/lifebouy-hero-image.png';
import LifebouyHandwashImage from '../../../../assets/img/product-highlights/lifebouy-handwash.png';
import LifebouySoapImage from '../../../../assets/img/product-highlights/lifebouy-soap.png';
import LifebouyBodywashImage from '../../../../assets/img/product-highlights/lifebouy-bodywash.png';
import KnorroxHeroImage from '../../../../assets/img/product-highlights/knorrox-hero-image.png';
import KnorroxSoyaMinceImage from '../../../../assets/img/product-highlights/knorrox-soya-mince.png';
import VaselineHeroImage from '../../../../assets/img/product-highlights/vaseline-hero-image.png';
import VaselineAdvancedRepairImage from '../../../../assets/img/product-highlights/vaseline-advanced-repair.png';
import VaselineAgingSkinRescueImage from '../../../../assets/img/product-highlights/vaseline-aging-skin-rescue.png';
import VaselineAloeImage from '../../../../assets/img/product-highlights/vaseline-aloe.png';
import VaselineAtopiRescueImage from '../../../../assets/img/product-highlights/vaseline-atopi-rescue.png';
import VaselineCocoaGlowImage from '../../../../assets/img/product-highlights/vaseline-cocoa-glow.png';
import VaselineCrackedHeelRescueImage from '../../../../assets/img/product-highlights/vaseline-cracked-heel-rescue.png';
import VaselineDarkSpotRescueImage from '../../../../assets/img/product-highlights/vaseline-dark-spot-rescue.png';
import VaselineDrySkinImage from '../../../../assets/img/product-highlights/vaseline-dryskin.png';
import VaselineExtremelyDrySkinImage from '../../../../assets/img/product-highlights/vaseline-extremely-dry-skin.png';
import VaselineFirmingImage from '../../../../assets/img/product-highlights/vaseline-firming.png';
import VaselineHandAndNailImage from '../../../../assets/img/product-highlights/vaseline-hand-and-nail.png';
import VaselineLipTherapyImage from '../../../../assets/img/product-highlights/vaseline-lip-therapy.png';
import VaselineMenCoolingImage from '../../../../assets/img/product-highlights/vaseline-men-cooling.png';
import VaselineMenEvenToneImage from '../../../../assets/img/product-highlights/vaseline-men-even-tone.png';
import VaselineMenExtraHydrationImage from '../../../../assets/img/product-highlights/vaseline-men-extra-hydration.png';
import VaselineMenFastAbsorbingImage from '../../../../assets/img/product-highlights/vaseline-men-fast-absorbing.png';
import VaselineOrangeEvenToneImage from '../../../../assets/img/product-highlights/vaseline-orange-even-tone.png';
import VaselinePetroleumJellyImage from '../../../../assets/img/product-highlights/vaseline-petroleum-jelly.png';

// Slider
const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    lazyLoad: true,
    infinite: false,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

export default function ProductHighlightsPage() {
    return (
        <>
            <Helmet>
                <title>Product Highlights | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <section id="product-highlights">
                <Hero
                    title="Product Highlights"
                    heroMobile={HeroMobile}
                    heroDesktop={HeroDesktop}
                />

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center py-5">
                            <h1 className="mb-3">Product Highlights</h1>

                            <img
                                src={GermHeroImage}
                                alt="Germ Heros"
                                className="img-fluid mb-5"
                            />

                            <h2>Cleaning supplies to help keep germs at bay</h2>

                            <p>
                                Keeping your home hygienically clean isn’t as
                                difficult or as expensive as you think! With the
                                right supplies, it’s easy to keep your home
                                germ-free and smelling fresh!
                            </p>

                            <p className="mb-5">
                                With our must-have cleaning supplies you can
                                easily disinfect and kill harmful bacteria and
                                disease-causing organisms in your home!
                            </p>

                            <Slider {...settings}>
                                <div className="slide">
                                    <div className="h-100 text-dark-dark text-center">
                                        <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                            <img
                                                className="img-fluid"
                                                src={SunlightImage}
                                                alt="Sunlight Anti-Bacterial Dishwashing Liquid"
                                            />
                                        </div>

                                        <p className="mt-3 mb-1 small fw-bold">
                                            Sunlight Anti-Bacterial Dishwashing
                                            Liquid
                                        </p>

                                        <p className="fw-light mb-0 small text-start">
                                            Cuts through grease fast and kills
                                            99.9% of Germs. Sunlight Dishwashing
                                            Liquid is great in removing grease
                                            from your crockery, utensils,
                                            stainless-steel items and even
                                            plastic.
                                        </p>
                                    </div>
                                </div>

                                <div className="slide">
                                    <div className="h-100 text-dark-dark text-center">
                                        <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                            <img
                                                className="img-fluid"
                                                src={DomestosImage}
                                                alt="Domestos Extended Power Thick Bleach"
                                            />
                                        </div>

                                        <p className="mt-3 mb-1 small fw-bold">
                                            Domestos Extended Power Thick Bleach
                                        </p>

                                        <p className="fw-light mb-0 small text-start">
                                            Cleans a variety of surfaces
                                            throughout the home. Thick formula
                                            clings to the inside of your toilet
                                            bowl, killing germs flush after
                                            flush.
                                        </p>
                                    </div>
                                </div>

                                <div className="slide">
                                    <div className="h-100 text-dark-dark text-center">
                                        <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                            <img
                                                className="img-fluid"
                                                src={HandyAndyImage}
                                                alt="Handy Andy All in One Floor Cleaner"
                                            />
                                        </div>

                                        <p className="mt-3 mb-1 small fw-bold">
                                            Handy Andy All in One Floor Cleaner
                                        </p>

                                        <p className="fw-light mb-0 small text-start">
                                            Great for streak free, germ free,
                                            shiny floors!
                                        </p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="bg-grey-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center py-5">
                                <img
                                    src={LifebouyHeroImage}
                                    alt=""
                                    className="d-inline-block img-fluid mb-5"
                                />

                                <h2>Stop the spread of germs</h2>

                                <p>
                                    Cell phones, keyboards, surfaces. Everything
                                    we touch can spread germs!
                                </p>

                                <p className="mb-5">
                                    Stay protected and combat the spread of
                                    potentially dangerous germs by diligently
                                    washing and sanitising your hands and body
                                    with Lifebuoy!
                                </p>

                                <Slider {...settings}>
                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={LifebouyHandwashImage}
                                                    alt="Lifebuoy Total 10 Hand Wash"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Lifebuoy Total 10 Hand Wash
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Lifebuoy Total Hand wash, with
                                                advanced germ protection
                                                ingredient, Activ5, gives you
                                                99.9% germ protection in just 10
                                                seconds.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={LifebouySoapImage}
                                                    alt="Lifebuoy Total 10 Bar Soap"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Lifebuoy Total 10 Bar Soap
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Lifebuoy Bar Soap Total helps
                                                protect from 10
                                                infection-causing germs.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={LifebouyBodywashImage}
                                                    alt="Lifebuoy Total 10 Body Wash 750ml"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Lifebuoy Total 10 Body Wash
                                                750ml
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Lifebuoy Active Silver Formula
                                                Hygiene Body Wash helps keep you
                                                and your family hygienically
                                                clean and fresh all day by
                                                targeting 10 skin
                                                problem-causing germs.
                                            </p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center py-5">
                            <img
                                src={KnorroxHeroImage}
                                alt=""
                                className="d-inline-block img-fluid mb-5"
                            />

                            <h2>
                                Taste The Meatiness &amp; Eat Well For Less With
                                Knorrox
                            </h2>

                            <p>Get nutritious meals on the table fast!</p>

                            <p className="mb-5">
                                Knorrox's wide range of cubes, soups and soya
                                mince are a great way to add delicious meatiness
                                to any meal.
                            </p>

                            <Slider {...settings}>
                                <div className="slide">
                                    <div className="h-100 text-dark-dark text-center">
                                        <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                            <img
                                                className="img-fluid"
                                                src={KnorroxSoyaMinceImage}
                                                alt="Knorrox Savoury Soya Mince 200g"
                                            />
                                        </div>

                                        <p className="mt-3 mb-1 small fw-bold">
                                            Knorrox Savoury Soya Mince 200g
                                        </p>

                                        <p className="fw-light mb-0 small text-start">
                                            Add meaty taste and make meals go
                                            further with Knorrox Savoury Mince
                                            Soya Mince!
                                        </p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="bg-grey-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center py-5">
                                <img
                                    src={VaselineHeroImage}
                                    alt=""
                                    className="d-inline-block img-fluid mb-5"
                                />

                                <h2>Your skin health matters</h2>

                                <p>
                                    Vaseline&reg; believes that truly healthy
                                    skin starts with deep healing moisture.
                                </p>

                                <p className="mb-5">
                                    With our experts and advanced Vaseline®
                                    healing products, we can help you rescue
                                    your skin.
                                </p>

                                <Slider {...settings}>
                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineAdvancedRepairImage
                                                    }
                                                    alt="Vaseline&reg; Intensive Care&trade; Advanced Repair Unscented Lotions"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; Intensive
                                                Care&trade; Advanced Repair
                                                Unscented Lotion
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Clinically proven to moisturise
                                                to heal very dry skin in 5 days.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineAgingSkinRescueImage
                                                    }
                                                    alt="Vaseline&reg; Clinical Care Ageing Skin Rescue Body Cream​"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; Clinical Care
                                                Ageing Skin Rescue Body Cream​
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Ageing Skin Rescue Body Lotion
                                                is made with intensive
                                                moisturisers and hyaluronic acid
                                                boosters, to heal intense
                                                dryness.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={VaselineAloeImage}
                                                    alt="Vaseline&reg; Intensive Care&trade; Aloe Soothe Lotion"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; Intensive
                                                Care&trade; Aloe Soothe Lotion
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Clinically proven to moisturise
                                                deeply to soothe dry skin with
                                                the first application. Heals and
                                                refreshes dry skin without
                                                feeling greasy.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineAtopiRescueImage
                                                    }
                                                    alt="Vaseline&reg; Clinical Care Atopi Rescue Body Lotion"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; Clinical Care
                                                Atopi Rescue Body Lotion
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Atopi Rescue Body Lotion with
                                                Colloidal Oatmeal Skin
                                                Protectant relieves itching and
                                                irritation due to eczema.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={VaselineCocoaGlowImage}
                                                    alt="Vaseline&reg; Intensive Care&trade; Cocoa Glow Lotion"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; Intensive
                                                Care&trade; Cocoa Glow Lotion
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Naturally Healthy, Naturally
                                                Glowing Skin.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineCrackedHeelRescueImage
                                                    }
                                                    alt="Vaseline&reg; Clinical Care Cracked Heel Rescue Foot Cream"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; Clinical Care
                                                Cracked Heel Rescue Foot Cream
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Cracked Heel Rescue Foot Cream
                                                delivers intense moisturisation
                                                to soothe dry, cracked heels.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineDarkSpotRescueImage
                                                    }
                                                    alt="Vaseline&reg; Clinical Care Hand Cream Dark Spot Rescue"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; Clinical Care Hand
                                                Cream Dark Spot Rescue
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Dark Spot Rescue Hand Cream,
                                                with intensive moisturisers and
                                                Vitamin B3, reduces appearance
                                                of dark spots in 4 weeks.​
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={VaselineDrySkinImage}
                                                    alt="Vaseline&reg; Intensive Care&trade; Dry Skin Repair Body Lotion"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; Intensive
                                                Care&trade; Dry Skin Repair Body
                                                Lotion
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Intensive Care&trade; Advanced
                                                Repair Lightly Fragranced Cream
                                                has been clinically proven to
                                                moisturise and restore very dry
                                                skin in just five days.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineExtremelyDrySkinImage
                                                    }
                                                    alt="Vaseline&reg; Clinical Care Extremely Dry Skin Rescue Hand Cream​"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; Clinical Care
                                                Extremely Dry Skin Rescue Hand
                                                Cream​
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Extremely Dry Skin Rescue Hand
                                                Cream with Barrier Restore
                                                Complex, provides immediate
                                                relief for dry hands.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={VaselineFirmingImage}
                                                    alt="Vaseline&reg; Intensive Care&trade; Firming Body Lotion"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; Intensive
                                                Care&trade; Firming Body Lotion​
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Intensive Care&trade; Advanced
                                                Repair Firming Lotion reveals
                                                smoother, firmer and more
                                                resilient skin.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineHandAndNailImage
                                                    }
                                                    alt="Vaseline&reg; Intensive Care&trade; Healthy Hands Stronger Nails Lotion"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; Intensive
                                                Care&trade; Healthy Hands
                                                Stronger Nails Lotion
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Skin moisturiser hand cream to
                                                heal very dry hands.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineLipTherapyImage
                                                    }
                                                    alt="Healthy looking lips"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Healthy looking lips
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Take long-lasting lip
                                                moisturisation with you wherever
                                                you go.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineMenCoolingImage
                                                    }
                                                    alt="Vaseline&reg; MEN Cooling Repairing Moisture Body Cream"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; MEN Cooling
                                                Repairing Moisture Body Cream
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Vaseline&reg; MEN Cooling Body
                                                Cream actively hydrates and
                                                cools the skin without leaving
                                                behind any greasy residue.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineMenEvenToneImage
                                                    }
                                                    alt="Vaseline&reg; MEN Even Tone Repairing Moisture Body Lotion"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; MEN Even Tone
                                                Repairing Moisture Body Lotion
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Vaseline&reg; MEN Even Tone
                                                Lotion is a body lotion that
                                                contains Stratys-3&trade;, a
                                                breakthrough in moisturising
                                                technology.​
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineMenExtraHydrationImage
                                                    }
                                                    alt="Vaseline&reg; MEN Extra Strength Repairing Moisture Body Lotion"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; MEN Extra Strength
                                                Repairing Moisture Body Lotion
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Vaseline&reg; MEN Extra Strength
                                                Body Lotion is fast absorbing
                                                and non-greasy. This
                                                quick-acting body lotion
                                                instantly restores the driest
                                                skin, and is perfect to use
                                                during the winter months​.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineMenFastAbsorbingImage
                                                    }
                                                    alt="Vaseline&reg; MEN Fast Absorbing Repairing Moisture Body Cream"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; MEN Fast Absorbing
                                                Repairing Moisture Body Cream
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Vaseline&reg; MEN Fast Absorbing
                                                Body Cream Provides sufficient
                                                dry skin relief without leaving
                                                an oily residue behind. This
                                                handy skin moisturiser tackles
                                                dryness in just 15 seconds.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselineOrangeEvenToneImage
                                                    }
                                                    alt="Vaseline&reg; Intensive Care&trade; Healthy Even Tone Body Lotion"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                Vaseline&reg; Intensive
                                                Care&trade; Healthy Even Tone
                                                Body Lotion
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Healthy Even Tone Body Lotion
                                                helps match your uneven and
                                                discoloured areas to your body’s
                                                natural shade.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="slide">
                                        <div className="h-100 text-dark-dark text-center">
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        VaselinePetroleumJellyImage
                                                    }
                                                    alt="The original petroleum jelly, helping heal skin for generations"
                                                />
                                            </div>

                                            <p className="mt-3 mb-1 small fw-bold">
                                                The original petroleum jelly,
                                                helping heal skin for
                                                generations
                                            </p>

                                            <p className="fw-light mb-0 small text-start">
                                                Start all your skin routines
                                                with this gentle and
                                                hypoallergenic healing jelly to
                                                accelerate skin's natural
                                                healing process.
                                            </p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
