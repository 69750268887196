// Packages
import React from 'react';

// Routes
import { PORTAL_TILLSLIP_UPLOAD } from '../../routes';

export default function UploadTillSlipButton() {
    return (
        <div className="text-center mb-5">
            <a
                href={PORTAL_TILLSLIP_UPLOAD}
                className="btn btn-wide btn-primary"
            >
                Upload Till Slip
            </a>
        </div>
    );
}
