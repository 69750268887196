// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import Navbar from '../../../components/Navbar/AdminNavbar';
import Pagination from '../../../helpers/Pagination';
import PrintAd from './PrintAd';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { CMS_DASHBOARD, CMS_PRINTADTYPES_CREATE } from '../../../routes';

export default function CmsPrintAdTypesIndex() {
    // Redux
    const dispatch = useDispatch();
    const printAdsList = useSelector((state) => state.printAdTypeList);

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Component Did Mount
    useEffect(() => {
        Api.get('/all-printadtypes')
            .then(function (response) {
                if (response.data.success) {
                    dispatch({
                        type: 'SET_PRINTADTYPES_LIST',
                        payload: response.data.data.printAds,
                    });
                } else {
                    setResponseMessage(response.data.message);
                    setResponseStatus('error');
                }
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage(
                        'An error occurred while retrieving your data'
                    );
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Print Ad Types | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="tillslips-index" className="container mb-5">
                <div className="row">
                    <div className="col-12 text-center">
                        <Title title="Print Ad Types" />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />
                    </div>
                </div>

                <div className="text-center mb-5">
                    <a
                        href={CMS_PRINTADTYPES_CREATE}
                        className="btn btn-wide btn-primary"
                    >
                        Create Print Ad Type
                    </a>
                </div>

                {printAdsList && Object.keys(printAdsList).length > 0 ? (
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table
                                    id="table-tillslips"
                                    className="mb-0 table table-bordered table-striped table-sm"
                                >
                                    <thead className="table-light">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                &nbsp;
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Print Ad Type Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Is active
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <Pagination
                                            data={printAdsList}
                                            RenderComponent={PrintAd}
                                            pageLimit={5}
                                            dataLimit={10}
                                        />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p className="mb-0 text-center">No records were found.</p>
                )}

                <div className="row mt-5">
                    <div className="col-12 col-lg-8 offset-lg-2 text-start">
                        <p>
                            <a href={CMS_DASHBOARD}>
                                Admin Dashboard{' '}
                                <i className="fas fa-arrow-right float-end"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}
