// Packages
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/PortalUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { AUTH_LOGOUT, PORTAL_DASHBOARD } from '../../../routes';

export default function PortalUserUpdatePassword() {
    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // React Hook Form
    const { handleSubmit, register, reset, errors, getValues } = useForm({
        defaultValues: {
            password: '',
            newPassword: '',
        },
    });

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/users/update-password', {
                current_password: data.currentPassword,
                new_password: data.newPassword,
                new_password_confirmation: data.newPasswordConfirmation,
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');

                        reset();
                    }
                    return <Redirect to={AUTH_LOGOUT} />;
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Update Your Password | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="update-password" className="container mb-5">
                <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-3">
                        <Title title="Update Your Password" />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-5">
                                <label
                                    htmlFor="currentPassword"
                                    className="fw-bold form-label small"
                                >
                                    Current Password *
                                </label>

                                <input
                                    type="password"
                                    name="currentPassword"
                                    id="currentPassword"
                                    className="form-control"
                                    autoComplete="current-password"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Current Password is required.',
                                        },
                                        minLength: {
                                            value: 8,
                                            message:
                                                'Current Password must be between 8 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Current Password must be between 8 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.currentPassword && (
                                    <div className="invalid-feedback">
                                        {errors.currentPassword.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="new_password"
                                    className="fw-bold form-label small"
                                >
                                    New Password *
                                </label>

                                <input
                                    type="password"
                                    name="newPassword"
                                    id="newPassword"
                                    className="form-control"
                                    autoComplete="new-password"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'New Password is required.',
                                        },
                                        minLength: {
                                            value: 8,
                                            message:
                                                'New Password must be between 8 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'New Password must be between 8 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.newPassword && (
                                    <div className="invalid-feedback">
                                        {errors.newPassword.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-5">
                                <label
                                    htmlFor="newPasswordConfirmation"
                                    className="fw-bold form-label small"
                                >
                                    Confirm New Password *
                                </label>

                                <input
                                    type="password"
                                    name="newPasswordConfirmation"
                                    id="newPasswordConfirmation"
                                    className="form-control"
                                    autoComplete="new-password"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Confirm New Password is required.',
                                        },
                                        minLength: {
                                            value: 8,
                                            message:
                                                'Confirm New Password must be between 8 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Confirm New Password must be between 8 and 191 characters.',
                                        },
                                        validate: {
                                            newPasswordEqual: (value) =>
                                                value ===
                                                    getValues().newPassword ||
                                                'New Password and Confirm New Password must match.',
                                        },
                                    })}
                                />

                                {errors.newPasswordConfirmation && (
                                    <div className="invalid-feedback">
                                        {errors.newPasswordConfirmation.message}
                                    </div>
                                )}
                            </div>

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Update Password"
                                    className="btn btn-wide btn-primary"
                                />

                                <a
                                    href={PORTAL_DASHBOARD}
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
