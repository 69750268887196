// Packages
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Components
import TraderRegisterLayout from './TraderRegisterLayout';

const TraderRegisterStep3 = (props) => {
    // Redux
    const traderRegister = useSelector((state) => state.traderRegister);
    const provinceList = useSelector((state) => state.provinceList);
    const referralTypeList = useSelector((state) => state.referralTypeList);
    const storeAmountList = useSelector((state) => state.storeAmountList);
    const storeTypeList = useSelector((state) => state.storeTypeList);
    const dispatch = useDispatch();

    // React Hook Form
    const { handleSubmit, register, errors } = useForm();

    // Submit Form
    const onSubmit = (data) => {
        dispatch({
            type: 'SET_TRADER_REGISTER',
            payload: {
                ...traderRegister,
                traderName: data.traderName,
                traderAddress: data.traderAddress,
                traderSuburb: data.traderSuburb,
                traderCity: data.traderCity,
                traderProvinceId: data.traderProvinceId,
                traderStoreAmount: data.traderStoreAmount,
                traderStoreType: data.traderStoreType,
                referralTypeId: data.referralTypeId,
            },
        });

        props.history.push('/trader/register/4');
    };

    return (
        <>
            <TraderRegisterLayout
                stepName="Trader Details"
                stepNumber="3"
                stepProgress="w-75"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-4">
                        <label
                            htmlFor="traderName"
                            className="fw-bold form-label small "
                        >
                            Your Store's Name *
                        </label>

                        <input
                            type="text"
                            name="traderName"
                            id="traderName"
                            className="form-control"
                            placeholder="Awesome Trading"
                            defaultValue={traderRegister.traderName}
                            ref={register({
                                required: {
                                    value: true,
                                    message: "Your Store's Name is required.",
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        "Your Store's Name must be between 2 and 191 characters.",
                                },
                                maxLength: {
                                    value: 191,
                                    message:
                                        "Your Store's Name must be between 2 and 191 characters.",
                                },
                            })}
                        />

                        {errors.traderName && (
                            <div className="invalid-feedback">
                                {errors.traderName.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="traderStoreAmount"
                            className="fw-bold form-label small "
                        >
                            How many stores do you own? *
                        </label>

                        <select
                            name="traderStoreAmount"
                            id="traderStoreAmount"
                            className="form-control"
                            defaultValue={traderRegister.traderStoreAmount}
                            ref={register({
                                required: {
                                    value: true,
                                    message:
                                        'How many stores do you own is required.',
                                },
                            })}
                        >
                            <option value="" disabled>
                                Choose
                            </option>
                            {storeAmountList &&
                                storeAmountList.map(function (item, index) {
                                    return (
                                        <option
                                            key={index}
                                            value={item.store_amount_id}
                                        >
                                            {item.store_amount_name}
                                        </option>
                                    );
                                })}
                        </select>

                        {errors.traderStoreAmount && (
                            <div className="invalid-feedback">
                                {errors.traderStoreAmount.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="traderStoreType"
                            className="fw-bold form-label small "
                        >
                            Store type/size? *
                        </label>

                        <select
                            name="traderStoreType"
                            id="traderStoreType"
                            className="form-control"
                            defaultValue={traderRegister.traderStoreType}
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Store type/size is required.',
                                },
                            })}
                        >
                            <option value="" disabled>
                                Choose
                            </option>
                            {storeTypeList &&
                                storeTypeList.map(function (item, index) {
                                    return (
                                        <option
                                            key={index}
                                            value={item.store_type_id}
                                        >
                                            {item.store_type_name}
                                        </option>
                                    );
                                })}
                        </select>

                        {errors.traderStoreType && (
                            <div className="invalid-feedback">
                                {errors.traderStoreType.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="traderAddress"
                            className="fw-bold form-label small "
                        >
                            Your Store's Address *
                        </label>

                        <input
                            type="text"
                            name="traderAddress"
                            id="traderAddress"
                            className="form-control"
                            placeholder="15 Nollsworth Crescent"
                            defaultValue={traderRegister.traderAddress}
                            ref={register({
                                required: {
                                    value: true,
                                    message:
                                        "Your Store's Address is required.",
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        "Your Store's Address must be between 2 and 191 characters.",
                                },
                                maxLength: {
                                    value: 191,
                                    message:
                                        "Your Store's Address must be between 2 and 191 characters.",
                                },
                            })}
                        />

                        {errors.traderAddress && (
                            <div className="invalid-feedback">
                                {errors.traderAddress.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="traderSuburb"
                            className="fw-bold form-label small "
                        >
                            Your Store's Suburb *
                        </label>

                        <input
                            type="text"
                            name="traderSuburb"
                            id="traderSuburb"
                            className="form-control"
                            placeholder="Umhlanga"
                            defaultValue={traderRegister.traderSuburb}
                            ref={register({
                                required: {
                                    value: true,
                                    message: "Your Store's Suburb is required.",
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        "Your Store's Suburb must be between 2 and 191 characters.",
                                },
                                maxLength: {
                                    value: 191,
                                    message:
                                        "Your Store's Suburb must be between 2 and 191 characters.",
                                },
                            })}
                        />

                        {errors.traderSuburb && (
                            <div className="invalid-feedback">
                                {errors.traderSuburb.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="traderCity"
                            className="fw-bold form-label small "
                        >
                            Your Store's City *
                        </label>

                        <input
                            type="text"
                            name="traderCity"
                            id="traderCity"
                            className="form-control"
                            placeholder="Durban"
                            defaultValue={traderRegister.traderCity}
                            ref={register({
                                required: {
                                    value: true,
                                    message: "Your Store's City is required.",
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        "Your Store's City must be between 2 and 191 characters.",
                                },
                                maxLength: {
                                    value: 191,
                                    message:
                                        "Your Store's City must be between 2 and 191 characters.",
                                },
                            })}
                        />

                        {errors.traderCity && (
                            <div className="invalid-feedback">
                                {errors.traderCity.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="traderProvinceId"
                            className="fw-bold form-label small "
                        >
                            Your Store's Province *
                        </label>

                        <select
                            name="traderProvinceId"
                            id="traderProvinceId"
                            className="form-control"
                            defaultValue={traderRegister.traderProvinceId}
                            ref={register({
                                required: {
                                    value: true,
                                    message:
                                        "Your Store's Province is required.",
                                },
                            })}
                        >
                            <option value="" disabled>
                                Choose
                            </option>
                            {provinceList &&
                                provinceList.map(function (item, index) {
                                    return (
                                        <option
                                            key={index}
                                            value={item.province_id}
                                        >
                                            {item.province_name}
                                        </option>
                                    );
                                })}
                        </select>

                        {errors.traderProvinceId && (
                            <div className="invalid-feedback">
                                {errors.traderProvinceId.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="referralTypeId"
                            className="fw-bold form-label small "
                        >
                            Where did you hear about us? *
                        </label>

                        <select
                            name="referralTypeId"
                            id="referralTypeId"
                            className="form-control"
                            defaultValue={traderRegister.referralTypeId}
                            ref={register({
                                required: {
                                    value: true,
                                    message:
                                        'Where did you hear about us is required.',
                                },
                            })}
                        >
                            <option value="" disabled>
                                Choose
                            </option>
                            {referralTypeList &&
                                referralTypeList.map(function (item, index) {
                                    return item.referral_type_is_active_trader ===
                                        '1' ? (
                                        <option
                                            key={index}
                                            value={item.referral_type_id}
                                        >
                                            {item.referral_type_name}
                                        </option>
                                    ) : null;
                                })}
                        </select>

                        {errors.referralTypeId && (
                            <div className="invalid-feedback">
                                {errors.referralTypeId.message}
                            </div>
                        )}
                    </div>

                    <div className="text-center mt-100 mb-270">
                        <a
                            href="/trader/register/2"
                            className="btn btn-wide btn-secondary btn-lg text-uppercase me-2"
                        >
                            <i className="fas fa-angle-left"></i> Previous
                        </a>

                        <button
                            type="submit"
                            className="next btn btn-wide btn-primary btn-lg text-uppercase"
                        >
                            Next <i className="fas fa-angle-right"></i>
                        </button>
                    </div>
                </form>
            </TraderRegisterLayout>
        </>
    );
};

export default withRouter(TraderRegisterStep3);
