// Packages
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

export default function CmsProductCreate() {
    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // React Form Hook
    const { handleSubmit, register, errors } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            productName: '',
            productValue: '',
        },
    });

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/products/', {
                product_name: data.productName,
                product_value: data.productValue,
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Create Product | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="product-edit" className="container mb-5">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <Title title="Create Product" />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label
                                    htmlFor="productName"
                                    className="fw-bold form-label small"
                                >
                                    Product Name *
                                </label>

                                <input
                                    type="text"
                                    name="productName"
                                    id="productName"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Product Name is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Product Name must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Product Name must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.productName && (
                                    <div className="invalid-feedback">
                                        {errors.productName.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="productValue"
                                    className="fw-bold form-label small "
                                >
                                    Product Price *
                                </label>

                                <input
                                    type="text"
                                    name="productValue"
                                    id="productValue"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Product Price is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Product Price must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Product Price must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.productValue && (
                                    <div className="invalid-feedback">
                                        {errors.productValue.message}
                                    </div>
                                )}
                            </div>

                            <ResponseMessage
                                message={responseMessage}
                                status={responseStatus}
                            />

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Create Product"
                                    className="btn btn-wide btn-primary"
                                />

                                <a
                                    href="/cms/products/"
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
