// Packages
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Hero from '../../../components/Hero';
import Navbar from '../../../components/Navbar';
import ResponseMessage from '../../../components/ResponseMessage';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Images
import HeroDesktop from '../../../assets/img/page-banners/banner-trader-forgotten-password-desktop.png';
import HeroMobile from '../../../assets/img/page-banners/banner-trader-forgotten-password-mobile.png';

export default function TraderForgotPassword() {
    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // React Hook Form
    const { handleSubmit, register, reset, errors } = useForm({
        defaultValues: {
            mobileNumber: '',
        },
    });

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/password/email', {
                mobile: data.mobileNumber,
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');

                        reset();
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Reset your password | Unilever Shopper</title>
                <body className="trader-app" />
            </Helmet>

            <Navbar />

            <section id="forgot-password">
                <Hero
                    title="Reset your password"
                    heroMobile={HeroMobile}
                    heroDesktop={HeroDesktop}
                />

                <div className="container my-5">
                    <div className="row">
                        <div className="col-12 col-lg-6 offset-lg-3">
                            <Title title="Reset your password" />

                            <ResponseMessage
                                message={responseMessage}
                                status={responseStatus}
                            />

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-5">
                                    <label
                                        htmlFor="mobileNumber"
                                        className="fw-bold form-label small"
                                    >
                                        Mobile Number *
                                    </label>

                                    <input
                                        type="text"
                                        name="mobileNumber"
                                        id="mobileNumber"
                                        className="form-control"
                                        placeholder="0660714504"
                                        ref={register({
                                            required: {
                                                value: true,
                                                message:
                                                    'Mobile Number is required.',
                                            },
                                            minLength: {
                                                value: 10,
                                                message:
                                                    'Mobile Number must be 10 digits.',
                                            },
                                            maxLength: {
                                                value: 10,
                                                message:
                                                    'Mobile Number must be 10 digits.',
                                            },
                                            pattern: {
                                                value: /^([0]{1})([1-9]{1})([0-9]{8})$/g,
                                                message:
                                                    'Mobile Number is an invalid format.',
                                            },
                                        })}
                                    />

                                    {errors.mobileNumber && (
                                        <div className="invalid-feedback">
                                            {errors.mobileNumber.message}
                                        </div>
                                    )}
                                </div>

                                <div className="text-center">
                                    <input
                                        type="submit"
                                        value="Request new password"
                                        className="btn btn-wide btn-primary"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
