export function retrieveToken() {
    let accessToken = false;

    if (!localStorage.getItem('persist:root')) {
        return accessToken;
    }

    const jsonLocalStorage = localStorage.getItem('persist:root');
    const jsonObject = JSON.parse(jsonLocalStorage);

    if (jsonObject.accessToken) {
        accessToken = jsonObject.accessToken;
    }

    if (accessToken === 'false' || accessToken === false) {
        accessToken = false;
    } else {
        accessToken = accessToken.replace(/"/g, '');
    }

    return accessToken;
}
