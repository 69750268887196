// Packages
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Components
import StokvelRegisterLayout from './StokvelRegisterLayout';

const StokvelRegisterStep2 = (props) => {
    // Redux
    const stokvelRegister = useSelector((state) => state.stokvelRegister);
    const provinceList = useSelector((state) => state.provinceList);
    const dispatch = useDispatch();

    // React Hook Form
    const { handleSubmit, register, errors } = useForm();

    // Submit Form
    const onSubmit = (data) => {
        dispatch({
            type: 'SET_STOKVEL_REGISTER',
            payload: {
                ...stokvelRegister,
                homeAddress: data.homeAddress,
                homeSuburb: data.homeSuburb,
                homeCity: data.homeCity,
                homeProvinceId: data.homeProvinceId,
            },
        });

        props.history.push('/stokvel/register/3');
    };

    return (
        <>
            <StokvelRegisterLayout
                stepName="Physical Address"
                stepNumber="2"
                stepProgress="w-50"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-4">
                        <label
                            htmlFor="homeAddress"
                            className="fw-bold form-label small "
                        >
                            Home Address *
                        </label>

                        <input
                            type="text"
                            name="homeAddress"
                            id="homeAddress"
                            className="form-control"
                            placeholder="15 Nollsworth Crescent"
                            defaultValue={stokvelRegister.homeAddress}
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Home Address is required.',
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        'Home Address must be between 2 and 191 characters.',
                                },
                                maxLength: {
                                    value: 191,
                                    message:
                                        'Home Address must be between 2 and 191 characters.',
                                },
                            })}
                        />

                        {errors.homeAddress && (
                            <div className="invalid-feedback">
                                {errors.homeAddress.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="homeSuburb"
                            className="fw-bold form-label small "
                        >
                            Home Suburb *
                        </label>

                        <input
                            type="text"
                            name="homeSuburb"
                            id="homeSuburb"
                            className="form-control"
                            placeholder="Umhlanga"
                            defaultValue={stokvelRegister.homeSuburb}
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Home Suburb is required.',
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        'Home Suburb must be between 2 and 191 characters.',
                                },
                                maxLength: {
                                    value: 191,
                                    message:
                                        'Home Suburb must be between 2 and 191 characters.',
                                },
                            })}
                        />

                        {errors.homeSuburb && (
                            <div className="invalid-feedback">
                                {errors.homeSuburb.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="homeCity"
                            className="fw-bold form-label small "
                        >
                            Home City *
                        </label>

                        <input
                            type="text"
                            name="homeCity"
                            id="homeCity"
                            className="form-control"
                            placeholder="Durban"
                            defaultValue={stokvelRegister.homeCity}
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Home City is required.',
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        'Home City must be between 2 and 191 characters.',
                                },
                                maxLength: {
                                    value: 191,
                                    message:
                                        'Home City must be between 2 and 191 characters.',
                                },
                            })}
                        />

                        {errors.homeCity && (
                            <div className="invalid-feedback">
                                {errors.homeCity.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="homeProvinceId"
                            className="fw-bold form-label small "
                        >
                            Home Province *
                        </label>

                        <select
                            name="homeProvinceId"
                            id="homeProvinceId"
                            className="form-control"
                            defaultValue={stokvelRegister.homeProvinceId}
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Home Province is required.',
                                },
                            })}
                        >
                            <option value="" disabled>
                                Choose
                            </option>
                            {provinceList &&
                                provinceList.map(function (item, index) {
                                    return (
                                        <option
                                            key={index}
                                            value={item.province_id}
                                        >
                                            {item.province_name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    {errors.homeProvinceId && (
                        <div className="invalid-feedback">
                            {errors.homeProvinceId.message}
                        </div>
                    )}

                    <div className="text-center mt-100 mb-270">
                        <a
                            href="/stokvel/register"
                            className="btn btn-wide btn-secondary btn-lg text-uppercase me-2"
                        >
                            <i className="fas fa-angle-left"></i> Previous
                        </a>

                        <button
                            type="submit"
                            className="next btn btn-wide btn-primary btn-lg text-uppercase"
                        >
                            Next <i className="fas fa-angle-right"></i>
                        </button>
                    </div>
                </form>
            </StokvelRegisterLayout>
        </>
    );
};

export default withRouter(StokvelRegisterStep2);
