// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import Navbar from '../../../components/Navbar/AdminNavbar';
import Pagination from '../../../helpers/Pagination';
import Tillslip from './Tillslip';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { CMS_DASHBOARD } from '../../../routes';

export default function TillSlipIndex() {
    // Redux
    const dispatch = useDispatch();
    const tillSlipList = useSelector((state) => state.tillSlipList);

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Component Did Mount
    useEffect(() => {
        Api.get('/tillslips')
            .then(function (response) {
                if (response.data.success) {
                    dispatch({
                        type: 'SET_TILL_SLIP_LIST',
                        payload: response.data.data.tillslips,
                    });
                } else {
                    setResponseMessage(response.data.message);
                    setResponseStatus('error');
                }
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage(
                        'An error occurred while retrieving your data'
                    );
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Till Slips | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="tillslips-index" className="container mb-5">
                <div className="row">
                    <div className="col-12 text-center">
                        <Title title="Till Slips" />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />
                    </div>
                </div>

                {tillSlipList && Object.keys(tillSlipList).length > 0 ? (
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table
                                    id="table-tillslips"
                                    className="mb-0 table table-bordered table-striped table-sm"
                                >
                                    <thead className="table-light">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                &nbsp;
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Member
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Entity Type
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Entity Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Invoice
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Amount
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Processed?
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Approved?
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <Pagination
                                            data={tillSlipList}
                                            RenderComponent={Tillslip}
                                            pageLimit={5}
                                            dataLimit={10}
                                        />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p className="mb-0 text-center">No records were found.</p>
                )}

                <div className="row mt-5">
                    <div className="col-12 col-lg-8 offset-lg-2 text-start">
                        <p>
                            <a href={CMS_DASHBOARD}>
                                Admin Dashboard{' '}
                                <i className="fas fa-arrow-right float-end"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}
