// Packages
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/PortalUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { CMS_TRADER_INDEX } from '../../../routes';

export default function PortalTraderCreate() {
    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [provinces, setProvinces] = useState(null);
    const [traders, setTraders] = useState(null);

    // React Hook Form
    const { handleSubmit, register, reset, errors } = useForm({
        defaultValues: {
            traderName: '',
            traderAddress: '',
            traderSuburb: '',
            traderCity: '',
            traderProvinceId: '',
            traderPostalCode: '',
            traderRetailer: '',
        },
    });

    // Component Did Mount
    useEffect(() => {
        Api.get('/traders')
            .then(function (response) {
                setProvinces(response.data.data.provinces);
                setTraders(response.data.data.traders);
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage('Unable to retrieve the form data.');
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/traders/create', {
                trader_name: data.traderName,
                trader_address: data.traderAddress,
                trader_suburb: data.traderSuburb,
                trader_city: data.traderCity,
                trader_province_id: data.traderProvinceId,
                trader_postalcode: data.traderPostalCode,
                trader_retailer: data.traderRetailer,
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }
                    reset();
                })
                .catch(function (error) {
                    setResponseMessage(error.response.data.message);
                    setResponseStatus('error');
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Create Trader | Unilever Shopper</title>
                <body className="trader-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="trader-create" className="container pb-5">
                <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-3">
                        <Title title="Create your account" />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label
                                    htmlFor="traderName"
                                    className="fw-bold form-label small"
                                >
                                    Your Store's Name *
                                </label>

                                <input
                                    type="text"
                                    name="traderName"
                                    id="traderName"
                                    className="form-control"
                                    placeholder="Awesome Trading"
                                    list="traderList"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                "Your Store's Name is required.",
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                "Your Store's Name must be between 2 and 191 characters.",
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                "Your Store's Name must be between 2 and 191 characters.",
                                        },
                                    })}
                                />

                                <datalist id="traderList">
                                    {traders &&
                                        traders.map(function (item, index) {
                                            return (
                                                <option
                                                    key={index}
                                                    value={item.trader_name}
                                                >
                                                    {item.trader_name}
                                                </option>
                                            );
                                        })}
                                </datalist>

                                {errors.traderName && (
                                    <div className="invalid-feedback">
                                        {errors.traderName.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="traderAddress"
                                    className="fw-bold form-label small"
                                >
                                    Your Store's Address *
                                </label>

                                <input
                                    type="text"
                                    name="traderAddress"
                                    id="traderAddress"
                                    className="form-control"
                                    placeholder="15 Nollsworth Crescent"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                "Your Store's Address is required.",
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                "Your Store's Address must be between 2 and 191 characters.",
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                "Your Store's Address must be between 2 and 191 characters.",
                                        },
                                    })}
                                />

                                {errors.traderAddress && (
                                    <div className="invalid-feedback">
                                        {errors.traderAddress.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="traderSuburb"
                                    className="fw-bold form-label small"
                                >
                                    Your Store's Suburb *
                                </label>

                                <input
                                    type="text"
                                    name="traderSuburb"
                                    id="traderSuburb"
                                    className="form-control"
                                    placeholder="Umhlanga"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                "Your Store's Suburb is required.",
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                "Your Store's Suburb must be between 2 and 191 characters.",
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                "Your Store's Suburb must be between 2 and 191 characters.",
                                        },
                                    })}
                                />

                                {errors.traderSuburb && (
                                    <div className="invalid-feedback">
                                        {errors.traderSuburb.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="traderCity"
                                    className="fw-bold form-label small"
                                >
                                    Store's City *
                                </label>

                                <input
                                    type="text"
                                    name="traderCity"
                                    id="traderCity"
                                    className="form-control"
                                    placeholder="Durban"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                "Your Store's City is required.",
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                "Your Store's City must be between 2 and 191 characters.",
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                "Your Store's City must be between 2 and 191 characters.",
                                        },
                                    })}
                                />

                                {errors.traderCity && (
                                    <div className="invalid-feedback">
                                        {errors.traderCity.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="traderProvinceId"
                                    className="fw-bold form-label small"
                                >
                                    Your Store's Province *
                                </label>

                                <select
                                    name="traderProvinceId"
                                    id="traderProvinceId"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                "Your Store's Province is required.",
                                        },
                                    })}
                                >
                                    <option value="" disabled>
                                        Choose
                                    </option>
                                    {provinces &&
                                        provinces.map(function (item, index) {
                                            return (
                                                <option
                                                    key={index}
                                                    value={item.province_id}
                                                >
                                                    {item.province_name}
                                                </option>
                                            );
                                        })}
                                </select>

                                {errors.traderProvinceId && (
                                    <div className="invalid-feedback">
                                        {errors.traderProvinceId.message}
                                    </div>
                                )}
                            </div>

                            <ResponseMessage
                                message={responseMessage}
                                status={responseStatus}
                            />

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Create"
                                    className="btn btn-wide btn-primary"
                                />

                                <a
                                    href={CMS_TRADER_INDEX}
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
