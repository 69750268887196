// Packages
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';

// Components
import TraderRegisterLayout from './TraderRegisterLayout';
import ResponseMessage from '../../../components/ResponseMessage';

// Helpers
import Api from '../../../helpers/Api';

const TraderRegisterStep1 = (props) => {
    // Redux
    const traderRegister = useSelector((state) => state.traderRegister);
    const genderList = useSelector((state) => state.genderList);
    const languageList = useSelector((state) => state.languageList);
    const dispatch = useDispatch();

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Datepicker
    const dateMin = moment().subtract(118, 'years').toDate();
    const dateMax = moment().subtract(18, 'years').toDate();

    // React Hook Form
    const { handleSubmit, register, errors, control, watch } = useForm();
    const password = useRef({});
    password.current = watch('password', '');

    // Component Did Mount
    useEffect(() => {
        Api.get('/register')
            .then(function (response) {
                dispatch({
                    type: 'SET_GENDER_LIST',
                    payload: response.data.data.genders,
                });

                dispatch({
                    type: 'SET_LANGUAGE_LIST',
                    payload: response.data.data.languages,
                });

                dispatch({
                    type: 'SET_PROVINCE_LIST',
                    payload: response.data.data.provinces,
                });

                dispatch({
                    type: 'SET_REFERAL_TYPE_LIST',
                    payload: response.data.data.referral_types,
                });

                dispatch({
                    type: 'SET_STORE_AMOUNT_LIST',
                    payload: response.data.data.store_amounts,
                });

                dispatch({
                    type: 'SET_STORE_TYPE_LIST',
                    payload: response.data.data.store_types,
                });

                dispatch({
                    type: 'SET_TRADER_LIST',
                    payload: null,
                });
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage('Unable to retrieve the form data.');
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    // Submit Form
    const onSubmit = (data) => {
        dispatch({
            type: 'SET_TRADER_REGISTER',
            payload: {
                ...traderRegister,
                firstName: data.firstName,
                lastName: data.lastName,
                emailAddress: data.emailAddress,
                mobileNumber: data.mobileNumber,
                dateOfBirth: data.dateOfBirth,
                password: data.password,
                passwordConfirm: data.passwordConfirm,
                genderId: data.genderId,
                languageId: data.languageId,
                customerNumber: data.customerNumber,
            },
        });

        props.history.push('/trader/register/2');
    };

    return (
        <>
            <TraderRegisterLayout
                stepName="Personal Information"
                stepNumber="1"
                stepProgress="w-25"
            >
                <ResponseMessage
                    message={responseMessage}
                    status={responseStatus}
                />

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-4">
                        <label
                            htmlFor="firstName"
                            className="fw-bold form-label small"
                        >
                            First Name *
                        </label>

                        <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            className="form-control"
                            placeholder="Jane"
                            defaultValue={traderRegister.firstName}
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'First Name is required.',
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        'First Name must be between 2 and 191 characters.',
                                },
                                maxLength: {
                                    value: 191,
                                    message:
                                        'First Name must be between 2 and 191 characters.',
                                },
                            })}
                        />

                        {errors.firstName && (
                            <div className="invalid-feedback">
                                {errors.firstName.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="lastName"
                            className="fw-bold form-label small"
                        >
                            Last Name *
                        </label>

                        <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            className="form-control"
                            placeholder="Doe"
                            defaultValue={traderRegister.lastName}
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Last Name is required.',
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        'Last Name must be between 2 and 191 characters.',
                                },
                                maxLength: {
                                    value: 191,
                                    message:
                                        'Last Name must be between 2 and 191 characters.',
                                },
                            })}
                        />

                        {errors.lastName && (
                            <div className="invalid-feedback">
                                {errors.lastName.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="emailAddress"
                            className="fw-bold form-label small"
                        >
                            Email Address
                        </label>

                        <input
                            type="email"
                            name="emailAddress"
                            id="emailAddress"
                            className="form-control"
                            placeholder="someone@company.co.za"
                            defaultValue={traderRegister.emailAddress}
                            autoComplete="username"
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Email Address is required.',
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        'Email Address must be between 2 and 191 characters.',
                                },
                                maxLength: {
                                    value: 191,
                                    message:
                                        'Email Address must be between 2 and 191 characters.',
                                },
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message:
                                        'Email Address is an invalid format.',
                                },
                            })}
                        />

                        {errors.emailAddress && (
                            <div className="invalid-feedback">
                                {errors.emailAddress.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="mobileNumber"
                            className="fw-bold form-label small"
                        >
                            Mobile Number *
                        </label>

                        <input
                            type="text"
                            name="mobileNumber"
                            id="mobileNumber"
                            className="form-control"
                            placeholder="0660714504"
                            defaultValue={traderRegister.mobileNumber}
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Mobile Number is required.',
                                },
                                minLength: {
                                    value: 10,
                                    message: 'Mobile Number must be 10 digits.',
                                },
                                maxLength: {
                                    value: 10,
                                    message: 'Mobile Number must be 10 digits.',
                                },
                                pattern: {
                                    value: /^([0]{1})([1-9]{1})([0-9]{8})$/g,
                                    message:
                                        'Mobile Number is an invalid format.',
                                },
                            })}
                        />

                        {errors.mobileNumber && (
                            <div className="invalid-feedback">
                                {errors.mobileNumber.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="dateOfBirth"
                            className="fw-bold form-label small"
                        >
                            Date of Birth *
                        </label>

                        <Controller
                            name="dateOfBirth"
                            className="form-control"
                            control={control}
                            valueName="selected"
                            defaultValue={
                                traderRegister.dateOfBirth === ''
                                    ? ''
                                    : moment(
                                          traderRegister.dateOfBirth
                                      ).toDate()
                            }
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Date of Birth is required.',
                                },
                            }}
                            render={({ onChange, value }) => (
                                <DatePicker
                                    className="form-control"
                                    id="dateOfBirth"
                                    dateFormat="yyyy-MM-dd"
                                    minDate={dateMin}
                                    maxDate={dateMax}
                                    showMonthDropdown
                                    showYearDropdown
                                    selected={
                                        value === ''
                                            ? ''
                                            : moment(value).toDate()
                                    }
                                    onChange={onChange}
                                />
                            )}
                        />

                        {errors.dateOfBirth && (
                            <div className="invalid-feedback">
                                {errors.dateOfBirth.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="password"
                            className="fw-bold form-label small"
                        >
                            Create Password *
                        </label>

                        <input
                            type="password"
                            name="password"
                            id="password"
                            className="form-control"
                            defaultValue={traderRegister.password}
                            autoComplete="new-password"
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Password is required.',
                                },
                                minLength: {
                                    value: 8,
                                    message:
                                        'Password must be at least 8 characters.',
                                },
                            })}
                        />

                        {errors.password && (
                            <div className="invalid-feedback">
                                {errors.password.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="passwordConfirm"
                            className="fw-bold form-label small"
                        >
                            Confirm Password *
                        </label>

                        <input
                            type="password"
                            name="passwordConfirm"
                            id="passwordConfirm"
                            className="form-control"
                            defaultValue={traderRegister.passwordConfirm}
                            autoComplete="new-password"
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Confirm Password is required.',
                                },
                                minLength: {
                                    value: 8,
                                    message:
                                        'Confirm Password must be at least 8 characters.',
                                },
                                validate: (value) =>
                                    value === password.current ||
                                    'Passwords do not match.',
                            })}
                        />

                        {errors.passwordConfirm && (
                            <div className="invalid-feedback">
                                {errors.passwordConfirm.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="genderId"
                            className="fw-bold form-label small"
                        >
                            Gender *
                        </label>

                        <select
                            name="genderId"
                            id="genderId"
                            className="form-control"
                            defaultValue={traderRegister.genderId}
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Gender is required.',
                                },
                            })}
                        >
                            <option value="" disabled>
                                Choose
                            </option>
                            {genderList &&
                                genderList.map(function (item, index) {
                                    return (
                                        <option
                                            key={index}
                                            value={item.gender_id}
                                        >
                                            {item.gender_name}
                                        </option>
                                    );
                                })}
                        </select>

                        {errors.genderId && (
                            <div className="invalid-feedback">
                                {errors.genderId.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="languageId"
                            className="fw-bold form-label small"
                        >
                            Home Language *
                        </label>

                        <select
                            name="languageId"
                            id="languageId"
                            className="form-control"
                            defaultValue={traderRegister.languageId}
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Home Language is required.',
                                },
                            })}
                        >
                            <option value="" disabled>
                                Choose
                            </option>
                            {languageList &&
                                languageList.map(function (item, index) {
                                    return (
                                        <option
                                            key={index}
                                            value={item.language_id}
                                        >
                                            {item.language_name}
                                        </option>
                                    );
                                })}
                        </select>

                        {errors.languageId && (
                            <div className="invalid-feedback">
                                {errors.languageId.message}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="customerNumber"
                            className="fw-bold form-label small"
                        >
                            Enter Customer Number *
                        </label>

                        <input
                            type="text"
                            name="customerNumber"
                            id="customerNumber"
                            className="form-control"
                            placeholder="UNI12345"
                            defaultValue={traderRegister.customerNumber}
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Customer Number is required.',
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        'Customer Number must be at least 2 characters.',
                                },
                            })}
                        />

                        {errors.customerNumber && (
                            <div className="invalid-feedback">
                                {errors.customerNumber.message}
                            </div>
                        )}
                    </div>

                    <div className="text-center mt-100 mb-270">
                        <button
                            type="submit"
                            className="next btn btn-wide btn-primary btn-lg text-uppercase"
                        >
                            Next <i className="fas fa-angle-right"></i>
                        </button>
                    </div>
                </form>
            </TraderRegisterLayout>
        </>
    );
};

export default withRouter(TraderRegisterStep1);
