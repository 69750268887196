// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/PortalUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { PORTAL_DASHBOARD } from '../../../routes';

export default function PortalTraderShow() {
    // Redux
    const dispatch = useDispatch();
    const trader = useSelector((state) => state.trader);
    const user = useSelector((state) => state.user);

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Check URL
    const url = window.location.pathname;
    const urlParams = url.split('/');

    // Component Did Mount
    useEffect(() => {
        Api.get('/traders/' + urlParams[3])
            .then(function (response) {
                if (response.data.success) {
                    dispatch({
                        type: 'SET_USER',
                        payload: response.data.data.user,
                    });

                    dispatch({
                        type: 'SET_TRADER',
                        payload: response.data.data.trader,
                    });
                } else {
                    setResponseMessage(response.data.message);
                    setResponseStatus('error');
                }
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage(
                        'An error occurred while retrieving your data.'
                    );
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Trader Dashboard | Unilever Shopper</title>
                <body className="trader-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="trader-dashboard" className="container mb-5">
                <div className="row">
                    <div className="col-12 text-center">
                        <Title title={trader ? trader.trader_name : 'Trader'} />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />
                    </div>
                </div>

                {trader && user && trader.trader_mobile === user.mobile && (
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive mt-5">
                                <table
                                    id="table"
                                    className="table table-sm table-bordered"
                                >
                                    <thead className="table-light">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                First Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Last Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Role
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trader.users &&
                                            Object.keys(trader.users).length >
                                                0 &&
                                            trader.users.map(function (
                                                item,
                                                index
                                            ) {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center align-middle">
                                                            {
                                                                item.user_first_name
                                                            }
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {
                                                                item.user_last_name
                                                            }
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {item.mobile ===
                                                            trader.trader_mobile
                                                                ? 'Owner'
                                                                : 'Employee'}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2 mt-5 text-start">
                        <p>
                            <a href={PORTAL_DASHBOARD}>
                                User Dashboard{' '}
                                <i className="fas fa-arrow-right float-end"></i>
                            </a>
                        </p>

                        {trader &&
                            user &&
                            trader.trader_mobile === user.mobile && (
                                <p>
                                    <a
                                        href={
                                            '/portal/traders/' +
                                            urlParams[3] +
                                            '/edit'
                                        }
                                    >
                                        Edit Trader{' '}
                                        <i className="fas fa-arrow-right float-end"></i>
                                    </a>
                                </p>
                            )}
                    </div>
                </div>
            </section>
        </>
    );
}
