// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

export default function CmsProductEdit() {
    // Redux
    const dispatch = useDispatch();
    const product = useSelector((state) => state.product);

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Check URL
    const url = window.location.pathname;
    const urlParams = url.split('/');

    // React Form Hook
    const { handleSubmit, register, reset, errors } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            productName: '',
            productValue: '',
        },
    });

    useEffect(() => {
        Api.get('/products/' + urlParams[3] + '/edit')
            .then(function (response) {
                dispatch({
                    type: 'SET_PRODUCT',
                    payload: response.data.data.product,
                });
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage('Unable to retrieve the form data.');
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        reset({
            productName: product.product_name,
            productValue: product.product_value,
        });
        // eslint-disable-next-line
    }, [reset]);

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/products/' + urlParams[3], {
                _method: 'put',
                product_name: data.productName,
                product_value: data.productValue,
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Edit Product | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="product-edit" className="container mb-5">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <Title
                            title={
                                product
                                    ? 'Edit ' + product.product_name
                                    : 'Edit Product'
                            }
                        />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label
                                    htmlFor="productName"
                                    className="fw-bold form-label small"
                                >
                                    Product Name *
                                </label>

                                <input
                                    type="text"
                                    name="productName"
                                    id="productName"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Product Name is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Product Name must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Product Name must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.productName && (
                                    <div className="invalid-feedback">
                                        {errors.productName.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="productValue"
                                    className="fw-bold form-label small "
                                >
                                    Product Price *
                                </label>

                                <input
                                    type="text"
                                    name="productValue"
                                    id="productValue"
                                    className="form-control"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message:
                                                'Product Price is required.',
                                        },
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Product Price must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Product Price must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.productValue && (
                                    <div className="invalid-feedback">
                                        {errors.productValue.message}
                                    </div>
                                )}
                            </div>

                            <ResponseMessage
                                message={responseMessage}
                                status={responseStatus}
                            />

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Update Product"
                                    className="btn btn-wide btn-primary"
                                />

                                <a
                                    href="/cms/products/"
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
