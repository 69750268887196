// Packages
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Hero from '../../../components/Hero';
import Navbar from '../../../components/Navbar';
import ResponseMessage from '../../../components/ResponseMessage';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Images
import HeroDesktop from '../../../assets/img/page-banners/banner-stokvel-login-desktop.png';
import HeroMobile from '../../../assets/img/page-banners/banner-stokvel-login-mobile.png';

// Routes
import {
    PORTAL_DASHBOARD,
    STOKVEL_REGISTER,
    STOKVEL_FORGOT_PASSWORD,
} from '../../../routes';

function StokvelLogin() {
    // Redux
    const dispatch = useDispatch();

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Browser History
    const history = useHistory();

    // React Hook Form
    const { handleSubmit, register, errors } = useForm({
        defaultValues: {
            mobileNumber: '',
            password: '',
            friendName: '',
            friendMobile: '',
            remember: false,
        },
    });

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/login', {
                mobile: data.mobileNumber,
                password: data.password,
                friend_name: data.friendName,
                friend_mobile: data.friendMobile,
                remember: data.remember,
            })
                .then(function (response) {
                    if (response.data.data.access_token) {
                        dispatch({
                            type: 'SET_ACCESS_TOKEN',
                            payload: response.data.data.access_token,
                        });

                        dispatch({
                            type: 'SET_USER',
                            payload: response.data.data.user,
                        });

                        setTimeout(() => {
                            history.push(PORTAL_DASHBOARD);
                        }, 2000);
                    }
                })
                .catch(function (error) {
                    if (error.response.data) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Login as Stokvel | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <section id="login">
                <Hero
                    title="Login as Stokvel"
                    heroMobile={HeroMobile}
                    heroDesktop={HeroDesktop}
                />

                <div className="container my-5">
                    <div className="row">
                        <div className="col-12 col-lg-6 offset-lg-3">
                            <Title title="Log into your account" />

                            <ResponseMessage
                                message={responseMessage}
                                status={responseStatus}
                            />

                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                autoComplete="off"
                            >
                                <div className="mb-4">
                                    <label
                                        htmlFor="mobileNumber"
                                        className="fw-bold form-label small"
                                    >
                                        Mobile Number *
                                    </label>

                                    <input
                                        type="text"
                                        name="mobileNumber"
                                        id="mobileNumber"
                                        className="form-control"
                                        placeholder="0660714504"
                                        autoComplete="username"
                                        ref={register({
                                            required: {
                                                value: true,
                                                message:
                                                    'Mobile Number is required.',
                                            },
                                            minLength: {
                                                value: 10,
                                                message:
                                                    'Mobile Number must be 10 digits.',
                                            },
                                            maxLength: {
                                                value: 10,
                                                message:
                                                    'Mobile Number must be 10 digits.',
                                            },
                                            pattern: {
                                                value: /^([0]{1})([1-9]{1})([0-9]{8})$/g,
                                                message:
                                                    'Mobile Number is an invalid format.',
                                            },
                                        })}
                                    />

                                    {errors.mobileNumber && (
                                        <div className="invalid-feedback">
                                            {errors.mobileNumber.message}
                                        </div>
                                    )}
                                </div>

                                <div className="mb-4">
                                    <label
                                        htmlFor="password"
                                        className="fw-bold form-label small"
                                    >
                                        Password *
                                    </label>

                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        className="form-control"
                                        autoComplete="current-password"
                                        ref={register({
                                            required: {
                                                value: true,
                                                message:
                                                    'Password is required.',
                                            },
                                            minLength: {
                                                value: 4,
                                                message:
                                                    'Password must be between 4 and 191 characters.',
                                            },
                                            maxLength: {
                                                value: 191,
                                                message:
                                                    'Password must be between 8 and 191 characters.',
                                            },
                                        })}
                                    />

                                    {errors.password && (
                                        <div className="invalid-feedback">
                                            {errors.password.message}
                                        </div>
                                    )}
                                </div>

                                <div className="row mb-5">
                                    <div className="col-12 col-lg-6 mb-3 mb-lg-0 text-start">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                name="remember"
                                                id="remember"
                                                className="form-check-input"
                                                ref={register()}
                                            />
                                            <label
                                                htmlFor="remember"
                                                className="form-check-label"
                                            >
                                                Remember Me
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6 text-center text-lg-end">
                                        <a href={STOKVEL_FORGOT_PASSWORD}>
                                            Forgot Your Password?
                                        </a>
                                    </div>
                                </div>

                                <h2 className="text-center">Refer a Friend</h2>

                                <p>
                                    Get your friend to join the Wozani
                                    Nizothenga Loyalty Programme mdeni and you
                                    could win R10 airtime. Simply fill in your
                                    friend's details and stand to win.
                                </p>

                                <div className="mb-4">
                                    <label
                                        htmlFor="friendName"
                                        className="fw-bold form-label small"
                                    >
                                        Your Friend's First Name
                                    </label>

                                    <input
                                        type="text"
                                        name="friendName"
                                        id="friendName"
                                        className="form-control"
                                        placeholder="Jane"
                                        ref={register({
                                            minLength: {
                                                value: 2,
                                                message:
                                                    "Your Friend's Name must be between 2 and 191 characters.",
                                            },
                                            maxLength: {
                                                value: 191,
                                                message:
                                                    "Your Friend's Name must be between 2 and 191 characters.",
                                            },
                                        })}
                                    />

                                    {errors.friendName && (
                                        <div className="invalid-feedback">
                                            {errors.friendName.message}
                                        </div>
                                    )}
                                </div>

                                <div className="mb-5">
                                    <label
                                        htmlFor="friendMobile"
                                        className="fw-bold form-label small"
                                    >
                                        Your Friend's Mobile Number
                                    </label>

                                    <input
                                        type="text"
                                        name="friendMobile"
                                        id="friendMobile"
                                        className="form-control"
                                        placeholder="0660714504"
                                        inputMode="numeric"
                                        ref={register({
                                            minLength: {
                                                value: 10,
                                                message:
                                                    "Your Friend's Mobile Number must be 10 digits.",
                                            },
                                            maxLength: {
                                                value: 10,
                                                message:
                                                    "Your Friend's Mobile Number must be 10 digits.",
                                            },
                                            pattern: {
                                                value: /^([0]{1})([1-9]{1})([0-9]{8})$/g,
                                                message:
                                                    "Your Friend's Mobile Number is an invalid format.",
                                            },
                                        })}
                                    />
                                    {errors.friendMobile && (
                                        <div className="invalid-feedback">
                                            {errors.friendMobile.message}
                                        </div>
                                    )}
                                </div>

                                <div className="mb-3 text-center">
                                    <input
                                        type="submit"
                                        value="Login"
                                        className="btn btn-wide btn-primary"
                                    />
                                </div>
                            </form>

                            <div className="small text-center">
                                <a href={STOKVEL_REGISTER} className="fw-light">
                                    Dont have an account?{' '}
                                    <span className="fw-bold">
                                        Sign up here
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default withRouter(StokvelLogin);
