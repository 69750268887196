// Packages
import React from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';

// Components
import Hero from '../../../../components/Hero';
import Navbar from '../../../../components/Navbar';

// Images
import HeroDesktop from '../../../../assets/img/page-banners/banner-bundles-and-promotions-desktop.png';
import HeroMobile from '../../../../assets/img/page-banners/banner-bundles-and-promotions-mobile.png';
import Bundle001 from '../../../../assets/img/bundles-and-promotions/mixed-deal.jpg';
import Bundle002 from '../../../../assets/img/bundles-and-promotions/surf-mix.jpg';
import Bundle003 from '../../../../assets/img/bundles-and-promotions/washing-powder.jpg';
import Bundle004 from '../../../../assets/img/bundles-and-promotions/knorrox.jpg';

// Slider
const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    lazyLoad: true,
    infinite: false,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

export default function BundlesAndPromotions() {
    return (
        <>
            <Helmet>
                <title>Bundles and Promotions | Unilever Shopper</title>
                <body className="trader-app" />
            </Helmet>

            <Navbar />

            <section id="product-highlights">
                <Hero
                    title="Bundles and Promotions"
                    heroMobile={HeroMobile}
                    heroDesktop={HeroDesktop}
                />

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center py-5">
                            <h1 className="mb-3">Bundles and Promotions</h1>

                            <p>
                                Check with your Unilever Rep on which deals are
                                valid this week.
                            </p>

                            <Slider {...settings}>
                                <div className="slide">
                                    <img
                                        src={Bundle001}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>

                                <div className="slide">
                                    <img
                                        src={Bundle002}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>

                                <div className="slide">
                                    <img
                                        src={Bundle003}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>

                                <div className="slide">
                                    <img
                                        src={Bundle004}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
