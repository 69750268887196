// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/PortalUnileverLogo';
import Title from '../../../components/Title';
import UploadTillSlipButton from '../../../components/Buttons/UploadTillSlip';

// Helpers
import Api from '../../../helpers/Api';

// Images
import DesktopBanner from '../../../assets/img/campaigns/2022/q4-cashback/2022-q4-banner-desktop.png';
import MobileBanner from '../../../assets/img/campaigns/2022/q4-cashback/2022-q4-banner-desktop.png';

// Routes
import {
    PORTAL_REFER_A_FRIEND,
    PORTAL_TILLSLIP_UPLOAD,
    PORTAL_USER_PASSWORD_EDIT,
    PORTAL_USER_PROFILE_EDIT,
} from '../../../routes';

export default function PortalDashboard() {
    // Redux
    const dispatch = useDispatch();
    const userStokvel = useSelector((state) => state.userStokvel);
    const userTrader = useSelector((state) => state.userTrader);
    const user = useSelector((state) => state.user);

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Calculate Total Tillslip Value
    function overallTillSlipValue(items) {
        let totalValue = 0.0;
        for (let index1 = 0; index1 < items.length; index1++) {
            totalValue += parseFloat(items[index1].tillslip_total_amount);
        }
        return parseFloat(totalValue);
    }

    // Component Did Mount
    useEffect(() => {
        Api.get('/portal')
            .then(function (response) {
                if (response.data.success) {
                    dispatch({
                        type: 'SET_USER',
                        payload: response.data.data.user,
                    });

                    dispatch({
                        type: 'SET_USER_STOKVEL',
                        payload: response.data.data.user.stokvels,
                    });

                    dispatch({
                        type: 'SET_USER_TRADER',
                        payload: response.data.data.user.traders,
                    });
                } else {
                    setResponseMessage(response.data.message);
                    setResponseStatus('error');
                }
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage(
                        'An error occurred while retrieving your data.'
                    );
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>User Dashboard | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="stokvel-dashboard" className="container my-5">
                <div className="row">
                    <div className="col-12">
                        {userStokvel && Object.keys(userStokvel).length > 0 && (
                            <div className="mb-5 text-center">
                                <picture>
                                    <source
                                        media="(max-width:768px)"
                                        srcSet={MobileBanner}
                                        className="img-fluid"
                                    />
                                    <source
                                        media="(min-width:769px)"
                                        srcSet={DesktopBanner}
                                        className="img-fluid"
                                    />
                                    <img
                                        src={DesktopBanner}
                                        alt="Purchase Unilever products to the value of R5000 and receive R500 cash back. Valid 17 November 2022 to 31 January 2023."
                                        className="img-fluid"
                                    />
                                </picture>
                            </div>
                        )}

                        <Title
                            title={
                                user
                                    ? 'Welcome ' + user.user_first_name
                                    : 'Welcome'
                            }
                        />

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        {userStokvel && Object.keys(userStokvel).length > 0 && (
                            <UploadTillSlipButton />
                        )}
                    </div>
                </div>

                {userStokvel && Object.keys(userStokvel).length > 0 && (
                    <div className="row">
                        <div className="col-12 mb-5">
                            <div className="border p-3">
                                <h2 className="mb-3 text-center">
                                    Your Stokvels
                                </h2>

                                <div className="table-responsive">
                                    <table
                                        id="table-stokvels"
                                        className="table table-bordered table-striped table-sm"
                                    >
                                        <thead className="table-light">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    &nbsp;
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Stokvel Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Members
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Till Slips
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userStokvel &&
                                                Object.keys(userStokvel)
                                                    .length > 0 &&
                                                userStokvel.map(function (
                                                    item,
                                                    index
                                                ) {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-center align-middle">
                                                                <a
                                                                    href={
                                                                        '/portal/stokvels/' +
                                                                        item.stokvel_id
                                                                    }
                                                                >
                                                                    <i className="fa-fw fas fa-search"></i>
                                                                </a>
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                <a
                                                                    href={
                                                                        '/portal/stokvels/' +
                                                                        item.stokvel_id
                                                                    }
                                                                >
                                                                    {
                                                                        item.stokvel_name
                                                                    }
                                                                </a>
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                {
                                                                    item.stokvel_membership_count
                                                                }
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                {
                                                                    item
                                                                        .till_slips
                                                                        .length
                                                                }
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                R{' '}
                                                                {item.till_slips
                                                                    .length > 0
                                                                    ? parseFloat(
                                                                          overallTillSlipValue(
                                                                              item.till_slips
                                                                          )
                                                                      ).toFixed(
                                                                          2
                                                                      )
                                                                    : parseFloat(
                                                                          0.0
                                                                      ).toFixed(
                                                                          2
                                                                      )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {userTrader && Object.keys(userTrader).length > 0 && (
                    <div className="row">
                        <div className="col-12 mb-5">
                            <div className="border p-3">
                                <h2 className="mb-3 text-center">
                                    Your Traders
                                </h2>

                                <div className="table-responsive">
                                    <table
                                        id="table-traders"
                                        className="table table-bordered table-striped table-sm"
                                    >
                                        <thead className="table-light">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    &nbsp;
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Trader Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Members
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Till Slips
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center align-middle"
                                                >
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userTrader &&
                                                Object.keys(userTrader).length >
                                                    0 &&
                                                userTrader.map(function (
                                                    item,
                                                    index
                                                ) {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-center align-middle">
                                                                <a
                                                                    href={
                                                                        '/portal/traders/' +
                                                                        item.trader_id
                                                                    }
                                                                >
                                                                    <i className="fa-fw fas fa-search"></i>
                                                                </a>
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                <a
                                                                    href={
                                                                        '/portal/traders/' +
                                                                        item.trader_id
                                                                    }
                                                                >
                                                                    {
                                                                        item.trader_name
                                                                    }
                                                                </a>
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                {
                                                                    item.trader_membership_count
                                                                }
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                {
                                                                    item
                                                                        .till_slips
                                                                        .length
                                                                }
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                R{' '}
                                                                {item.till_slips
                                                                    .length > 0
                                                                    ? parseFloat(
                                                                          overallTillSlipValue(
                                                                              item.till_slips
                                                                          )
                                                                      ).toFixed(
                                                                          2
                                                                      )
                                                                    : parseFloat(
                                                                          0.0
                                                                      ).toFixed(
                                                                          2
                                                                      )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2 text-start">
                        <p>
                            <a href={PORTAL_USER_PROFILE_EDIT}>
                                Update Profile{' '}
                                <i className="fas fa-arrow-right float-end"></i>
                            </a>
                        </p>

                        <p>
                            <a href={PORTAL_USER_PASSWORD_EDIT}>
                                Update Password{' '}
                                <i className="fas fa-arrow-right float-end"></i>
                            </a>
                        </p>

                        {userStokvel && Object.keys(userStokvel).length > 0 && (
                            <p>
                                <a href={PORTAL_TILLSLIP_UPLOAD}>
                                    Upload Till Slip{' '}
                                    <i className="fas fa-arrow-right float-end"></i>
                                </a>
                            </p>
                        )}

                        <p>
                            <a href={PORTAL_REFER_A_FRIEND}>
                                Refer a Friend{' '}
                                <i className="fas fa-arrow-right float-end"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}
