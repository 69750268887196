// Unilever South Africa
export const UNILEVER_ACCESSIBILITY =
    'https://www.unilever.co.za/accessibility.html';
export const UNILEVER_CONTACT = 'https://www.unilever.co.za/contact/';
export const UNILEVER_COOKIE =
    'https://www.unilevernotices.com/south-africa/english/cookie-notice/notice.html';
export const UNILEVER_LEGAL = 'https://www.unilever.co.za/legal.html';
export const UNILEVER_PRIVACY =
    'https://www.unilevernotices.com/south-africa/english/privacy-notice/notice.html';
export const UNILEVER_RSS = 'https://www.unilever.co.za/rss.html';
export const UNILEVER_WEBSITE = 'https://www.unilever.co.za/';

// Google
export const GOOGLE_MAP = 'https://goo.gl/maps/bhgg2KLFCkGFHpUq8';

// Frontend
export const HOME = '/';
export const CONTACT = '/contact';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';

// Frontend - Stokvel
export const LEADERBOARD = '/leaderboard';
export const PRINT_ADS = '/unilever-print-ads';
export const PRODUCT_HIGHLIGHTS = '/product-highlights';
export const TESTIMONIALS = '/testimonials';

// Frontend - Trader
export const TRADER_BUNDLES_AND_PROMOTIONS = '/traders/bundles-and-promotions';
export const MYSTORE_INDEX = '/mystore';
export const MYSTORE_CREATE = '/mystore/contact';
export const MYSTORE_ARTICLE1 =
    '/mystore/articles/turn-your-customers-into-fans';
export const MYSTORE_ARTICLE2 = '/mystore/articles/covid-19-and-your-business';

// Auth
export const AUTH_LOGOUT = '/logout';

// Auth - Stokvel
export const STOKVEL_REGISTER = '/stokvel/register';
export const STOKVEL_REGISTER_2 = '/stokvel/register/2';
export const STOKVEL_REGISTER_3 = '/stokvel/register/3';
export const STOKVEL_REGISTER_4 = '/stokvel/register/4';
export const STOKVEL_LOGIN = '/stokvel/login';
export const STOKVEL_FORGOT_PASSWORD = '/stokvel/forgot-password';

// Auth - Trader
export const TRADER_REGISTER = '/trader/register';
export const TRADER_REGISTER_2 = '/trader/register/2';
export const TRADER_REGISTER_3 = '/trader/register/3';
export const TRADER_REGISTER_4 = '/trader/register/4';
export const TRADER_LOGIN = '/trader/login';
export const TRADER_FORGOT_PASSWORD = '/trader/forgot-password';

// Portal
export const PORTAL_DASHBOARD = '/portal';
export const PORTAL_REFER_A_FRIEND = '/portal/refer-a-friend';
export const PORTAL_TILLSLIP_UPLOAD = '/portal/upload-tillslip';
export const PORTAL_USER_PROFILE_EDIT = '/portal/update-profile';
export const PORTAL_USER_PASSWORD_EDIT = '/portal/update-password';
export const PORTAL_STOKVEL_CREATE = '/portal/stokvels/create';
export const PORTAL_STOKVEL_SHOW = '/portal/stokvels/:id';
export const PORTAL_STOKVEL_EDIT = '/portal/stokvels/:id/edit';
export const PORTAL_TRADER_CREATE = '/portal/traders/create';
export const PORTAL_TRADER_SHOW = '/portal/traders/:id';
export const PORTAL_TRADER_EDIT = '/portal/traders/:id/edit';

// CMS - Dashboard
export const CMS_DASHBOARD = '/cms';
export const CMS_SYSTEM_OVERVIEW = '/cms/system-overview';

// CMS - Airtime
export const CMS_AIRTIME_CREATE = '/cms/airtime/upload';

// CMS - MyStore - Articles
export const CMS_MYSTORE_ARTICLES_INDEX = '/cms/mystore/articles';

// CMS - MyStore - Magazines
export const CMS_MYSTORE_MAGAZINES_INDEX = '/cms/mystore/magazines';

export const CMS_PRINTADTYPES_CREATE = '/cms/printadtypes/create';
export const CMS_PRINTADTYPES_INDEX = '/cms/printadtypes';
export const CMS_PRINTADTYPES_EDIT = '/cms/printadtypes/:id/edit';

// CMS - Print Ads
export const CMS_PRINTADS_INDEX = '/cms/print-ads';

// CMS - Products
export const CMS_PRODUCT_INDEX = '/cms/products';
export const CMS_PRODUCT_CREATE = '/cms/products/create';
export const CMS_PRODUCT_EDIT = '/cms/products/:id/edit';

// CMS - Products
export const CMS_PRODUCT_HIGHLIGHTS_INDEX = '/cms/product-highlights';

// CMS - SMS
export const CMS_SMS_CREATE = '/cms/sms/send';

// CMS - Stokvels
export const CMS_STOKVEL_INDEX = '/cms/stokvels';
export const CMS_STOKVEL_CREATE = '/cms/stokvels/create';
export const CMS_STOKVEL_EDIT = '/cms/stokvels/:id/edit';

// CMS - Testimonials
export const CMS_TESTIMONIAL_INDEX = '/cms/testimonials';
export const CMS_TESTIMONIAL_CREATE = '/cms/testimonials/create';
export const CMS_TESTIMONIAL_EDIT = '/cms/testimonials/:id/edit';

// CMS - Traders
export const CMS_TRADER_INDEX = '/cms/traders';
export const CMS_TRADER_CREATE = '/cms/traders/create';
export const CMS_TRADER_EDIT = '/cms/traders/:id/edit';

// CMS - Trader's Bundles and Promotions
export const CMS_TRADER_BUNDLE_PROMOTION_INDEX = '/cms/bundles-and-promotions';
export const CMS_TRADER_BUNDLE_PROMOTION_CREATE =
    '/cms/bundles-and-promotions/create';
export const CMS_TRADER_BUNDLE_PROMOTION_EDIT =
    '/cms/bundles-and-promotions/:id/edit';

// CMS - Till Slips
export const CMS_TILLSLIP_INDEX = '/cms/tillslips';
export const CMS_TILLSLIP_CREATE = '/cms/tillslips/create';
export const CMS_TILLSLIP_EDIT = '/cms/tillslips/:id/edit';
export const CMS_TILLSLIPITEM_EDIT = '/cms/tillslipitem/:id/edit';

// CMS - Users
export const CMS_USER_INDEX = '/cms/users';
export const CMS_USER_CREATE = '/cms/users/create';
export const CMS_USER_EDIT = '/cms/users/:id/edit';

// Errors
export const ERROR_404 = '/404';
