import React from 'react';

// Icons
import NoIcon from '../../../components/Icons/NoIcon';
import YesIcon from '../../../components/Icons/YesIcon';

const Tillslip = (props) => {
    const {
        index,
        tillslip_id,
        user: { user_first_name },
        user: { user_last_name },
        tillslip_stokvel_id,
        tillslip_invoice_number,
        tillslip_total_amount,
        tillslip_is_processed,
        tillslip_is_approved,
    } = props.data;

    return (
        <tr key={index}>
            <td className="text-center align-middle">
                <a href={'/cms/tillslips/' + tillslip_id + '/edit'}>
                    <i className="fa-fw fas fa-edit"></i>
                </a>
            </td>
            <td className="text-center align-middle">
                {user_first_name + ' ' + user_last_name}
            </td>
            <td className="text-center align-middle">
                {tillslip_stokvel_id ? 'Stokvel' : 'Trader'}
            </td>
            <td className="text-center align-middle">
                {tillslip_stokvel_id
                    ? props.data.stokvel.stokvel_name
                    : props.data.trader
                    ? props.data.trader.trader_name
                    : ''}
            </td>
            <td className="text-center align-middle">
                {tillslip_invoice_number}
            </td>
            <td className="text-center align-middle">
                R{' '}
                {tillslip_total_amount
                    ? parseFloat(tillslip_total_amount).toFixed(2)
                    : parseFloat(0.0).toFixed(2)}
            </td>
            <td className="text-center align-middle">
                {tillslip_is_processed === '1' ? <YesIcon /> : <NoIcon />}
            </td>
            <td className="text-center align-middle">
                {tillslip_is_approved === '1' ? <YesIcon /> : <NoIcon />}
            </td>
        </tr>
    );
};

export default Tillslip;
