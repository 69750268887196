// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Navbar from '../../../components/Navbar/AdminNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Helpers
import Api from '../../../helpers/Api';

// Routes
import { CMS_DASHBOARD } from '../../../routes';

export default function TillSlipItemEdit() {
    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [tillSlipItems, setTillSlipItems] = useState([]);

    // Redux
    const dispatch = useDispatch();
    const productList = useSelector((state) => state.productList);

    // Check URL
    const url = window.location.pathname;
    const urlParams = url.split('/');

    // React Hook Form
    const { handleSubmit, register, reset, errors } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            product: '',
            price: '',
            quantity: '',
        },
    });

    // Component Did Mount
    useEffect(() => {
        Api.get('tillslipitem/' + urlParams[3] + '/edit')
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: 'SET_TILL_SLIP_ITEM',
                        payload: response.data.data.tillSlipItemsArray,
                    });
                    setTillSlipItems(response.data.data.tillSlipItemsArray);

                    dispatch({
                        type: 'SET_PRODUCT_LIST',
                        payload: response.data.data.product,
                    });
                }
            })
            .catch((error) => {
                if (error.response) {
                    setResponseMessage(error.response.data.message);
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, []);

    // Submit Form
    const onSubmit = (data) => {
        const product = data.product;
        const price = parseFloat(data.price).toFixed(2);
        const quantity = parseInt(data.quantity);
        const total = parseFloat(price * quantity).toFixed(2);
        const dataObject = {
            product: product,
            price: price,
            quantity: quantity,
            total: total,
        };
        const mergeData = [dataObject];
        setTillSlipItems(mergeData);
        reset({
            product: '',
            price: '',
            quantity: '',
        });
    };

    // Handle Edit
    const handleEdit = (index, data) => {
        reset({
            product: data.product,
            price: data.price,
            quantity: data.quantity,
        });

        let array = [...tillSlipItems];
        array.splice(index, 1);
        setTillSlipItems(array);
    };

    // Submit to Server
    const submitToServer = () => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('/tillslipitem/' + urlParams[3], {
                _method: 'put',
                tillslipItems: tillSlipItems,
            })
                .then((response) => {
                    window.location.reload(true);
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('error');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Edit Till Slip Item| Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="tillslips-edit" className="container mb-5">
                <div className="row">
                    <div className="col-12">
                        <Title title="Edit Till Slip Item" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="mb-5"
                        >
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label
                                        htmlFor="product"
                                        className="fw-bold form-label small"
                                    >
                                        Product
                                    </label>
                                    <input
                                        type="text"
                                        name="product"
                                        id="product"
                                        className="form-control"
                                        list="productList"
                                        ref={register({
                                            required: {
                                                value: true,
                                                message: 'Product is required.',
                                            },
                                            minLength: {
                                                value: 2,
                                                message:
                                                    'Product must be between 2 and 191 characters.',
                                            },
                                            maxLength: {
                                                value: 191,
                                                message:
                                                    'Product must be between 2 and 191 characters.',
                                            },
                                        })}
                                    />

                                    <datalist id="productList">
                                        {productList &&
                                            productList.map(function (
                                                item,
                                                index
                                            ) {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={
                                                            item.product_name
                                                        }
                                                    >
                                                        {item.product_name}
                                                    </option>
                                                );
                                            })}
                                    </datalist>

                                    {errors.product && (
                                        <div className="invalid-feedback">
                                            {errors.product.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 col-lg-2">
                                    <label
                                        htmlFor="price"
                                        className="fw-bold form-label small"
                                    >
                                        Price Per Unit
                                    </label>
                                    <input
                                        type="text"
                                        name="price"
                                        id="price"
                                        className="form-control"
                                        ref={register({
                                            required: {
                                                value: true,
                                                message: 'Price is required.',
                                            },
                                            minLength: {
                                                value: 1,
                                                message:
                                                    'Price must be at least 1 digit.',
                                            },
                                            pattern: {
                                                value: /^[0-9.]+$/,
                                                message:
                                                    'Price is an invalid format.',
                                            },
                                        })}
                                    />

                                    {errors.price && (
                                        <div className="invalid-feedback">
                                            {errors.price.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 col-lg-2">
                                    <label
                                        htmlFor="quantity"
                                        className="fw-bold form-label small"
                                    >
                                        Quantity
                                    </label>
                                    <input
                                        type="text"
                                        name="quantity"
                                        id="quantity"
                                        className="form-control"
                                        ref={register({
                                            required: {
                                                value: true,
                                                message:
                                                    'Quantity is required.',
                                            },
                                            minLength: {
                                                value: 1,
                                                message:
                                                    'Quantity must be at least 1 digit.',
                                            },
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message:
                                                    'Quantity is an invalid format.',
                                            },
                                        })}
                                    />

                                    {errors.quantity && (
                                        <div className="invalid-feedback">
                                            {errors.quantity.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 col-lg-2">
                                    <input
                                        type="submit"
                                        value="Add"
                                        className="btn btn-primary mt-32"
                                    />
                                </div>
                            </div>
                        </form>

                        {tillSlipItems && tillSlipItems.length > 0 && (
                            <div className="table-responsive mb-5">
                                <table
                                    id="table-tillslips"
                                    className="table table-bordered table-sm"
                                >
                                    <thead className="table-light">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Product
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Price Per Unit
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Quantity
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                Total
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle"
                                            >
                                                &nbsp;
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tillSlipItems &&
                                            Object.keys(tillSlipItems).length >
                                                0 &&
                                            tillSlipItems.map(function (
                                                item,
                                                index
                                            ) {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center align-middle">
                                                            {item.product}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            R{' '}
                                                            {parseFloat(
                                                                item.price
                                                            ).toFixed(2)}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {item.quantity}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            R{' '}
                                                            {parseFloat(
                                                                item.total
                                                            ).toFixed(2)}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    handleEdit(
                                                                        index,
                                                                        item
                                                                    )
                                                                }
                                                                className="btn"
                                                            >
                                                                <i className="fas fa-edit"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <div className="text-center">
                            <button
                                type="button"
                                className="btn btn-wide btn-primary"
                                onClick={submitToServer}
                            >
                                Save
                            </button>

                            <a
                                href={CMS_DASHBOARD}
                                className="btn btn-wide btn-secondary ms-2 text-uppercase"
                            >
                                Back
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
