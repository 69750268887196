// Session
export const UNSET_SESSION = 'UNSET_SESSION';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const UNSET_ACCESS_TOKEN = 'UNSET_ACCESS_TOKEN';

// Lists
export const SET_GENDER_LIST = 'SET_GENDER_LIST';
export const UNSET_GENDER_LIST = 'UNSET_GENDER_LIST';

export const SET_LANGUAGE_LIST = 'SET_LANGUAGE_LIST';
export const UNSET_LANGUAGE_LIST = 'UNSET_LANGUAGE_LIST';

export const SET_PRINTADTYPE = 'SET_PRINTADTYPE';
export const UNSET_PRINTADTYPE = 'UNSET_PRINTADTYPE';

export const SET_PRINTADTYPES_LIST = 'SET_PRINTADTYPES_LIST';
export const UNSET_PRINTADTYPES_LIST = 'UNSET_PRINTADTYPES_LIST';

export const SET_PROVINCE_LIST = 'SET_PROVINCE_LIST';
export const UNSET_PROVINCE_LIST = 'UNSET_PROVINCE_LIST';

export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST';
export const UNSET_PRODUCT_LIST = 'UNSET_PRODUCT_LIST';

export const SET_REFERAL_TYPE_LIST = 'SET_REFERAL_TYPE_LIST';
export const UNSET_REFERAL_TYPE_LIST = 'UNSET_REFERAL_TYPE_LIST';

export const SET_RETAILER_LIST = 'SET_RETAILER_LIST';
export const UNSET_RETAILER_LIST = 'UNSET_RETAILER_LIST';

export const SET_STORE_AMOUNT_LIST = 'SET_STORE_AMOUNT_LIST';
export const UNSET_STORE_AMOUNT_LIST = 'UNSET_STORE_AMOUNT_LIST';

export const SET_STORE_TYPE_LIST = 'SET_STORE_TYPE_LIST';
export const UNSET_STORE_TYPE_LIST = 'UNSET_STORE_TYPE_LIST';

export const SET_STOKVEL_LIST = 'SET_STOKVEL_LIST';
export const UNSET_STOKVEL_LIST = 'UNSET_STOKVEL_LIST';

export const SET_TESTIMONIALS_LIST = 'SET_TESTIMONIALS_LIST';
export const UNSET_TESTIMONIALS_LIST = 'UNSET_TESTIMONIALS_LIST';

export const SET_TESTIMONIALS_ACTIVE_LIST = 'SET_TESTIMONIALS_ACTIVE_LIST';
export const UNSET_TESTIMONIALS_ACTIVE_LIST = 'UNSET_TESTIMONIALS_ACTIVE_LIST';

export const UNSET_TESTIMONIALS_FEATURED_LIST =
    'UNSET_TESTIMONIALS_FEATURED_LIST';
export const SET_TESTIMONIALS_FEATURED_LIST = 'SET_TESTIMONIALS_FEATURED_LIST';

export const SET_TILL_SLIP_ITEM = 'SET_TILL_SLIP_ITEM';
export const UNSET_TILL_SLIP_ITEM = 'UNSET_TILL_SLIP_ITEM';

export const SET_TILL_SLIP_LIST = 'SET_TILL_SLIP_LIST';
export const UNSET_TILL_SLIP_LIST = 'UNSET_TILL_SLIP_LIST';

export const SET_TILL_SLIP_ITEMS_ARRAY_LIST = 'SET_TILL_SLIP_ITEMS_ARRAY_LIST';
export const UNSET_TILL_SLIP_ITEMS_ARRAY_LIST =
    'UNSET_TILL_SLIP_ITEMS_ARRAY_LIST';

export const SET_TRADER_LIST = 'SET_TRADER_LIST';
export const UNSET_TRADER_LIST = 'UNSET_TRADER_LIST';

export const SET_TRADER_BUNDLE_PROMOTION_LIST =
    'SET_TRADER_BUNDLE_PROMOTION_LIST';
export const UNSET_TRADER_BUNDLE_PROMOTION_LIST =
    'UNSET_TRADER_BUNDLE_PROMOTION_LIST';

export const SET_USER_LIST = 'SET_USER_LIST';
export const UNSET_USER_LIST = 'UNSET_USER_LIST';

// Register
export const SET_STOKVEL_REGISTER = 'SET_STOKVEL_REGISTER';
export const UNSET_STOKVEL_REGISTER = 'UNSET_STOKVEL_REGISTER';

export const SET_TRADER_REGISTER = 'SET_TRADER_REGISTER';
export const UNSET_TRADER_REGISTER = 'UNSET_TRADER_REGISTER';

// Individual States
export const SET_PRODUCT = 'SET_PRODUCT';
export const UNSET_PRODUCT = 'UNSET_PRODUCT';

export const SET_STOKVEL = 'SET_STOKVEL';
export const UNSET_STOKVEL = 'UNSET_STOKVEL';

export const SET_TESTIMONIAL = 'SET_TESTIMONIAL';
export const UNSET_TESTIMONIAL = 'UNSET_TESTIMONIAL';

export const SET_TILL_SLIP = 'SET_TILL_SLIP';
export const UNSET_TILL_SLIP = 'UNSET_TILL_SLIP';

export const SET_TRADER = 'SET_TRADER';
export const UNSET_TRADER = 'UNSET_TRADER';

export const SET_TRADER_BUNDLE_PROMOTION = 'SET_TRADER_BUNDLE_PROMOTION';
export const UNSET_TRADER_BUNDLE_PROMOTION = 'UNSET_TRADER_BUNDLE_PROMOTION';

// User
export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';

export const SET_USER_STOKVEL = 'SET_USER_STOKVEL';
export const UNSET_USER_STOKVEL = 'UNSET_USER_STOKVEL';

export const SET_USER_TRADER = 'SET_USER_TRADER';
export const UNSET_USER_TRADER = 'UNSET_USER_TRADER';
