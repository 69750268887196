// Packages
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Navbar from '../../../components/Navbar/AdminNavbar';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Routes
import {
    CMS_AIRTIME_CREATE,
    CMS_MYSTORE_ARTICLES_INDEX,
    CMS_MYSTORE_MAGAZINES_INDEX,
    CMS_PRINTADTYPES_INDEX,
    CMS_PRINTADS_INDEX,
    CMS_PRODUCT_INDEX,
    CMS_PRODUCT_HIGHLIGHTS_INDEX,
    CMS_STOKVEL_INDEX,
    CMS_SYSTEM_OVERVIEW,
    CMS_TESTIMONIAL_INDEX,
    CMS_TILLSLIP_INDEX,
    CMS_TRADER_INDEX,
    CMS_USER_INDEX,
    CMS_TRADER_BUNDLE_PROMOTION_INDEX,
    CMS_SMS_CREATE,
} from '../../../routes';

export default function CmsDashboard() {
    const actionList = [
        {
            icon: 'fas fa-stack-1x fa-inverse fa-database',
            link: CMS_SYSTEM_OVERVIEW,
            title: 'System Overview',
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-newspaper',
            link: CMS_MYSTORE_ARTICLES_INDEX,
            title: 'MyStore Articles (coming soon)',
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-book',
            link: CMS_MYSTORE_MAGAZINES_INDEX,
            title: 'MyStore Magazines (coming soon)',
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-ad',
            link: CMS_PRINTADTYPES_INDEX,
            title: 'Print Ad Types',
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-ad',
            link: CMS_PRINTADS_INDEX,
            title: 'Print Ads (coming soon)',
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-shopping-cart',
            link: CMS_PRODUCT_INDEX,
            title: 'Products',
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-highlighter',
            link: CMS_PRODUCT_HIGHLIGHTS_INDEX,
            title: 'Product Highlights (coming soon)',
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-mobile',
            link: CMS_AIRTIME_CREATE,
            title: 'Send Airtime',
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-sms',
            link: CMS_SMS_CREATE,
            title: 'Send SMS',
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-clipboard',
            link: CMS_STOKVEL_INDEX,
            title: 'Stokvels',
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-quote-right',
            link: CMS_TESTIMONIAL_INDEX,
            title: 'Testimonials',
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-shopping-bag',
            link: CMS_TILLSLIP_INDEX,
            title: 'Till Slips',
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-store-alt',
            link: CMS_TRADER_INDEX,
            title: 'Traders',
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-money-bill-alt',
            link: CMS_TRADER_BUNDLE_PROMOTION_INDEX,
            title: "Trader's Bundles and Promotions",
        },
        {
            icon: 'fas fa-stack-1x fa-inverse fa-users',
            link: CMS_USER_INDEX,
            title: 'Users',
        },
    ];

    return (
        <>
            <Helmet>
                <title>Admin Dashboard | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="admin-dashboard" className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <Title title="Admin Dashboard" />
                    </div>
                </div>

                <div className="row">
                    {actionList.map((item, index) => {
                        return (
                            <div className="col-12 col-lg-3 mb-5" key={index}>
                                <a
                                    href={item.link}
                                    className="fw-bold text-decoration-none"
                                >
                                    <div className="bg-grey-light p-5 text-center h-100">
                                        <div className="mb-3">
                                            <span className="fa-stack fa-2x">
                                                <i className="fas fa-circle fa-stack-2x"></i>
                                                <i className={item.icon}></i>
                                            </span>
                                        </div>
                                        {item.title}
                                    </div>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </section>
        </>
    );
}
