// Packages
import React from 'react';

export default function NoIcon() {
    return (
        <>
            <i className="fa-fw fas fa-times text-danger"></i>
        </>
    );
}
