// Packages
import React from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';

// Components
import Hero from '../../../../components/Hero';
import Navbar from '../../../../components/Navbar';

// Images and PDFs
import HeroDesktop from '../../../../assets/img/page-banners/banner-print-ads-desktop.png';
import HeroMobile from '../../../../assets/img/page-banners/banner-print-ads-mobile.png';

// Misc
import Ad01Image from '../../../../assets/img/print-ads/ad-01.png';
import Ad01PDF from '../../../../assets/pdf/print-ads/ad-01.pdf';
import Ad02Image from '../../../../assets/img/print-ads/ad-02.png';
import Ad02PDF from '../../../../assets/pdf/print-ads/ad-02.pdf';

// Home Care
import SunlightImage from '../../../../assets/img/print-ads/sunlight.png';
import SunlightPDF from '../../../../assets/pdf/print-ads/sunlight.pdf';
import HandyAndyImage from '../../../../assets/img/print-ads/handy-andy-domestos.png';
import HandyAndyPDF from '../../../../assets/pdf/print-ads/handy-andy-domestos.jpg';
import Surf01Image from '../../../../assets/img/print-ads/surf-01.png';
import Surf01PDF from '../../../../assets/pdf/print-ads/surf-01.jpg';
import Surf02Image from '../../../../assets/img/print-ads/surf-02.png';
import Surf02PDF from '../../../../assets/pdf/print-ads/surf-02.jpg';

// Food
import KnorroxImage from '../../../../assets/img/print-ads/knorrox-range.png';
import KnorroxPDF from '../../../../assets/pdf/print-ads/knorrox-range.pdf';
import KnorroxAromatImage from '../../../../assets/img/print-ads/knorrox-aromat.png';
import KnorroxAromatPDF from '../../../../assets/pdf/print-ads/knorrox-aromat.pdf';
import KnorrImage from '../../../../assets/img/print-ads/knorr.png';
import KnorrPDF from '../../../../assets/pdf/print-ads/knorr.jpg';
import RobertsonsImage from '../../../../assets/img/print-ads/robertsons.png';
import RobertsonsPDF from '../../../../assets/pdf/print-ads/robertsons.jpg';
import RobertsonsAromatImage from '../../../../assets/img/print-ads/robertsons-aromat.png';
import RobertsonsAromatPDF from '../../../../assets/pdf/print-ads/robertsons-aromat.jpg';

// Personal Care
import DawnImage from '../../../../assets/img/print-ads/dawn.png';
import DawnPDF from '../../../../assets/pdf/print-ads/dawn.pdf';
import LifebuoyImage from '../../../../assets/img/print-ads/lifebuoy.png';
import LifebuoyPDF from '../../../../assets/pdf/print-ads/lifebuoy.pdf';
import ShieldImage from '../../../../assets/img/print-ads/shield.png';
import ShieldPDF from '../../../../assets/pdf/print-ads/shield.pdf';
import LuxImage from '../../../../assets/img/print-ads/lux-sunlight.png';
import LuxPDF from '../../../../assets/pdf/print-ads/lux-sunlight.pdf';
import PondsImage from '../../../../assets/img/print-ads/ponds.png';
import PondsPDF from '../../../../assets/pdf/print-ads/ponds.jpg';
import LifebuoyShieldImage from '../../../../assets/img/print-ads/lifebuoy-shield.png';
import LifebuoyShieldPDF from '../../../../assets/pdf/print-ads/lifebuoy-shield.jpg';
import VaselineImage from '../../../../assets/img/print-ads/vaseline.png';
import VaselinePDF from '../../../../assets/pdf/print-ads/vaseline.pdf';

const Misc = [
    {
        image: Ad01Image,
        pdf: Ad01PDF,
    },
    {
        image: Ad02Image,
        pdf: Ad02PDF,
    },
];

const HomeCare = [
    {
        image: SunlightImage,
        pdf: SunlightPDF,
    },
    {
        image: HandyAndyImage,
        pdf: HandyAndyPDF,
    },
    {
        image: Surf01Image,
        pdf: Surf01PDF,
    },
    {
        image: Surf02Image,
        pdf: Surf02PDF,
    },
];

const Food = [
    {
        image: KnorroxImage,
        pdf: KnorroxPDF,
    },
    {
        image: KnorroxAromatImage,
        pdf: KnorroxAromatPDF,
    },
    {
        image: KnorrImage,
        pdf: KnorrPDF,
    },
    {
        image: RobertsonsImage,
        pdf: RobertsonsPDF,
    },
    {
        image: RobertsonsAromatImage,
        pdf: RobertsonsAromatPDF,
    },
];

const PersonalCare = [
    {
        image: DawnImage,
        pdf: DawnPDF,
    },
    {
        image: LifebuoyImage,
        pdf: LifebuoyPDF,
    },
    {
        image: ShieldImage,
        pdf: ShieldPDF,
    },
    {
        image: LuxImage,
        pdf: LuxPDF,
    },
    {
        image: PondsImage,
        pdf: PondsPDF,
    },
    {
        image: LifebuoyShieldImage,
        pdf: LifebuoyShieldPDF,
    },
    {
        image: VaselineImage,
        pdf: VaselinePDF,
    },
];

// Slider
const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    lazyLoad: true,
    infinite: false,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

export default function PrintAdsPage() {
    return (
        <>
            <Helmet>
                <title>Unilever Print Ads | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <section id="print">
                <Hero
                    title="Unilever Print Ads"
                    heroMobile={HeroMobile}
                    heroDesktop={HeroDesktop}
                />

                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className="mb-3">Unilever Print Ads</h1>

                            <Slider {...settings}>
                                {Misc.map((item, index) => {
                                    return (
                                        <div className="slide-item" key={index}>
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center mb-3">
                                                <img
                                                    className="img-fluid"
                                                    src={item.image}
                                                    alt=""
                                                />
                                            </div>

                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={item.pdf}
                                                className="small text-decoration-underline"
                                            >
                                                View / Download
                                            </a>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="bg-grey-light py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>Home Care</h2>

                                <Slider {...settings}>
                                    {HomeCare.map((item, index) => {
                                        return (
                                            <div
                                                className="slide-item"
                                                key={index}
                                            >
                                                <div className="bg-white shadow-all d-flex justify-content-center align-items-center mb-3">
                                                    <img
                                                        className="img-fluid"
                                                        src={item.image}
                                                        alt=""
                                                    />
                                                </div>

                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={item.pdf}
                                                    className="small text-decoration-underline"
                                                >
                                                    View / Download
                                                </a>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2>Foods</h2>

                            <Slider {...settings}>
                                {Food.map((item, index) => {
                                    return (
                                        <div className="slide-item" key={index}>
                                            <div className="bg-white shadow-all d-flex justify-content-center align-items-center mb-3">
                                                <img
                                                    className="img-fluid"
                                                    src={item.image}
                                                    alt=""
                                                />
                                            </div>

                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={item.pdf}
                                                className="small text-decoration-underline"
                                            >
                                                View / Download
                                            </a>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="bg-grey-light py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>Personal Care</h2>

                                <Slider {...settings}>
                                    {PersonalCare.map((item, index) => {
                                        return (
                                            <div
                                                className="slide-item"
                                                key={index}
                                            >
                                                <div className="bg-white shadow-all d-flex justify-content-center align-items-center mb-3">
                                                    <img
                                                        className="img-fluid"
                                                        src={item.image}
                                                        alt=""
                                                    />
                                                </div>

                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={item.pdf}
                                                    className="small text-decoration-underline"
                                                >
                                                    View / Download
                                                </a>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
