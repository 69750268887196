// Packages
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Hero from '../../../components/Hero';
import Navbar from '../../../components/Navbar';

// Images
import HeroDesktop from '../../../assets/img/page-banners/banner-trader-register-desktop.png';
import HeroMobile from '../../../assets/img/page-banners/banner-trader-register-mobile.png';

export default function TraderRegisterLayout(props) {
    return (
        <>
            <Helmet>
                <title>Register | Unilever Shopper</title>
                <body className="trader-app" />
            </Helmet>

            <Navbar />

            <section id="trader-register">
                <Hero
                    title="Create your account"
                    heroMobile={HeroMobile}
                    heroDesktop={HeroDesktop}
                />

                <div className="container pt-5">
                    <div className="row">
                        <div className="col-12 col-lg-6 offset-lg-3">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <span
                                        id="process-name"
                                        className="fw-bold "
                                    >
                                        {props.stepName}
                                    </span>
                                </div>
                                <div className="col-12 col-lg-6 text-end">
                                    <span
                                        id="process-step"
                                        className="fw-bold "
                                    >
                                        Step {props.stepNumber} of 4
                                    </span>
                                </div>
                            </div>

                            <div className="progress border-radius-20 mt-20 mb-50">
                                <div
                                    id="process-bar"
                                    className={
                                        'progress-bar bg-blue-dark ' +
                                        props.stepProgress
                                    }
                                    role="progressbar"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>

                            {props.children}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
