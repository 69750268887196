// Packages
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

// Helpers
import { retrieveToken } from './retrieveToken';
import Api from './Api';

// Routes
import { STOKVEL_LOGIN, PORTAL_DASHBOARD } from '../routes';

export default function AdminRoute({ Component }) {
    const accessToken = retrieveToken();

    const [isAdminUser, setIsAdminUser] = useState(false);
    const [isBusyLoading, setIsBusyLoading] = useState(true);

    useEffect(() => {
        Api.get('/users/me')
            .then(function (response) {
                if (
                    response.data.success &&
                    response.data.data.user.user_is_admin === '1'
                ) {
                    setIsAdminUser(true);
                } else {
                    setIsAdminUser(false);
                }

                setIsBusyLoading(false);
            })
            .catch(function (error) {
                setIsAdminUser(false);
                setIsBusyLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    if (!accessToken) {
        return <Redirect to={STOKVEL_LOGIN} />;
    }

    if (!isBusyLoading && !isAdminUser) {
        return <Redirect to={PORTAL_DASHBOARD} />;
    }

    if (!isBusyLoading && isAdminUser) {
        return <Component />;
    }

    return null;
}
