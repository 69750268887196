// Packages
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Hero from '../../../../components/Hero';
import Navbar from '../../../../components/Navbar';

// Images
import HeroDesktop from '../../../../assets/img/page-banners/banner-leaderboard-desktop.png';
import HeroMobile from '../../../../assets/img/page-banners/banner-leaderboard-mobile.png';
import BackToSchool2020Image from '../../../../assets/img/campaigns/2020/cashback/cashback-2020-leaderboard.png';
import WomensDay2020Image from '../../../../assets/img/campaigns/2020/womens-day/wheel-week-04-desktop.gif';
import EndOfYear2019FirstImage from '../../../../assets/img/campaigns/2019/end-year-campaign/winner-icon.png';
import EndOfYear2019SecondImage from '../../../../assets/img/campaigns/2019/end-year-campaign/second-icon.png';
import EndOfYear2019ThirdImage from '../../../../assets/img/campaigns/2019/end-year-campaign/third-icon.png';
import EndOfYear2019Progress1Image from '../../../../assets/img/campaigns/2019/end-year-campaign/goal-progress-01.png';
import EndOfYear2019Progress2Image from '../../../../assets/img/campaigns/2019/end-year-campaign/goal-progress-02.png';
import EndOfYear2019Progress3Image from '../../../../assets/img/campaigns/2019/end-year-campaign/goal-progress-03.png';
import EndOfYear2019Progress4Image from '../../../../assets/img/campaigns/2019/end-year-campaign/goal-progress-04.png';
import EndOfYear2019Progress5Image from '../../../../assets/img/campaigns/2019/end-year-campaign/goal-progress-05.png';
import EndOfYear2019Progress6Image from '../../../../assets/img/campaigns/2019/end-year-campaign/goal-progress-06.png';
import EndOfYear2019Progress7Image from '../../../../assets/img/campaigns/2019/end-year-campaign/goal-progress-07.png';
import EndOfYear2019Progress8Image from '../../../../assets/img/campaigns/2019/end-year-campaign/goal-progress-08.png';
import EndOfYear2019Progress9Image from '../../../../assets/img/campaigns/2019/end-year-campaign/goal-progress-09.png';
import EndOfYear2019Progress10Image from '../../../../assets/img/campaigns/2019/end-year-campaign/goal-progress-10.png';

export default function LeaderboardPage() {
    return (
        <>
            <Helmet>
                <title>Leaderboard | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <section id="leaderboard">
                <Hero
                    title="Leaderboard"
                    heroMobile={HeroMobile}
                    heroDesktop={HeroDesktop}
                />

                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className="mb-3">Leaderboard</h1>
                            <h2>2020 End Year Campaign Leaderboard</h2>
                        </div>

                        <div className="col-12 col-lg-8 offset-lg-2 mb-5">
                            <div className="row">
                                <div className="col-3 d-flex align-items-center justify-content-center">
                                    <img
                                        className="img-fluid"
                                        src={EndOfYear2019SecondImage}
                                        alt="2nd Place"
                                    />
                                </div>

                                <div className="col-6 d-flex align-items-center justify-content-center">
                                    <img
                                        className="img-fluid"
                                        src={EndOfYear2019FirstImage}
                                        alt="Winner"
                                    />
                                </div>

                                <div className="col-3 d-flex align-items-center justify-content-center">
                                    <img
                                        className="img-fluid"
                                        src={EndOfYear2019ThirdImage}
                                        alt="Third Place"
                                    />
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col-lg-3 d-none d-lg-block">
                                    <p className="mb-0 fw-bold text-center">
                                        Club Eagle Social
                                    </p>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <p className="mb-0 fw-bold text-center">
                                        Success Stokvel
                                    </p>
                                </div>

                                <div className="col-lg-3 d-none d-lg-block">
                                    <p className="mb-0 fw-bold text-center">
                                        Bomme Ba Thare
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-8 offset-lg-2 shadow-all py-3 p-lg-3 text-center">
                            <h3 className=" text-uppercase">
                                Leaderboard Breakdown
                            </h3>

                            <div className="row py-3">
                                <div className="col-12 col-lg-6 text-center text-lg-start">
                                    <p className="fw-bold mb-0">Goal:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-center text-lg-end">
                                    <p className="fw-bold mb-0">R10 000.00</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-6 d-none d-lg-flex text-center text-lg-start">
                                    <p className="fw-bold">Stokvel:</p>
                                </div>

                                <div className="col-12 col-lg-6 d-none d-lg-flex text-center text-lg-end">
                                    <p className="fw-bold">Goal progress:</p>
                                </div>
                            </div>

                            <div className="row pt-3 p-lg-0 mobile-bg-white">
                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Stokvel:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-start">
                                    <p>1. Success Stokvel</p>
                                </div>

                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Goal progress:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-end">
                                    <p>
                                        <img
                                            className="img-fluid"
                                            src={EndOfYear2019Progress1Image}
                                            alt=""
                                        />
                                    </p>
                                </div>
                            </div>

                            <div className="row pt-3 p-lg-0">
                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Stokvel:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-start">
                                    <p>2. Club Eagle Social</p>
                                </div>

                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Goal progress:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-end">
                                    <p>
                                        <img
                                            className="img-fluid"
                                            src={EndOfYear2019Progress2Image}
                                            alt=""
                                        />
                                    </p>
                                </div>
                            </div>

                            <div className="row pt-3 p-lg-0 mobile-bg-white">
                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Stokvel:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-start">
                                    <p>3. Bomme Ba Thare</p>
                                </div>

                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Goal progress:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-end">
                                    <p>
                                        <img
                                            className="img-fluid"
                                            src={EndOfYear2019Progress3Image}
                                            alt=""
                                        />
                                    </p>
                                </div>
                            </div>

                            <div className="row pt-3 p-lg-0">
                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Stokvel:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-start">
                                    <p>4. Pretoria Groceries Club</p>
                                </div>

                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Goal progress:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-end">
                                    <p>
                                        <img
                                            className="img-fluid"
                                            src={EndOfYear2019Progress4Image}
                                            alt=""
                                        />
                                    </p>
                                </div>
                            </div>

                            <div className="row pt-3 p-lg-0 mobile-bg-white">
                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Stokvel:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-start">
                                    <p>5. Sharing Queens</p>
                                </div>

                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Goal progress:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-end">
                                    <p>
                                        <img
                                            className="img-fluid"
                                            src={EndOfYear2019Progress5Image}
                                            alt=""
                                        />
                                    </p>
                                </div>
                            </div>

                            <div className="row pt-3 p-lg-0">
                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Stokvel:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-start">
                                    <p>6. Phuthanang</p>
                                </div>

                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Goal progress:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-end">
                                    <p>
                                        <img
                                            className="img-fluid"
                                            src={EndOfYear2019Progress6Image}
                                            alt=""
                                        />
                                    </p>
                                </div>
                            </div>

                            <div className="row pt-3 p-lg-0 mobile-bg-white">
                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Stokvel:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-start">
                                    <p>7. Sebenza Ladies</p>
                                </div>

                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Goal progress:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-end">
                                    <p>
                                        <img
                                            className="img-fluid"
                                            src={EndOfYear2019Progress7Image}
                                            alt=""
                                        />
                                    </p>
                                </div>
                            </div>

                            <div className="row pt-3 p-lg-0">
                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Stokvel:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-start">
                                    <p>8. Ladies Club 707</p>
                                </div>

                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Goal progress:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-end">
                                    <p>
                                        <img
                                            className="img-fluid"
                                            src={EndOfYear2019Progress8Image}
                                            alt=""
                                        />
                                    </p>
                                </div>
                            </div>

                            <div className="row pt-3 p-lg-0 mobile-bg-white">
                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Stokvel:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-start">
                                    <p>9. Guitar Ladies</p>
                                </div>

                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Goal progress:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-end">
                                    <p>
                                        <img
                                            className="img-fluid"
                                            src={EndOfYear2019Progress9Image}
                                            alt=""
                                        />
                                    </p>
                                </div>
                            </div>

                            <div className="row pt-3 p-lg-0">
                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Stokvel:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-start">
                                    <p className="mb-lg-0">
                                        10. Melethi Ladies Club
                                    </p>
                                </div>

                                <div className="col-12 d-lg-none">
                                    <p className="fw-bold">Goal progress:</p>
                                </div>

                                <div className="col-12 col-lg-6 text-lg-end">
                                    <p className="mb-0">
                                        <img
                                            className="img-fluid"
                                            src={EndOfYear2019Progress10Image}
                                            alt=""
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-grey-light py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>Back To School Competition Winner</h2>

                                <img
                                    src={BackToSchool2020Image}
                                    alt="Back To School Competition Winner"
                                    className="img-fluid d-inline-block"
                                />

                                <h3 className="h4 text-uppercase">
                                    Congratulations to our Winner
                                </h3>

                                <p className="mb-0">Siyazama Stokvel</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 px-3">
                            <h2 className=" text-center">
                                Women's Month Competition
                            </h2>

                            <div className="text-center">
                                <img
                                    src={WomensDay2020Image}
                                    alt="Week 4 Winners' Wheel"
                                    className="img-fluid d-inline-block mb-5"
                                />
                            </div>

                            <h3 className="h4  text-center text-uppercase">
                                Congratulations to our Winners
                            </h3>

                            <div className="row text-start">
                                <div className="col-12 col-lg-3 py-3 py-lg-0 mobile-bg-grey-light">
                                    <h4 className="h5 text-center">Week 1:</h4>
                                    <ol className="mb-0">
                                        <li>Mpumelelo</li>
                                        <li>Masibuyisane</li>
                                        <li>Reyakgona</li>
                                        <li>Jabulile Woman's Club</li>
                                        <li>New Look Grocery</li>
                                    </ol>
                                </div>

                                <div className="col-12 col-lg-3 py-3 py-lg-0">
                                    <h4 className="h5 text-center">Week 2:</h4>
                                    <ol className="mb-0">
                                        <li>Wisani</li>
                                        <li>Thusanang Stokvel</li>
                                        <li>Basadi Babantle</li>
                                        <li>Masakhane Ladies</li>
                                        <li>Mazidlekhaya</li>
                                    </ol>
                                </div>

                                <div className="col-12 col-lg-3 py-3 py-lg-0 mobile-bg-grey-light">
                                    <h4 className="h5 text-center">Week 3:</h4>
                                    <ol className="mb-0">
                                        <li>Madodana</li>
                                        <li>Sbonelo Sembokodo</li>
                                        <li>TryAgain</li>
                                        <li>Helping Hand</li>
                                        <li>Uthandolwethu</li>
                                    </ol>
                                </div>

                                <div className="col-12 col-lg-3 pt-3 pt-lg-0">
                                    <h4 className="h5 text-center">Week 4:</h4>
                                    <ol className="mb-0">
                                        <li>Botshepengi Social Club</li>
                                        <li>Senzakahle</li>
                                        <li>Gogo's</li>
                                        <li>DRDRL Grocery Scheme</li>
                                        <li>Beautiful Ladies</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
