// Packages
import React from 'react';

export default function YesIcon() {
    return (
        <>
            <i className="fa-fw fas fa-check text-success"></i>
        </>
    );
}
