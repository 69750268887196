// Packages
import React from 'react';

export default function ResponseMessage(props) {
    if (props.status !== null && props.status !== '') {
        if (props.status === 'error') {
            return (
                <div className="alert alert-danger mb-3" role="alert">
                    {props.message}
                </div>
            );
        }

        if (props.status === 'success') {
            return (
                <div className="alert alert-success mb-3" role="alert">
                    {props.message}
                </div>
            );
        }
    } else {
        return null;
    }
}
