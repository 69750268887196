import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// CSS
import './resources/sass/app.scss';

// Redux
import { store, persistor } from './store/store';

// React
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';

// Routes
import {
    // Auth - Stokvel
    STOKVEL_REGISTER,
    STOKVEL_REGISTER_2,
    STOKVEL_REGISTER_3,
    STOKVEL_REGISTER_4,
    STOKVEL_LOGIN,
    STOKVEL_FORGOT_PASSWORD,

    // Auth - Trader
    TRADER_REGISTER,
    TRADER_REGISTER_2,
    TRADER_REGISTER_3,
    TRADER_REGISTER_4,
    TRADER_LOGIN,
    TRADER_FORGOT_PASSWORD,

    // Frontend
    HOME,
    CONTACT,
    TERMS_AND_CONDITIONS,

    // Frontend - Stokvel
    LEADERBOARD,
    PRODUCT_HIGHLIGHTS,
    TESTIMONIALS,
    PRINT_ADS,

    // Frontend - Trader
    TRADER_BUNDLES_AND_PROMOTIONS,
    MYSTORE_INDEX,
    MYSTORE_CREATE,
    MYSTORE_ARTICLE1,
    MYSTORE_ARTICLE2,

    // Portal
    PORTAL_DASHBOARD,
    PORTAL_REFER_A_FRIEND,
    PORTAL_USER_PROFILE_EDIT,
    PORTAL_USER_PASSWORD_EDIT,
    PORTAL_TILLSLIP_UPLOAD,
    PORTAL_STOKVEL_CREATE,
    PORTAL_STOKVEL_EDIT,
    PORTAL_STOKVEL_SHOW,
    PORTAL_TRADER_CREATE,
    PORTAL_TRADER_EDIT,
    PORTAL_TRADER_SHOW,

    // CMS
    CMS_DASHBOARD,
    CMS_SYSTEM_OVERVIEW,
    CMS_AIRTIME_CREATE,
    CMS_PRINTADTYPES_CREATE,
    CMS_PRINTADTYPES_EDIT,
    CMS_PRINTADTYPES_INDEX,
    CMS_PRODUCT_INDEX,
    CMS_PRODUCT_CREATE,
    CMS_PRODUCT_EDIT,
    CMS_STOKVEL_INDEX,
    CMS_STOKVEL_EDIT,
    CMS_TESTIMONIAL_CREATE,
    CMS_TESTIMONIAL_INDEX,
    CMS_TESTIMONIAL_EDIT,
    CMS_TILLSLIP_INDEX,
    CMS_TILLSLIP_EDIT,
    CMS_TILLSLIPITEM_EDIT,
    CMS_TRADER_INDEX,
    CMS_TRADER_EDIT,
    CMS_USER_INDEX,
    CMS_USER_EDIT,

    // Errors
    ERROR_404,
    CMS_SMS_CREATE,
} from './routes';

// Components
import Covid from './components/Covid';
import Footer from './components/Footer';
import Loader from './components/Loader';

// Helpers
import ProtectedRoute from './helpers/ProtectedRoute';
import AdminRoute from './helpers/AdminRoute';

// Pages
import ContactPage from './pages/Frontend/ContactPage';
import HomePage from './pages/Frontend/HomePage';
import TermsAndConditionsPage from './pages/Frontend/TermsAndConditions';

// Pages - Stokvel
import LeaderboardPage from './pages/Frontend/Stokvel/LeaderboardPage';
import PrintAdsPage from './pages/Frontend/Stokvel/PrintAdsPage';
import ProductHighlightsPage from './pages/Frontend/Stokvel/ProductHighlightsPage';
import TestimonialsPage from './pages/Frontend/Stokvel/TestimonialsPage';

// Pages - Trader
import MyStorePage from './pages/Frontend/Trader/MyStorePage';
import MyStoreCreatePage from './pages/Frontend/Trader/MyStorePage/create';
import MyStoreArticle1Page from './pages/Frontend/Trader/MyStorePage/article1';
import MyStoreArticle2Page from './pages/Frontend/Trader/MyStorePage/article2';
import BundlesAndPromotions from './pages/Frontend/Trader/BundlesAndPromotions';

// Pages - Auth - Stokvel
import StokvelRegisterStep1 from './pages/Auth/Stokvel/StokvelRegisterStep1';
import StokvelRegisterStep2 from './pages/Auth/Stokvel/StokvelRegisterStep2';
import StokvelRegisterStep3 from './pages/Auth/Stokvel/StokvelRegisterStep3';
import StokvelRegisterStep4 from './pages/Auth/Stokvel/StokvelRegisterStep4';
import StokvelLogin from './pages/Auth/Stokvel/StokvelLogin';
import StokvelForgotPassword from './pages/Auth/Stokvel/StokvelForgotPassword';

// Pages - Auth - Trader
import TraderRegisterStep1 from './pages/Auth/Trader/TraderRegisterStep1';
import TraderRegisterStep2 from './pages/Auth/Trader/TraderRegisterStep2';
import TraderRegisterStep3 from './pages/Auth/Trader/TraderRegisterStep3';
import TraderRegisterStep4 from './pages/Auth/Trader/TraderRegisterStep4';
import TraderLogin from './pages/Auth/Trader/TraderLogin';
import TraderForgotPassword from './pages/Auth/Trader/TraderForgotPassword';

// Pages - Portal
import PortalDashboard from './pages/Portal/Dashboard';
import PortalReferAFriendCreate from './pages/Portal/ReferAFriend/create';
import PortalStokvelCreate from './pages/Portal/Stokvel/create';
import PortalStokvelShow from './pages/Portal/Stokvel/show';
import PortalStokvelEdit from './pages/Portal/Stokvel/edit';
import PortalTillSlipCreate from './pages/Portal/TillSlip/create';
import PortalTraderCreate from './pages/Portal/Trader/create';
import PortalTraderShow from './pages/Portal/Trader/show';
import PortalTraderEdit from './pages/Portal/Trader/edit';
import PortalUserUpdatePassword from './pages/Portal/User/update-password';
import PortalUserUpdateProfile from './pages/Portal/User/update-profile';

// Pages - CMS
import CmsDashboard from './pages/Cms/Dashboard';
import CmsSystemOverview from './pages/Cms/SystemOverview';
import CmsAirtimeCreate from './pages/Cms/Airtime/create';
import CmsPrintAdTypesCreate from './pages/Cms/PrintAdTypes/create';
import CmsPrintAdTypesEdit from './pages/Cms/PrintAdTypes/edit';
import CmsPrintAdTypesIndex from './pages/Cms/PrintAdTypes';
import CmsProductIndex from './pages/Cms/Product';
import CmsProductCreate from './pages/Cms/Product/create';
import CmsProductEdit from './pages/Cms/Product/edit';
import CmsSmsCreatePage from './pages/Cms/Sms/create';
import CmsStokvelIndex from './pages/Cms/Stokvel';
import CmsStokvelEdit from './pages/Cms/Stokvel/edit';
import CmsTestimonialCreate from './pages/Cms/Testimonial/create';
import CmsTestimonialEdit from './pages/Cms/Testimonial/edit';
import CmsTestimonialIndex from './pages/Cms/Testimonial';
import CmsTillSlipIndex from './pages/Cms/TillSlip';
import CmsTillSlipEdit from './pages/Cms/TillSlip/edit';
import CmsTillSlipItemEdit from './pages/Cms/TillSlipItem/edit';
import CmsTraderEdit from './pages/Cms/Trader/edit';
import CmsTraderIndex from './pages/Cms/Trader';
import CmsUserIndex from './pages/Cms/User';
import CmsUserEdit from './pages/Cms/User/edit';

// Pages - Catchall
import PageNotFoundPage from './pages/Errors/PageNotFoundPage';

// Browser History
const history = createBrowserHistory();

// Store
window.store = store;

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <Loader />

                <Covid />

                <Router history={history}>
                    <Switch>
                        {/* Frontend - Pages */}
                        <Route exact path={HOME} component={HomePage} />
                        <Route exact path={CONTACT} component={ContactPage} />
                        <Route
                            exact
                            path={TERMS_AND_CONDITIONS}
                            component={TermsAndConditionsPage}
                        />

                        {/* Frontend - Stokvel */}
                        <Route
                            exact
                            path={LEADERBOARD}
                            component={LeaderboardPage}
                        />
                        <Route
                            exact
                            path={PRINT_ADS}
                            component={PrintAdsPage}
                        />
                        <Route
                            exact
                            path={PRODUCT_HIGHLIGHTS}
                            component={ProductHighlightsPage}
                        />
                        <Route
                            exact
                            path={TESTIMONIALS}
                            component={TestimonialsPage}
                        />

                        {/* Frontend - Trader */}
                        <Route
                            exact
                            path={TRADER_BUNDLES_AND_PROMOTIONS}
                            component={BundlesAndPromotions}
                        />
                        <Route
                            exact
                            path={MYSTORE_INDEX}
                            component={MyStorePage}
                        />
                        <Route
                            exact
                            path={MYSTORE_CREATE}
                            component={MyStoreCreatePage}
                        />
                        <Route
                            exact
                            path={MYSTORE_ARTICLE1}
                            component={MyStoreArticle1Page}
                        />
                        <Route
                            exact
                            path={MYSTORE_ARTICLE2}
                            component={MyStoreArticle2Page}
                        />

                        {/* Frontend - Auth - Stokvel */}
                        <ProtectedRoute
                            exact
                            path={STOKVEL_REGISTER}
                            Component={StokvelRegisterStep1}
                            isGuest={true}
                        />
                        <ProtectedRoute
                            exact
                            path={STOKVEL_REGISTER_2}
                            Component={StokvelRegisterStep2}
                            isGuest={true}
                        />
                        <ProtectedRoute
                            exact
                            path={STOKVEL_REGISTER_3}
                            Component={StokvelRegisterStep3}
                            isGuest={true}
                        />
                        <ProtectedRoute
                            exact
                            path={STOKVEL_REGISTER_4}
                            Component={StokvelRegisterStep4}
                            isGuest={true}
                        />
                        <ProtectedRoute
                            exact
                            path={STOKVEL_LOGIN}
                            Component={StokvelLogin}
                            isGuest={true}
                        />
                        <ProtectedRoute
                            exact
                            path={STOKVEL_FORGOT_PASSWORD}
                            Component={StokvelForgotPassword}
                            isGuest={true}
                        />

                        {/* Frontend - Auth - Trader */}
                        <ProtectedRoute
                            exact
                            path={TRADER_REGISTER}
                            Component={TraderRegisterStep1}
                            isGuest={true}
                        />
                        <ProtectedRoute
                            exact
                            path={TRADER_REGISTER_2}
                            Component={TraderRegisterStep2}
                            isGuest={true}
                        />
                        <ProtectedRoute
                            exact
                            path={TRADER_REGISTER_3}
                            Component={TraderRegisterStep3}
                            isGuest={true}
                        />
                        <ProtectedRoute
                            exact
                            path={TRADER_REGISTER_4}
                            Component={TraderRegisterStep4}
                            isGuest={true}
                        />
                        <ProtectedRoute
                            exact
                            path={TRADER_LOGIN}
                            Component={TraderLogin}
                            isGuest={true}
                        />
                        <ProtectedRoute
                            exact
                            path={TRADER_FORGOT_PASSWORD}
                            Component={TraderForgotPassword}
                            isGuest={true}
                        />

                        {/* Backend - portal */}
                        <ProtectedRoute
                            exact
                            path={PORTAL_DASHBOARD}
                            Component={PortalDashboard}
                            isPrivate={true}
                        />
                        <ProtectedRoute
                            exact
                            path={PORTAL_REFER_A_FRIEND}
                            Component={PortalReferAFriendCreate}
                            isPrivate={true}
                        />
                        <ProtectedRoute
                            exact
                            path={PORTAL_STOKVEL_CREATE}
                            Component={PortalStokvelCreate}
                            isAdmin={true}
                        />
                        <ProtectedRoute
                            exact
                            path={PORTAL_STOKVEL_SHOW}
                            Component={PortalStokvelShow}
                            isPrivate={true}
                        />
                        <ProtectedRoute
                            exact
                            path={PORTAL_STOKVEL_EDIT}
                            Component={PortalStokvelEdit}
                            isPrivate={true}
                        />
                        <ProtectedRoute
                            exact
                            path={PORTAL_TILLSLIP_UPLOAD}
                            Component={PortalTillSlipCreate}
                            isPrivate={true}
                        />
                        <ProtectedRoute
                            exact
                            path={PORTAL_TRADER_CREATE}
                            Component={PortalTraderCreate}
                            isAdmin={true}
                        />
                        <ProtectedRoute
                            exact
                            path={PORTAL_TRADER_EDIT}
                            Component={PortalTraderEdit}
                            isPrivate={true}
                        />
                        <ProtectedRoute
                            exact
                            path={PORTAL_TRADER_SHOW}
                            Component={PortalTraderShow}
                            isPrivate={true}
                        />
                        <ProtectedRoute
                            exact
                            path={PORTAL_TRADER_EDIT}
                            Component={PortalTraderEdit}
                            isAdmin={true}
                        />
                        <ProtectedRoute
                            exact
                            path={PORTAL_USER_PASSWORD_EDIT}
                            Component={PortalUserUpdatePassword}
                            isPrivate={true}
                        />
                        <ProtectedRoute
                            exact
                            path={PORTAL_USER_PROFILE_EDIT}
                            Component={PortalUserUpdateProfile}
                            isPrivate={true}
                        />

                        {/* Backend - CMS */}
                        <AdminRoute
                            exact
                            path={CMS_DASHBOARD}
                            Component={CmsDashboard}
                        />
                        <AdminRoute
                            exact
                            path={CMS_SYSTEM_OVERVIEW}
                            Component={CmsSystemOverview}
                        />
                        <AdminRoute
                            exact
                            path={CMS_AIRTIME_CREATE}
                            Component={CmsAirtimeCreate}
                        />
                        <AdminRoute
                            exact
                            path={CMS_PRINTADTYPES_CREATE}
                            Component={CmsPrintAdTypesCreate}
                        />
                        <AdminRoute
                            exact
                            path={CMS_PRINTADTYPES_EDIT}
                            Component={CmsPrintAdTypesEdit}
                        />
                        <AdminRoute
                            exact
                            path={CMS_PRINTADTYPES_INDEX}
                            Component={CmsPrintAdTypesIndex}
                        />
                        <AdminRoute
                            exact
                            path={CMS_PRODUCT_INDEX}
                            Component={CmsProductIndex}
                        />
                        <AdminRoute
                            exact
                            path={CMS_PRODUCT_CREATE}
                            Component={CmsProductCreate}
                        />
                        <AdminRoute
                            exact
                            path={CMS_PRODUCT_EDIT}
                            Component={CmsProductEdit}
                        />
                        <AdminRoute
                            exect
                            path={CMS_SMS_CREATE}
                            Component={CmsSmsCreatePage}
                        />
                        <AdminRoute
                            exact
                            path={CMS_STOKVEL_INDEX}
                            Component={CmsStokvelIndex}
                        />
                        <AdminRoute
                            exact
                            path={CMS_STOKVEL_EDIT}
                            Component={CmsStokvelEdit}
                        />
                        <AdminRoute
                            exact
                            path={CMS_TESTIMONIAL_CREATE}
                            Component={CmsTestimonialCreate}
                        />
                        <AdminRoute
                            exact
                            path={CMS_TESTIMONIAL_EDIT}
                            Component={CmsTestimonialEdit}
                        />
                        <AdminRoute
                            exact
                            path={CMS_TESTIMONIAL_INDEX}
                            Component={CmsTestimonialIndex}
                        />
                        <AdminRoute
                            exact
                            path={CMS_TILLSLIP_INDEX}
                            Component={CmsTillSlipIndex}
                        />
                        <AdminRoute
                            exact
                            path={CMS_TILLSLIP_EDIT}
                            Component={CmsTillSlipEdit}
                        />
                        <AdminRoute
                            exact
                            path={CMS_TILLSLIPITEM_EDIT}
                            Component={CmsTillSlipItemEdit}
                        />
                        <AdminRoute
                            exact
                            path={CMS_TRADER_INDEX}
                            Component={CmsTraderIndex}
                        />
                        <AdminRoute
                            exact
                            path={CMS_TRADER_EDIT}
                            Component={CmsTraderEdit}
                        />
                        <AdminRoute
                            exact
                            path={CMS_USER_INDEX}
                            Component={CmsUserIndex}
                        />
                        <AdminRoute
                            exact
                            path={CMS_USER_EDIT}
                            Component={CmsUserEdit}
                        />

                        {/* Catchall - 404 Page */}
                        <Route
                            exact
                            path={ERROR_404}
                            component={PageNotFoundPage}
                        />
                        <Route path="*" component={PageNotFoundPage} />
                    </Switch>
                </Router>

                <Footer />
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
