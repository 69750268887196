// Packages
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Navbar from '../../../components/Navbar/AdminNavbar';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';
import Title from '../../../components/Title';

// Routes
import { CMS_DASHBOARD } from '../../../routes';

export default function SystemOverall() {
    return (
        <>
            <Helmet>
                <title>System Overview | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="system-overview" className="container mb-5">
                <div className="row">
                    <div className="col-12">
                        <Title title="System Overview" />

                        <h2 className="text-center">PowerBI</h2>
                        <div
                            className="iframe-container mb-5 border"
                            dangerouslySetInnerHTML={{
                                __html: '<iframe class="iframe-responsive" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=7a3703d4-1306-4b19-bd35-827457ee81dc&autoAuth=true&ctid=d52c9ea1-7c21-47b1-82a3-33a74b1f74b8&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtZC1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9" frameborder="0" allowFullScreen="true" title="Microsoft PowerBI"></iframe>',
                            }}
                        ></div>

                        <h2 className="text-center">Google Analytics</h2>
                        <div
                            className="iframe-container mb-5 border"
                            dangerouslySetInnerHTML={{
                                __html: '<iframe class="iframe-responsive" width="1200" height="600" src="https://datastudio.google.com/embed/reporting/1e52996e-cf8c-4a5e-b4d5-693fd7a2081b/page/P2C8B" frameborder="0" style="border:0" allowfullscreen></iframe>',
                            }}
                        ></div>

                        <div className="text-center">
                            <a
                                className="btn btn-wide btn-secondary"
                                href={CMS_DASHBOARD}
                            >
                                Back
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
