// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

// Components
import Navbar from '../../../components/Navbar/PortalNavbar';
import ResponseMessage from '../../../components/ResponseMessage';
import UnileverLogo from '../../../components/Logos/CmsUnileverLogo';

// Helpers
import Api from '../../../helpers/Api';

export default function CmsPrintAdTypesEdit() {
    // Redux
    const dispatch = useDispatch();

    // Local state
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [printadType, setPrintAdType] = useState([]);

    // Check URL
    const url = window.location.pathname;
    const urlParams = url.split('/');

    const { handleSubmit, register, reset, errors } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            printAdTypeName: '',
            printAdTypeIsActive: false,
        },
    });

    // Component Did Mount
    useEffect(() => {
        Api.get('printadtypes/' + urlParams[3] + '/edit')
            .then(function (response) {
                dispatch({
                    type: 'SET_PRINTADTYPE',
                    payload: response.data.data.printAdType,
                });
                setPrintAdType(response.data.data.printAdType);
            })
            .catch(function (error) {
                if (error.response) {
                    setResponseMessage('Unable to retrieve the form data.');
                    setResponseStatus('error');
                }
            });
        // eslint-disable-next-line
    }, [reset]);

    useEffect(() => {
        reset({
            printAdTypeName: printadType.print_ad_type_name ?? '',
            printAdTypeIsActive:
                printadType.print_ad_type_is_active === '1' ? true : false,
        });
        // eslint-disable-next-line
    }, [printadType, reset]);

    // Submit Form
    const onSubmit = (data) => {
        Api.get('/sanctum/csrf-cookie').then((response) => {
            Api.post('printadtypes/' + urlParams[3], {
                _method: 'put',
                print_ad_type_name: data.printAdTypeName,
                print_ad_type_is_active: data.printAdTypeIsActive,
            })
                .then(function (response) {
                    if (response.data.success) {
                        setResponseMessage(response.data.message);
                        setResponseStatus('success');
                    } else {
                        setResponseMessage(response.data.message);
                        setResponseStatus('error');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        setResponseMessage(error.response.data.message);
                        setResponseStatus('success');
                    }
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Edit Print Ad Type | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <UnileverLogo />

            <section id="stokvel-edit" className="container mb-5">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <ResponseMessage
                            message={responseMessage}
                            status={responseStatus}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label
                                    htmlFor="printAdTypeName"
                                    className="fw-bold form-label small"
                                >
                                    What is the print ad type name?{' '}
                                </label>

                                <input
                                    type="text"
                                    name="printAdTypeName"
                                    id="printAdTypeName"
                                    className="form-control"
                                    placeholder="John"
                                    ref={register({
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Name must be between 2 and 191 characters.',
                                        },
                                        maxLength: {
                                            value: 191,
                                            message:
                                                'Name must be between 2 and 191 characters.',
                                        },
                                    })}
                                />

                                {errors.stokvelName && (
                                    <div className="invalid-feedback">
                                        {errors.printAdTypeName.message}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <div className="form-check">
                                    <label
                                        htmlFor="printAdTypeIsActive"
                                        className="form-check-label"
                                    >
                                        <input
                                            type="checkbox"
                                            name="printAdTypeIsActive"
                                            id="printAdTypeIsActive"
                                            className="form-check-input"
                                            value={true}
                                            ref={register}
                                        />
                                        Is Active?
                                    </label>
                                </div>
                            </div>

                            <ResponseMessage
                                message={responseMessage}
                                status={responseStatus}
                            />

                            <div className="text-center">
                                <input
                                    type="submit"
                                    value="Update Print Ad Type"
                                    className="btn btn-wide btn-primary"
                                />

                                <a
                                    href={'/cms/printadtypes'}
                                    className="btn btn-wide btn-secondary ms-2 text-uppercase"
                                >
                                    Back
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
