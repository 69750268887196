// Packages
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Hero from '../../../components/Hero';
import Navbar from '../../../components/Navbar';

// Images
import HeroDesktop from '../../../assets/img/page-banners/banner-contact-desktop.png';
import HeroMobile from '../../../assets/img/page-banners/banner-contact-mobile.png';

// Routes
import { GOOGLE_MAP, UNILEVER_CONTACT } from '../../../routes';

export default function ContactPage() {
    return (
        <>
            <Helmet>
                <title>Contact Unilever | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <section id="home">
                <Hero
                    title="Contact Unilever"
                    heroMobile={HeroMobile}
                    heroDesktop={HeroDesktop}
                />

                <section className="container my-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className="mb-3">Contact Unilever</h1>
                        </div>
                        <div className="col-12 col-lg-3 offset-lg-3 text-center text-lg-start mb-5">
                            <h2 className="mb-4">Call Us</h2>
                            <p className="mb-1">We're here to help.</p>
                            <p className="h3 mb-2">
                                <a href="tel:+27315702000">031 570 2000</a>
                            </p>
                            <p className="mb-0 mt-2">
                                Mon to Fri 8:00am - 5:00pm
                            </p>
                        </div>

                        <div className="col-12 col-lg-3 text-center text-lg-start  mb-5">
                            <h2 className="mb-4">Write To Us</h2>
                            <p className="mb-0">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={GOOGLE_MAP}
                                >
                                    15 Nollsworth Crescent,
                                    <br />
                                    Umhlanga
                                    <br />
                                    Kwazulu-Natal
                                    <br />
                                    South Africa, 4051
                                </a>
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-8 offset-lg-2 text-center">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={UNILEVER_CONTACT}
                                className="btn btn-wide btn-primary"
                            >
                                Contact
                            </a>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}
