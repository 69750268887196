// Packages
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Navbar from '../../../components/Navbar';

export default function TermsAndConditionsPage() {
    return (
        <>
            <Helmet>
                <title>Terms and Conditions | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <section id="terms-and-conditions">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="mb-3 text-center">
                                2022 Q4 Cashback Campaign - Terms and Conditions
                            </h1>

                            <ol>
                                <li className="mb-2">
                                    The promoter is Unilever South Africa (Pty)
                                    Ltd ("the Promoter").
                                </li>
                                <li className="mb-2">
                                    The Cashback campaign is open to all South
                                    African Residents above the age of 18 who
                                    are in possession of a valid identity
                                    document or passport. The Cashback campaign,
                                    its agents and distributors accept no
                                    liability whatsoever for the misuse of the
                                    reward and or products, and are not liable
                                    for any personal loss, injury or death at
                                    Reward Partner featured venues and or by
                                    using the products, and cannot be held
                                    responsible for any dissatisfaction
                                    concerning quality or availability of
                                    featured venues and or products.
                                </li>
                                <li className="mb-2">
                                    The Cashback campaign is not open to any
                                    director, member, partner, employee, agent
                                    or consultant of the Promoter, the marketing
                                    service providers or any person who directly
                                    or indirectly controls or is controlled by
                                    these entities, including their spouses,
                                    life partners, business partners or
                                    immediate family members.
                                </li>
                                <li className="mb-2">
                                    Any participant under the age of 18 years
                                    must be fully assisted by his/her guardian,
                                    who approves of and consents to the
                                    participant's participation in the
                                    competition and the participant's
                                    receipt/possession of the prize.
                                </li>
                                <li className="mb-2">
                                    By participating in this competition
                                    participants agree to receive future
                                    marketing material from the brand/s on the
                                    program. However, the participant has the
                                    right to unsubscribe to receiving marketing
                                    material or request the promoter to delete
                                    their information by contacting the Promoter
                                    at 15 Nollsworth Crescent, Nollsworth Park,
                                    La Lucia, 4051 Att: Legal Department or
                                    opting out from any electronic marketing
                                    message.
                                </li>
                                <li className="mb-2">
                                    All personal information ('Personal Data')
                                    supplied by participants shall be processed
                                    in accordance with South African data
                                    protection legislation and the Promoter's
                                    Privacy Policy.
                                </li>
                                <li className="mb-2">
                                    The Promoter reserves the right, in its sole
                                    discretion and to the extent permitted by
                                    law, to amend these T&amp;C's at any time.
                                    The Promoter may notify The Participant of
                                    any significant changes, however there is no
                                    obligation on The Promoter to do so and it
                                    remains your responsibility to check these
                                    T&amp;C's regularly on the website and/or
                                    any other communications' medium applicable.
                                </li>
                                <li className="mb-2">
                                    The Promoter shall have the right to
                                    terminate the Program ("Termination")
                                    immediately and without notice for any
                                    reason, beyond its control, requiring this.
                                    In the event of such Termination, The
                                    Participant agree to waive any rights that
                                    The Participant may have in terms of this
                                    Program and acknowledge that The Participant
                                    will have no recourse against the Promoter,
                                    its agents and/or staff.
                                </li>
                                <li className="mb-2">
                                    These T&amp;C's constitute an agreement
                                    between The Promoter and the Participant
                                    ("The Participant").
                                </li>
                                <li className="mb-2">
                                    These T&amp;C's as well as the terms of use
                                    governing each of the specific
                                    rewards/prizes/ benefits ("Rewards") will
                                    apply to your participation/submission/entry
                                    ("Participation") into the campaign. Please
                                    note that these T&amp;C's do not replace the
                                    terms and conditions prescribed by each
                                    reward partner ("Reward Terms") and must be
                                    read in conjunction with such other Reward
                                    Terms. It is your responsibility to
                                    familiarise yourself with those Rewards
                                    Terms.
                                </li>
                                <li className="mb-2">
                                    By entering the Cashback campaign, The
                                    Participant confirms that they have read,
                                    understood, and agree to be bound by these
                                    T&amp;C's, as they may be amended from time
                                    to time. By continuing your Participation
                                    and use of the Rewards, after the Promoter
                                    has modified and/or amended these T&amp;C's,
                                    The Participant agrees to be bound by such
                                    modified T&amp;C's. If the modified
                                    T&amp;C's are not acceptable to The
                                    Participant, The Participant must terminate
                                    their Participation.
                                </li>
                                <li className="mb-2">
                                    The program is subject to program
                                    availability. Participating Reward Partner
                                    venues reserve the right to vary times and
                                    program availability (e.g. Public holidays).
                                </li>
                                <li className="mb-2">
                                    To the extent allowed by law, these
                                    T&amp;C's set out the whole of the agreement
                                    between The Participant and The Promoter in
                                    regard to the subject matter hereof and
                                    there are no other agreements, guarantees or
                                    representations, either verbal or in
                                    writing, in regard thereto upon which The
                                    Participant are relying in concluding this
                                    agreement and The Promoter shall not be
                                    bound by any express or implied term,
                                    representation, warranty, promise or the
                                    like not recorded herein. This clause
                                    constitutes an acknowledgment of fact by The
                                    Participant. The Participant must ensure
                                    that The Participant reads the above clause
                                    carefully and ensure that this statement is
                                    true and correct as this will limit your
                                    rights to claim that these statements are
                                    not true and correct.
                                </li>
                                <li className="mb-2">
                                    To the extent allowed by law, no extension
                                    of time or indulgence which The Promoter may
                                    grant to The Participant shall constitute a
                                    waiver of any of The Promoter's rights, who
                                    shall not thereby by precluded from
                                    exercising any rights against The
                                    Participant which may have arisen in the
                                    past or which might arise in the future.
                                    This clause limits and excludes obligations,
                                    liabilities, and legal responsibilities
                                    which The Promoter will have towards The
                                    Participant and also limits and excludes
                                    your rights and remedies and places various
                                    risks, liabilities, obligations and legal
                                    responsibilities on The Participant.
                                </li>
                                <li className="mb-2">
                                    These T&amp;C's shall be governed by and
                                    interpreted according to the laws of the
                                    Republic of South Africa and the parties
                                    consent to the non-exclusive jurisdiction of
                                    the High Court of South Africa, Western Cape
                                    Division, Cape Town, in respect of any
                                    proceedings arising out of or in connection
                                    with these T&amp;C's.
                                </li>
                                <li className="mb-2">
                                    Any provision in these T&amp;C's which is or
                                    may become illegal, invalid or unenforceable
                                    shall, to the extent of such prohibition or
                                    unenforceability, be ineffective and treated
                                    as having not been written and severed from
                                    the balance of these T&amp;C's, without
                                    invalidating the remaining provisions of
                                    these T&amp;C's.
                                </li>
                                <li className="mb-2">
                                    The Promoter and its agencies reserve the
                                    right to correct any errors and omissions.
                                    These terms and conditions will supersede
                                    any errors or omissions on any publicly
                                    communicated documentation or marketing
                                    material.
                                </li>
                                <li className="mb-2">
                                    The Participant agrees that The Promoter may
                                    send any notice or notification arising
                                    under and/or contemplated in these T&amp;C's
                                    by electronic mail or other electronic
                                    communication to your contact details
                                    provided when The Participant subscribed to
                                    The Promoter.
                                </li>
                                <li className="mb-2">
                                    The Participant are required to adhere to
                                    any South African Tax Laws linked to
                                    Rewards. It is your responsibility as a
                                    Participant to adhere and do the necessary
                                    submission is and when applicable.
                                </li>
                                <li className="mb-2">
                                    2022 UNILEVER CASH BACK CAMPAIGN ENTRY
                                    REQUIREMENTS FOR STOKVEL GROUPS
                                    <ol>
                                        <li className="mb-2">
                                            To enter the 2022 Unilever Shopper
                                            Cashback Campaign, Stokvel members
                                            will be required to sign up at
                                            www.unilevershopper.co.za if not
                                            already registered.
                                        </li>
                                        <li className="mb-2">
                                            Once registered, Stokvel members can
                                            upload their till slips, showing
                                            Unilever products that have been
                                            purchased, onto the Unilever Shopper
                                            website by taking a picture of their
                                            till slip and uploading it to their
                                            profile once signed in.
                                        </li>
                                        <li className="mb-2">
                                            Stokvels will earn a guaranteed
                                            R500.00 cashback for every R5000.00
                                            spent on Unilever products, during
                                            the campaign period of 17 November
                                            2022 – 31 January 2023. Example if a
                                            Stokvel group purchases Unilever
                                            product to the value of R5000.00
                                            they will qualify for R500.00
                                            cashback, if they purchase Unilever
                                            products to the value of R10 000.00
                                            they will qualify for R1000.00
                                            cashback.
                                        </li>
                                        <li className="mb-2">
                                            Stokvels will be informed about how
                                            much cashback they earn during the
                                            campaign period via SMS. They can
                                            also check their progress on the
                                            Unilever Shopper website once logged
                                            in.
                                        </li>
                                        <li className="mb-2">
                                            Points will only be earned on
                                            products purchased from the
                                            following Unilever brands: KNORR,
                                            KNORROX, ROBERTSONS, HELLMANN'S,
                                            CORNETTO, MAGNUM, OLA, PADDLE POP,
                                            JOKO, GLEN, LIPTON YELLOW LABEL,
                                            LIPTON, ORGANICS, TRESEMME,
                                            PEPSODENT, CLOSE UP, MENTADENT,
                                            IMPULSE, AXE, BRUT, SHIELD, DAWN,
                                            DOVE, SHIELD MEN, POND'S, VASELINE
                                            MEN, EVEN &amp; LOVELY, VASELINE,
                                            FISSAN, LIFEBUOY, LUX, SUNLIGHT,
                                            RADOX, COMFORT, OMO, SURF, SKIP,
                                            DOMESTOS, VIM, HANDY ANDY
                                        </li>
                                        <li className="mb-2">
                                            Purchases will only be considered if
                                            bought from the stores indicated at
                                            the end of this document.
                                        </li>
                                        <li className="mb-2">
                                            Stokvel members will only earn cash
                                            back when submitting their valid
                                            till slips. A till slip will be only
                                            be considered valid if the date on
                                            till slip is within campaign period
                                            (17 November 2022 - 31 January 2023)
                                            and the till slip is readable.
                                        </li>
                                        <li className="mb-2">
                                            All till slips are required to be
                                            uploaded to the
                                            www.unilevershopper.co.za by no
                                            later than 15 February in order to
                                            qualify for the cashback campaign.
                                        </li>
                                        <li className="mb-2">
                                            Till slips reflecting purchases made
                                            outside the campaign period will not
                                            be accepted.
                                        </li>
                                        <li className="mb-2">
                                            Unclear till slips, quotes,
                                            duplicate till slips, or sales
                                            orders will not be accepted/will not
                                            qualify to earn points.
                                        </li>
                                        <li className="mb-2">
                                            Once the till slip has been uploaded
                                            it can take up to 5 working days to
                                            be processed.
                                        </li>
                                        <li className="mb-2">
                                            Participants must keep their till
                                            slip as proof of purchase.
                                        </li>
                                        <li className="mb-2">
                                            The Stokvel groups that qualify for
                                            cash back will be notified via SMS
                                            on how to claim their cash back.
                                        </li>
                                        <li className="mb-2">
                                            All cash back earned is not
                                            exchangeable or transferable.
                                        </li>
                                        <li className="mb-2">
                                            All cashback campaign payouts will
                                            only take place once the campaign
                                            period is complete (after 15 January
                                            2022).
                                        </li>
                                        <li className="mb-2">
                                            All payouts will take place by no
                                            later than 19 March 2022.
                                        </li>
                                    </ol>
                                </li>
                                <li className="mb-2">
                                    A copy of these rules can be found on the
                                    following website www.unilevershopper.co.za
                                    throughout the rewards period or can be
                                    obtained from the Consumer Call Centre on
                                    telephone number +27 31 570 2000.
                                </li>
                                <li className="mb-2">
                                    The Promoter shall not be responsible for
                                    any lost, damaged, delayed, incorrect or
                                    incomplete sign ups or till slip uploads for
                                    any reason whatsoever. Proof of upload will
                                    be accepted as proof of receipt.
                                </li>
                                <li className="mb-2">
                                    The Promoter shall not be responsible for
                                    the failure of any technical element
                                    relating to this program that may result in
                                    a till slip not being successfully
                                    submitted.
                                </li>
                                <li className="mb-2">
                                    Responsibility is not accepted for till
                                    slips lost, damaged or delayed as a result
                                    of any network, computer or cell phone
                                    hardware or software failure of any kind.
                                    Proof of sending will not be accepted as
                                    proof of receipt.
                                </li>
                                <li className="mb-2">
                                    The Promoter reserves the right to
                                    disqualify any claim if fraud or cheating is
                                    suspected, including without limitation,
                                    through the manipulation of code or
                                    otherwise frequently falsifying data.
                                </li>
                                <li className="mb-2">
                                    No applications from agents, third parties,
                                    organised groups or applications
                                    automatically generated by computer will be
                                    accepted. No incomplete or corrupted entries
                                    will be accepted. Entries will only be
                                    accepted if they comply with all entry
                                    instructions.
                                </li>
                                <li className="mb-2">
                                    NOTE: Any attempt to use multiple e-mail,
                                    cell phone or Facebook accounts or other
                                    tactics to sign up or upload more than the
                                    stated limit may result in disqualification
                                    and all associated entries and/or rewards
                                    will be void. Signups or uploads generated
                                    by script, macro, bot, commercial contest
                                    subscription, incentives or other means not
                                    sponsored by the Promoter, vote-swapping
                                    sites, voting software, entering service
                                    sites or any other automated means and
                                    entries or votes by any means that subvert
                                    the entry/voting process or do not conform
                                    to the terms or spirit of these competition
                                    rules, will void the entry/votes and may
                                    disqualify the entrant.
                                </li>
                                <li className="mb-2">
                                    The Promoter reserves the right to use the
                                    images taken of the Rewarded for publicity
                                    purposes in any manner they deem fit,
                                    without remuneration being made payable to
                                    the Rewarded. However, the rewarded has the
                                    right to object to these images being used
                                    by written notification to the Promoter at
                                    15 Nollsworth Crescent, Nollsworth Park, La
                                    Lucia, 4051 Att: Legal Department.
                                </li>
                                <li className="mb-2">
                                    The Promoter shall have the right to change
                                    or terminate the programme competition
                                    immediately and without notice, if deemed
                                    necessary in its opinion and if
                                    circumstances arise outside of their
                                    control. In the event of such change or
                                    termination, all participants agree to waive
                                    any rights that they have in respect of this
                                    programme competition and acknowledge that
                                    they will have no recourse against the
                                    Promoter or its agents.
                                </li>
                                <li className="mb-2">
                                    In the event that the Reward is not
                                    available despite the Promoter's reasonable
                                    endeavours to procure the Reward the
                                    Promoter reserves the right to substitute
                                    the Reward of equal value.
                                </li>
                                <li className="mb-2">
                                    Neither the Promoter, its agents, its
                                    associated companies, nor any directors,
                                    officers nor employees of such, shall be
                                    liable for any loss or damage, whether
                                    direct, indirect, consequential or otherwise
                                    arising from any cause whatsoever, which may
                                    be suffered by the participant or the
                                    Rewarded.
                                </li>
                                <li className="mb-2">
                                    Participation in the rewards program
                                    constitutes acceptance of the program
                                    competition rules and participants agree to
                                    abide by the rules.
                                </li>
                                <li className="mb-2">
                                    This program is in no way sponsored,
                                    endorsed, or administered by, or associated
                                    with Facebook and the participants
                                    acknowledge that they are providing
                                    information to the Promoter and its agents
                                    only and not to Facebook.
                                </li>
                                <li className="mb-2">
                                    All participants in this Competition release
                                    Facebook from all liabilities and claims
                                    arising out of or in connection with this
                                    competition and these terms and conditions.
                                </li>
                                <li className="mb-2">
                                    This reward program is in no way sponsored,
                                    endorsed or administered by, or associated
                                    with Telkom, MTN, Vodacom and Cell C and the
                                    participants acknowledge that they are
                                    providing information to the Promoter and
                                    its agents only and not to telephone
                                    networks.
                                </li>
                                <li className="mb-2">
                                    All participants in this competition release
                                    Telkom, MTN, Vodacom and Cell C from all
                                    liabilities and claims arising out of or in
                                    connection with this competition and these
                                    terms and conditions.
                                </li>
                                <li className="mb-2">
                                    The Promoter reserves the right to delete
                                    any information uploaded by a participant
                                    which the Promoter, in its absolute
                                    discretion, believes is illegal, obscene,
                                    offensive or defamatory, or infringes the
                                    intellectual property rights of a third
                                    party, or is otherwise inappropriate for a
                                    campaign designed for mass audience
                                    entertainment.
                                </li>
                                <li className="mb-2">
                                    Participants warrant that they have full
                                    rights to the images and stories that they
                                    upload and that the use of the said material
                                    shall not constitute a breach of any third
                                    party's copyright or other intellectual
                                    property rights.
                                </li>
                                <li className="mb-2">
                                    Unilever or Shopper Marketing will not be
                                    held liable for any downtime of the platform
                                    that effects till slip uploads or loss of
                                    rewards.
                                </li>
                            </ol>

                            <h2 className="mb-3">Store List</h2>

                            <ul>
                                <li className="mb-2">
                                    1 Up Cash and Carry Paarl
                                </li>
                                <li className="mb-2">1 Up Mega Store Epping</li>
                                <li className="mb-2">
                                    A1 Supermarket Polokwane
                                </li>
                                <li className="mb-2">
                                    Adami Multisave Cradock
                                </li>
                                <li className="mb-2">
                                    Advance Wholesalers Hermanstad
                                </li>
                                <li className="mb-2">
                                    Afri Save Cash and Carry Uitenhage
                                </li>
                                <li className="mb-2">
                                    Africa Cash and Carry Crown Mines
                                </li>
                                <li className="mb-2">AH Grocers Durban</li>
                                <li className="mb-2">
                                    Aheers Multisave Greytown
                                </li>
                                <li className="mb-2">
                                    Aheers Powertrade Cash and Carry Greytown
                                </li>
                                <li className="mb-2">Alcor Groothandelaars</li>
                                <li className="mb-2">ANE Klerksdorp</li>
                                <li className="mb-2">
                                    Arcade Wholesalers Rustenburg
                                </li>
                                <li className="mb-2">
                                    Arrow Cash and Carry Durban
                                </li>
                                <li className="mb-2">
                                    Ash Cash and Carry Makhado
                                </li>
                                <li className="mb-2">
                                    Bargain Wholesale Port Shepstone
                                </li>
                                <li className="mb-2">
                                    Batho Cash and Carry Koster
                                </li>
                                <li className="mb-2">
                                    Bibi Cash and Carry Supermarket
                                </li>
                                <li className="mb-2">
                                    Big Daddys Cash and Carry Berea East London
                                </li>
                                <li className="mb-2">
                                    Big Daddys Cash and Carry Buffalo Street
                                </li>
                                <li className="mb-2">
                                    Big Daddys Cash and Carry Fort Beaufort
                                </li>
                                <li className="mb-2">
                                    Big Daddys Cash and Carry King Williams Town
                                </li>
                                <li className="mb-2">
                                    Big Daddys Cash and Carry Queenstown
                                </li>
                                <li className="mb-2">
                                    Big Daddys Cash and Carry Stutterheim
                                </li>
                                <li className="mb-2">
                                    Big Daddys Cash and Carry Wilsonia East
                                    London
                                </li>
                                <li className="mb-2">Big Save Hammanskraal</li>
                                <li className="mb-2">Big Save Lebombo</li>
                                <li className="mb-2">Big Save Mabopane</li>
                                <li className="mb-2">
                                    Big Save Marble Hall Hyper
                                </li>
                                <li className="mb-2">Big Save Marikana</li>
                                <li className="mb-2">Big Save Waltloo</li>
                                <li className="mb-2">
                                    Big Save Wholesalers Mabopane
                                </li>
                                <li className="mb-2">
                                    Brickfield Food Market Overport
                                </li>
                                <li className="mb-2">
                                    Brothers Cash and Carry Mafikeng
                                </li>
                                <li className="mb-2">
                                    Browns Cash &amp; Carry Bizana
                                </li>
                                <li className="mb-2">
                                    Browns Cash &amp; Carry Eshowe
                                </li>
                                <li className="mb-2">
                                    Browns Cash &amp; Carry Lusikisiki
                                </li>
                                <li className="mb-2">
                                    Browns Cash &amp; Carry Matatiele High
                                    Street
                                </li>
                                <li className="mb-2">
                                    Browns Cash &amp; Carry Nongoma
                                </li>
                                <li className="mb-2">
                                    Browns Cash &amp; Carry Nqutu
                                </li>
                                <li className="mb-2">
                                    Browns Cash and Carry Matatiele Station
                                    Street
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Baragwanath
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Bloed Street
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Botshabelo
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Burgersfort - New store
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Carletonville
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Cosmo City
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Crossing
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Emalahleni
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Empangeni
                                </li>
                                <li className="mb-2">Cambridge Food Eshowe</li>
                                <li className="mb-2">Cambridge Food Evaton</li>
                                <li className="mb-2">
                                    Cambridge Food Hillbrow
                                </li>
                                <li className="mb-2">Cambridge Food Jozini</li>
                                <li className="mb-2">
                                    Cambridge Food Kimberley
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Klerksdorp
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Kwa Mashu
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Ladysmith
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Lemo Mall
                                </li>
                                <li className="mb-2">Cambridge Food Manguzi</li>
                                <li className="mb-2">
                                    Cambridge Food Mitchells Plain
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Nelspruit
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Newcastle
                                </li>
                                <li className="mb-2">Cambridge Food Newgate</li>
                                <li className="mb-2">Cambridge Food Nongoma</li>
                                <li className="mb-2">Cambridge Food Nqutu</li>
                                <li className="mb-2">
                                    Cambridge Food Pietermaritzburg
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Pinetown
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Randburg
                                </li>
                                <li className="mb-2">Cambridge Food Rank</li>
                                <li className="mb-2">
                                    Cambridge Food Richards Bay
                                </li>
                                <li className="mb-2">Cambridge Food Seshego</li>
                                <li className="mb-2">Cambridge Food Tembisa</li>
                                <li className="mb-2">
                                    Cambridge Food Thaba Nchu
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Umgeni Road
                                </li>
                                <li className="mb-2">
                                    Cambridge Food Vosloorus
                                </li>
                                <li className="mb-2">
                                    Cambridge Food West Street
                                </li>
                                <li className="mb-2">
                                    Cambridge Link Pinetown
                                </li>
                                <li className="mb-2">Cambridge Mtubatuba</li>
                                <li className="mb-2">Cambridge Nkandla</li>
                                <li className="mb-2">Check In Vredendal</li>
                                <li className="mb-2">Check Me Stanger</li>
                                <li className="mb-2">
                                    Checkmart Crescent Overport
                                </li>
                                <li className="mb-2">
                                    Checkmart Palmview Phoenix
                                </li>
                                <li className="mb-2">
                                    Checkmart Supermarket Kwa Dukuza Stanger
                                </li>
                                <li className="mb-2">Checkmart The Wheel</li>
                                <li className="mb-2">CHECKMART TONGAAT</li>
                                <li className="mb-2">CHECKMART UMKOMAAS</li>
                                <li className="mb-2">Checkmart Verulam</li>
                                <li className="mb-2">Checksave Alpine</li>
                                <li className="mb-2">Checksave Boom Street</li>
                                <li className="mb-2">Checksave Brickfield</li>
                                <li className="mb-2">Checksave Bridge City</li>
                                <li className="mb-2">Checksave Brookdale</li>
                                <li className="mb-2">
                                    Checksave China City Durban
                                </li>
                                <li className="mb-2">Checksave City Gate</li>
                                <li className="mb-2">Checksave Dundee</li>
                                <li className="mb-2">Checksave Greytown</li>
                                <li className="mb-2">Checksave Hammersdale</li>
                                <li className="mb-2">Checksave Isipingo</li>
                                <li className="mb-2">Checksave Ladysmith</li>
                                <li className="mb-2">Checksave Merebank</li>
                                <li className="mb-2">Checksave Mooi River</li>
                                <li className="mb-2">Checksave Newlands</li>
                                <li className="mb-2">
                                    Checksave Pietermaritz Street
                                </li>
                                <li className="mb-2">Checksave Queen Street</li>
                                <li className="mb-2">Checksave Raisethorpe</li>
                                <li className="mb-2">Checksave Richmond</li>
                                <li className="mb-2">Checksave Shallcross</li>
                                <li className="mb-2">Checksave Stanger</li>
                                <li className="mb-2">Checksave Starwood</li>
                                <li className="mb-2">Checksave Umlazi</li>
                                <li className="mb-2">Checksave Verulam</li>
                                <li className="mb-2">
                                    Checksave Victoria Street
                                </li>
                                <li className="mb-2">Checksave Vryheid</li>
                                <li className="mb-2">Checksave West Street</li>
                                <li className="mb-2">Checksave Woodhurst</li>
                                <li className="mb-2">Checkstar Durban</li>
                                <li className="mb-2">
                                    Checkstar Mount Edgecombe
                                </li>
                                <li className="mb-2">Checkstar Overport</li>
                                <li className="mb-2">
                                    Choice Supermarket Burgersfort
                                </li>
                                <li className="mb-2">Choppies DC Rustenburg</li>
                                <li className="mb-2">Choppies Hebron</li>
                                <li className="mb-2">
                                    Choppies Hyper Rustenburg
                                </li>
                                <li className="mb-2">
                                    Choppies Jwayelani Witbank
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Bela Bela
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Boitekong
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Boshoek
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Botshabelo
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Brakpan
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Brits CBD
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Bronkhorstspruit
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Burgersfort
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Carletonville
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Carletonville Goldreef
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Cliffys
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Delareyville
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Germiston
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Groblersdal
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Hartswater
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Honeydew
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Jane Furse
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Khutsong
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Klerksdorp
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Koster
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Lephalale
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Lichtenburg
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Mafikeng
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Makhado
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Malelane
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Marikana
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Middelburg MP
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Modimolle
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Mokopane
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Mokopane 2
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Mooi Street Welkom
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Northam
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Odendaalsrus
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Orkney
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Parys
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Phumula
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Polokwane
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Potchefstroom
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Pretoria North
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Rustenburg
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Secunda
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Seshego
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Siyabuswa
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Swartruggens
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Taung
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Thohoyandou
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Vaalwater
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Van Dyk Park
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Vryburg
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Witbank
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Wolmaranstad
                                </li>
                                <li className="mb-2">
                                    Choppies Supermarket Zeerust
                                </li>
                                <li className="mb-2">Coleys Square Deal</li>
                                <li className="mb-2">
                                    Continental Cash and Carry
                                </li>
                                <li className="mb-2">
                                    Cosmetic Connection Botshabelo
                                </li>
                                <li className="mb-2">
                                    Cosmetic Connection Maitland Street
                                </li>
                                <li className="mb-2">
                                    Cosmetic Connection St Andrews Street
                                </li>
                                <li className="mb-2">
                                    Cosmetic Connection Thaba Nchu
                                </li>
                                <li className="mb-2">Derby Supermarket</li>
                                <li className="mb-2">
                                    Desai Cash and Carry Louis Trichardt
                                </li>
                                <li className="mb-2">
                                    Desai Cash and Carry Louis Trichardt 1
                                </li>
                                <li className="mb-2">
                                    Desais Cash and Carry Stanger
                                </li>
                                <li className="mb-2">
                                    Devland Cash and Carry Devland
                                </li>
                                <li className="mb-2">
                                    Devland Cash and Carry Ermelo
                                </li>
                                <li className="mb-2">
                                    Devland Cash and Carry Kokstad
                                </li>
                                <li className="mb-2">
                                    Devland Cash and Carry Nelspruit
                                </li>
                                <li className="mb-2">
                                    Devland Cash and Carry Welkom
                                </li>
                                <li className="mb-2">
                                    Devland Distribution Centre Cape Town
                                </li>
                                <li className="mb-2">
                                    Devland Imphupu Wholesalers Murchison Street
                                </li>
                                <li className="mb-2">
                                    DF Scott Cash &amp; Carry George
                                </li>
                                <li className="mb-2">
                                    Dominoes Supermarket Newcastle
                                </li>
                                <li className="mb-2">Drinks Galore</li>
                                <li className="mb-2">
                                    Ebiesons Cash and Carry Polokwane
                                </li>
                                <li className="mb-2">Econo Foods Ladybrand</li>
                                <li className="mb-2">
                                    Elangeni Powertrade Cash and Carry
                                </li>
                                <li className="mb-2">
                                    Elite Cash and Carry Athlone WC
                                </li>
                                <li className="mb-2">Elite Foodtown</li>
                                <li className="mb-2">
                                    Empangeni Cash and Carry Fourth St
                                </li>
                                <li className="mb-2">
                                    Emsengeni Wholesale Willowvale
                                </li>
                                <li className="mb-2">Endaleni Supermarket</li>
                                <li className="mb-2">
                                    Essas Cash and Carry Polokwane
                                </li>
                                <li className="mb-2">
                                    Evergreen Housewives Market DF Malan Drive
                                </li>
                                <li className="mb-2">
                                    Evergreens Kempton Park
                                </li>
                                <li className="mb-2">
                                    FACTORY SHOP BURGERSFORT
                                </li>
                                <li className="mb-2">
                                    Favours Cash and Carry Phoenix
                                </li>
                                <li className="mb-2">
                                    Ficksburg Cash &amp; Carry
                                </li>
                                <li className="mb-2">
                                    Finro Cash &amp; Carry North End
                                </li>
                                <li className="mb-2">Food Fresh Foodzone</li>
                                <li className="mb-2">Foodline Hyper Laudium</li>
                                <li className="mb-2">Foodtown Hyper</li>
                                <li className="mb-2">
                                    Foodtown Hyper Khayelitsha
                                </li>
                                <li className="mb-2">
                                    Foodtown Hyper Thlabane
                                </li>
                                <li className="mb-2">Foodtown Hyper Vryburg</li>
                                <li className="mb-2">
                                    Freedom Cash and Carry Bosman Street
                                </li>
                                <li className="mb-2">Freedom Foodtown</li>
                                <li className="mb-2">
                                    Frontline Hyper Pretoria (MOKOPANE)
                                </li>
                                <li className="mb-2">Georgies Foodtown</li>
                                <li className="mb-2">
                                    Giant Hyper Brackenfell
                                </li>
                                <li className="mb-2">
                                    Giant Hyperstore Epping
                                </li>
                                <li className="mb-2">
                                    Goal Supermarket Philippi
                                </li>
                                <li className="mb-2">
                                    H &amp; W Verspruiders cc
                                </li>
                                <li className="mb-2">
                                    H&amp;W Verspreiders cc
                                </li>
                                <li className="mb-2">
                                    Hammarsdale Cash and Carry
                                </li>
                                <li className="mb-2">Hammarsdale Hyper</li>
                                <li className="mb-2">
                                    Happy Family Distributors Boksburg
                                </li>
                                <li className="mb-2">
                                    Happy Family Distributors Springs
                                </li>
                                <li className="mb-2">
                                    Happy Family Distributors Witbank
                                </li>
                                <li className="mb-2">Happy Family East Rand</li>
                                <li className="mb-2">Happy Family Mthatha</li>
                                <li className="mb-2">
                                    Hazyview Cash &amp; Carry
                                </li>
                                <li className="mb-2">
                                    Hessels Cash and Carry Queenstown
                                </li>
                                <li className="mb-2">HMG Wholesalers</li>
                                <li className="mb-2">Hyperland Devland</li>
                                <li className="mb-2">Hyperland Hillfox</li>
                                <li className="mb-2">
                                    IBC INKANYEZI C AND C ESHOWE
                                </li>
                                <li className="mb-2">
                                    Idutywa Powersave Idutywa
                                </li>
                                <li className="mb-2">
                                    Ikhwezi Wholesale Eshowe
                                </li>
                                <li className="mb-2">
                                    Ikhwezi Wholesale Melmoth
                                </li>
                                <li className="mb-2">
                                    Ikhwezi Wholesale Ulundi
                                </li>
                                <li className="mb-2">
                                    Inkanyezi Cash and Carry Nkandla
                                </li>
                                <li className="mb-2">
                                    J and E Cash and Carry Pretoria West
                                </li>
                                <li className="mb-2">
                                    J and K Wholesalers Philippi
                                </li>
                                <li className="mb-2">Jabula Wholesalers</li>
                                <li className="mb-2">
                                    Jadwats Wholesalers Isipingo
                                </li>
                                <li className="mb-2">
                                    Jeenas Warehouse Isipingo
                                </li>
                                <li className="mb-2">
                                    Jock Morrison and Sons Jozini
                                </li>
                                <li className="mb-2">
                                    Jock Morrison and Sons Mbazwane
                                </li>
                                <li className="mb-2">
                                    Jock Morrison and Sons Mbazwane 2
                                </li>
                                <li className="mb-2">
                                    Jock Morrison and Sons Mtubatuba
                                </li>
                                <li className="mb-2">
                                    Jock Morrison and Sons Old Main Road
                                    Hluhluwe
                                </li>
                                <li className="mb-2">
                                    Jumbo Cash &amp; Carry Brickhill Road
                                </li>
                                <li className="mb-2">
                                    Jumbo Cash &amp; Carry Ottery
                                </li>
                                <li className="mb-2">
                                    Jumbo Cash &amp; Carry Philippi
                                </li>
                                <li className="mb-2">
                                    Jumbo Cash &amp; Carry Polokwane
                                </li>
                                <li className="mb-2">Jumbo Crown Mines</li>
                                <li className="mb-2">Jumbo East London</li>
                                <li className="mb-2">Jumbo Queenstown</li>
                                <li className="mb-2">
                                    Junk Shop New Republic Street
                                </li>
                                <li className="mb-2">
                                    Justrite Supermarket Phoenix
                                </li>
                                <li className="mb-2">Jwayelani Berea 15</li>
                                <li className="mb-2">
                                    Jwayelani Biyela Empangeni
                                </li>
                                <li className="mb-2">
                                    Jwayelani Bridge City 8
                                </li>
                                <li className="mb-2">
                                    Jwayelani Butchery Hill Street
                                </li>
                                <li className="mb-2">
                                    Jwayelani Butchery Umzinto
                                </li>
                                <li className="mb-2">
                                    Jwayelani Empangeni Rail
                                </li>
                                <li className="mb-2">Jwayelani Estcourt 4</li>
                                <li className="mb-2">Jwayelani Etna 17</li>
                                <li className="mb-2">
                                    Jwayelani Himalaya House
                                </li>
                                <li className="mb-2">Jwayelani Isipingo 12</li>
                                <li className="mb-2">Jwayelani Izingolweni</li>
                                <li className="mb-2">
                                    Jwayelani Lancers Road 23
                                </li>
                                <li className="mb-2">
                                    Jwayelani Morningside Durban Head Office
                                </li>
                                <li className="mb-2">
                                    Jwayelani Pietermaritz Street PMB
                                </li>
                                <li className="mb-2">Jwayelani Pinetown 10</li>
                                <li className="mb-2">
                                    Jwayelani Queen Street 9
                                </li>
                                <li className="mb-2">
                                    Jwayelani Retief St Pietermaritzburg
                                </li>
                                <li className="mb-2">Jwayelani Stanger 28</li>
                                <li className="mb-2">
                                    Jwayelani Victoria Street 5
                                </li>
                                <li className="mb-2">
                                    Jwayelani Warwick Avenue 11
                                </li>
                                <li className="mb-2">
                                    Kharodias Wholesale Greytown
                                </li>
                                <li className="mb-2">
                                    Kharodias Wholesale Kranskop
                                </li>
                                <li className="mb-2">
                                    Kimberley Cash &amp; Carry
                                </li>
                                <li className="mb-2">Kismat Cash and Carry</li>
                                <li className="mb-2">
                                    Kit Kat Cash and Carry Benoni
                                </li>
                                <li className="mb-2">Kit Kat Group Kliptown</li>
                                <li className="mb-2">
                                    Kit Kat Group Pretoria West
                                </li>
                                <li className="mb-2">
                                    Kit Kat Group Silverton
                                </li>
                                <li className="mb-2">
                                    Klerksdorp Cash &amp; Carry
                                </li>
                                <li className="mb-2">Ladysmith Foodzone</li>
                                <li className="mb-2">
                                    Laxmi Cash and Carry Lenasia
                                </li>
                                <li className="mb-2">
                                    Liberty Cash and Carry Ladysmith
                                </li>
                                <li className="mb-2">
                                    Lifestyle Supermarket Bynes
                                </li>
                                <li className="mb-2">Limpopo Cash and Carry</li>
                                <li className="mb-2">
                                    Lusikisiki Cash and Carry
                                </li>
                                <li className="mb-2">M and J Supermarket</li>
                                <li className="mb-2">M Premjee and Son</li>
                                <li className="mb-2">
                                    MA Powertrade Cash and Carry
                                </li>
                                <li className="mb-2">
                                    Macksons Express Ladysmith
                                </li>
                                <li className="mb-2">
                                    Macksons Foodzone Victoria Street Estcourt
                                </li>
                                <li className="mb-2">
                                    Macksons Supermarket Estcourt
                                </li>
                                <li className="mb-2">
                                    Macksons Supermarket Makatakata
                                </li>
                                <li className="mb-2">
                                    Macksons Supermarket Mooiriver
                                </li>
                                <li className="mb-2">
                                    Macksons Supervalue Bergville
                                </li>
                                <li className="mb-2">
                                    Makane Cash &amp; Carry T Junction
                                </li>
                                <li className="mb-2">Makro Alberton</li>
                                <li className="mb-2">Makro Amanzimtoti</li>
                                <li className="mb-2">Makro Bloemfontein</li>
                                <li className="mb-2">Makro Cape Gate</li>
                                <li className="mb-2">Makro Carnival City</li>
                                <li className="mb-2">Makro Centurion</li>
                                <li className="mb-2">Makro Cornubia</li>
                                <li className="mb-2">Makro Crown Mines</li>
                                <li className="mb-2">Makro Germiston</li>
                                <li className="mb-2">Makro Montague Gardens</li>
                                <li className="mb-2">Makro Nelspruit</li>
                                <li className="mb-2">Makro Ottery</li>
                                <li className="mb-2">Makro Pietermaritzburg</li>
                                <li className="mb-2">Makro Polokwane</li>
                                <li className="mb-2">Makro Port Elizabeth</li>
                                <li className="mb-2">Makro Riversands</li>
                                <li className="mb-2">Makro Silver Lakes</li>
                                <li className="mb-2">Makro Springfield</li>
                                <li className="mb-2">Makro Strubens Valley</li>
                                <li className="mb-2">
                                    Makro Vaal Vanderbijlpark
                                </li>
                                <li className="mb-2">Makro Wonderboom</li>
                                <li className="mb-2">Makro Woodmead</li>
                                <li className="mb-2">
                                    Makwa Groothandelaars Springbok
                                </li>
                                <li className="mb-2">
                                    Manaba Bargain Wholesalers
                                </li>
                                <li className="mb-2">
                                    Manguzi Cash &amp; Carry Durban North
                                </li>
                                <li className="mb-2">
                                    Manhattan Stores Pinetown
                                </li>
                                <li className="mb-2">
                                    Matat Wholesaler Matatiele
                                </li>
                                <li className="mb-2">
                                    Max Cash and Carry Estcourt
                                </li>
                                <li className="mb-2">Messina Factory Shop</li>
                                <li className="mb-2">
                                    Metro Food Market Bloemfontein
                                </li>
                                <li className="mb-2">
                                    Metro Fruit and Veg Botshabelo
                                </li>
                                <li className="mb-2">
                                    Mias Wholesale Middelburg MP
                                </li>
                                <li className="mb-2">
                                    Mikeva Cash &amp; Carry Mossel Bay
                                </li>
                                <li className="mb-2">Min Cash Foodtown</li>
                                <li className="mb-2">Moosas Cash and Carry</li>
                                <li className="mb-2">
                                    Moosas Wholesale Harding
                                </li>
                                <li className="mb-2">Moria Store</li>
                                <li className="mb-2">
                                    Mtuba Cash &amp; Carry Mtubatuba
                                </li>
                                <li className="mb-2">
                                    Muscleman Import and Export
                                </li>
                                <li className="mb-2">
                                    Nelspruit Cash &amp; Carry
                                </li>
                                <li className="mb-2">
                                    Newlook Cash and Carry Epping
                                </li>
                                <li className="mb-2">
                                    Northcity Wholesalers Newcastle
                                </li>
                                <li className="mb-2">
                                    Numain Cash and Carry Kimberley
                                </li>
                                <li className="mb-2">NWK Koster</li>
                                <li className="mb-2">
                                    Ohlanga Cash and Carry Kokstad
                                </li>
                                <li className="mb-2">
                                    Otees Wholesalers Lydenburg
                                </li>
                                <li className="mb-2">
                                    Panjivan Cash and Carry Isipingo
                                </li>
                                <li className="mb-2">
                                    Phoenix Cash and Carry Empangeni
                                </li>
                                <li className="mb-2">
                                    Phoenix Cash and Carry Nqutu
                                </li>
                                <li className="mb-2">
                                    Phoenix Cash and Carry Pietermaritzburg
                                </li>
                                <li className="mb-2">
                                    Platinum Cash and Carry Mogwase
                                </li>
                                <li className="mb-2">
                                    Platinum Cash and Carry Zeerust
                                </li>
                                <li className="mb-2">
                                    Polarama Cash and Carry Pedro Street
                                </li>
                                <li className="mb-2">Power Trade Kuruman</li>
                                <li className="mb-2">Power Trade Springbok</li>
                                <li className="mb-2">Power Trade Vryburg</li>
                                <li className="mb-2">
                                    Powertrade Pietermaritzburg
                                </li>
                                <li className="mb-2">
                                    Powertrade Village Supermarket
                                </li>
                                <li className="mb-2">Prazeres Foodzone</li>
                                <li className="mb-2">
                                    Premjee Cash and Carry Tzaneen
                                </li>
                                <li className="mb-2">
                                    Pretoria West Cash &amp; Carry
                                </li>
                                <li className="mb-2">
                                    Privacy NoticeTerms of UseCookie Notice
                                </li>
                                <li className="mb-2">
                                    Qwa Qwa Cash &amp; Carry
                                </li>
                                <li className="mb-2">
                                    R Adami and Son Cradock
                                </li>
                                <li className="mb-2">
                                    Raimondis Trading Robertson
                                </li>
                                <li className="mb-2">
                                    Raimondis Trading Worcester
                                </li>
                                <li className="mb-2">
                                    Reunion Cash and Carry Marianhill
                                </li>
                                <li className="mb-2">
                                    Rhino Cash &amp; Carry Bizana
                                </li>
                                <li className="mb-2">
                                    Rhino Cash &amp; Carry Edendale
                                </li>
                                <li className="mb-2">
                                    Rhino Cash &amp; Carry Harding
                                </li>
                                <li className="mb-2">
                                    Rhino Cash &amp; Carry Kokstad
                                </li>
                                <li className="mb-2">
                                    Rhino Cash &amp; Carry Matatiele
                                </li>
                                <li className="mb-2">
                                    Rhino Cash &amp; Carry Mdantsane
                                </li>
                                <li className="mb-2">
                                    Rhino Cash &amp; Carry Mount Frere
                                </li>
                                <li className="mb-2">
                                    Rhino Cash &amp; Carry Mthatha
                                </li>
                                <li className="mb-2">
                                    Rhino Cash &amp; Carry Port Shepstone
                                </li>
                                <li className="mb-2">
                                    Rhino Cash &amp; Carry Qumbu
                                </li>
                                <li className="mb-2">
                                    Rhino Cash &amp; Carry Sizwe
                                </li>
                                <li className="mb-2">
                                    Rhino Cash &amp; Carry Ulundi
                                </li>
                                <li className="mb-2">
                                    Rhino Cash &amp; Carry Umzimkhulu
                                </li>
                                <li className="mb-2">
                                    Rustenburg Cash &amp; Carry
                                </li>
                                <li className="mb-2">Samys Wholesalers</li>
                                <li className="mb-2">Save Brickfield</li>
                                <li className="mb-2">
                                    Save Cash and Carry Church Street
                                </li>
                                <li className="mb-2">
                                    Save Cash and Carry Howick
                                </li>
                                <li className="mb-2">
                                    Save Hyper Pietermaritzburg
                                </li>
                                <li className="mb-2">
                                    Save You Powertrade Pietermaritzburg
                                </li>
                                <li className="mb-2">
                                    Savemoor Cash and Carry Brakpan
                                </li>
                                <li className="mb-2">Saverite Newlands</li>
                                <li className="mb-2">
                                    Saverite Pietermaritzburg Langalibalele
                                    Street
                                </li>
                                <li className="mb-2">Siyama Cash and Carry</li>
                                <li className="mb-2">Siyaya Cash and Carry</li>
                                <li className="mb-2">Spargs Kokstad</li>
                                <li className="mb-2">Spaza Spaza Estcourt</li>
                                <li className="mb-2">
                                    Spendrite Supermarket Port Shepstone
                                </li>
                                <li className="mb-2">Splash Saverite</li>
                                <li className="mb-2">
                                    Springbok Discount Meat
                                </li>
                                <li className="mb-2">
                                    Springs Cash &amp; Carry
                                </li>
                                <li className="mb-2">Strand Cash and Carry</li>
                                <li className="mb-2">
                                    Sunshine Powersave Electron Street
                                </li>
                                <li className="mb-2">
                                    Sunshine Powersave Plaza
                                </li>
                                <li className="mb-2">Sunshine Westgate</li>
                                <li className="mb-2">Supa Save Burgersfort</li>
                                <li className="mb-2">Supa Save Polokwane</li>
                                <li className="mb-2">
                                    Supa Save Powertrade Yeoville
                                </li>
                                <li className="mb-2">
                                    Super Jumbo Bloemfontein
                                </li>
                                <li className="mb-2">Super Jumbo Epping</li>
                                <li className="mb-2">
                                    Super Value Supermarket Estcourt
                                </li>
                                <li className="mb-2">
                                    Superb Cash and Carry Stanger 17 Smithers
                                    Road
                                </li>
                                <li className="mb-2">SuperSave Foodtown</li>
                                <li className="mb-2">
                                    Supersave Powertrade Springs Marconi St
                                </li>
                                <li className="mb-2">
                                    Supersave Powertrade Springs Watt Road
                                </li>
                                <li className="mb-2">Tag Cash and Carry</li>
                                <li className="mb-2">Take n Pay Chatsworth</li>
                                <li className="mb-2">
                                    Take n Pay Village Mall Amanzimtoti
                                </li>
                                <li className="mb-2">TFS Bloemfontein DC</li>
                                <li className="mb-2">TFS Wholesale Dundee</li>
                                <li className="mb-2">
                                    TFS Wholesale Ladysmith
                                </li>
                                <li className="mb-2">TFS Wholesale Qwa Qwa</li>
                                <li className="mb-2">TFS Wholesale Vryheid</li>
                                <li className="mb-2">
                                    Thembani Cash and Carry Nqamakwe
                                </li>
                                <li className="mb-2">
                                    Three Star Cash and Carry Rustenburg
                                </li>
                                <li className="mb-2">Thrupps Illovo Centre</li>
                                <li className="mb-2">
                                    Tradevalue Cash and Carry Korsten
                                </li>
                                <li className="mb-2">
                                    Transito Cash and Carry Welkom
                                </li>
                                <li className="mb-2">
                                    Triangle Stores Highflats
                                </li>
                                <li className="mb-2">
                                    Trident Cash &amp; Carry Mokopane
                                </li>
                                <li className="mb-2">
                                    Tzaneen Cash &amp; Carry
                                </li>
                                <li className="mb-2">
                                    Unika Groothandelaars Station Road
                                </li>
                                <li className="mb-2">
                                    Up To Date Powertrade Vredendal
                                </li>
                                <li className="mb-2">
                                    Upington Cash &amp; Carry
                                </li>
                                <li className="mb-2">
                                    Vryburg Cash &amp; Carry
                                </li>
                                <li className="mb-2">
                                    Vryheid Cash &amp; Carry
                                </li>
                                <li className="mb-2">Warrich Wholesale</li>
                                <li className="mb-2">
                                    Weirs Cash &amp; Carry Butterworth
                                </li>
                                <li className="mb-2">
                                    Weirs Cash &amp; Carry Engcobo
                                </li>
                                <li className="mb-2">
                                    Weirs Cash &amp; Carry Idutywa
                                </li>
                                <li className="mb-2">
                                    Weirs Cash &amp; Carry King Williams Town
                                </li>
                                <li className="mb-2">
                                    Weirs Cash &amp; Carry Maclear
                                </li>
                                <li className="mb-2">
                                    Weirs Cash &amp; Carry Mount Frere
                                </li>
                                <li className="mb-2">
                                    Weirs Cash &amp; Carry Umtata
                                </li>
                                <li className="mb-2">
                                    Welkom Cash &amp; Carry
                                </li>
                                <li className="mb-2">
                                    Westmead Cash &amp; Carry
                                </li>
                                <li className="mb-2">
                                    Yarona Cash and Carry Crown Mines
                                </li>
                                <li className="mb-2">York Cash and Carry</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
