// Packages
import axios from 'axios';
import { retrieveToken } from './retrieveToken';

const Api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 50000,
    crossDomain: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

// Handle pre-request logic.
Api.interceptors.request.use(
    function (config) {
        const token = retrieveToken();
        const authHeader = getAuthHeader(token ? token : false);

        if (!authHeader) {
            return config;
        } else {
            return {
                ...config,
                headers: {
                    ...config.headers,
                    ...authHeader,
                },
            };
        }
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Handle unsuccessful RESPONSES
Api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            sessionStorage.clear();
            localStorage.clear();
        }
        return Promise.reject(error);
    }
);

function getAuthHeader(token = false) {
    if (token) {
        return {
            Authorization: `Bearer ${token}`,
        };
    } else {
        return false;
    }
}

export default Api;
