// Packages
import React from 'react';
import { Redirect } from 'react-router-dom';

// Helpers
import { retrieveToken } from './retrieveToken';

// Routes
import { STOKVEL_LOGIN, PORTAL_DASHBOARD } from '../routes';

export default function ProtectedRoute({ Component, isGuest, isPrivate }) {
    const accessToken = retrieveToken();

    if (isGuest) {
        if (accessToken) {
            return <Redirect to={PORTAL_DASHBOARD} />;
        }
    }

    if (isPrivate) {
        if (!accessToken) {
            return <Redirect to={STOKVEL_LOGIN} />;
        }
    }

    return <Component />;
}
