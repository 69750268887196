// Routes
import {
    LEADERBOARD,
    MYSTORE_INDEX,
    MYSTORE_CREATE,
    PRINT_ADS,
    PRODUCT_HIGHLIGHTS,
    TESTIMONIALS,
    CONTACT,
    PORTAL_DASHBOARD,
    PORTAL_TILLSLIP_UPLOAD,
    PORTAL_USER_PROFILE_EDIT,
    PORTAL_USER_PASSWORD_EDIT,
    PORTAL_REFER_A_FRIEND,
    CMS_DASHBOARD,
    CMS_AIRTIME_CREATE,
    CMS_STOKVEL_INDEX,
    CMS_TRADER_INDEX,
    CMS_TESTIMONIAL_INDEX,
    CMS_TILLSLIP_INDEX,
    CMS_SYSTEM_OVERVIEW,
    TRADER_BUNDLES_AND_PROMOTIONS,
    HOME,
    STOKVEL_REGISTER,
    STOKVEL_LOGIN,
    TRADER_REGISTER,
    TRADER_LOGIN,
    AUTH_LOGOUT,
} from '../../routes';

export const HomeNavbarItems = [
    {
        route: HOME,
        title: 'Home',
    },
];

export const RegisterLoginNavbarItems = [
    {
        route: STOKVEL_REGISTER,
        title: 'Register as Stokvel',
    },
    {
        route: STOKVEL_LOGIN,
        title: 'Login as Stokvel',
    },
    {
        route: TRADER_REGISTER,
        title: 'Register as Trader',
    },
    {
        route: TRADER_LOGIN,
        title: 'Login as Trader',
    },
];

export const LogoutNavbarItems = [
    {
        route: AUTH_LOGOUT,
        title: 'Logout',
    },
];

export const UserDashboardNavbarItems = [
    {
        route: PORTAL_DASHBOARD,
        title: 'User Dashboard',
    },
];

export const FrontendNavbarItems = [
    {
        route: LEADERBOARD,
        title: 'Leaderboard',
    },
    {
        route: PRINT_ADS,
        title: 'Print Ads',
    },
    {
        route: PRODUCT_HIGHLIGHTS,
        title: 'Product Highlights',
    },
    {
        route: TESTIMONIALS,
        title: 'Testimonials',
    },
    {
        route: TRADER_BUNDLES_AND_PROMOTIONS,
        title: "Trader's Bundles and Promotions",
    },
    {
        route: MYSTORE_INDEX,
        title: 'MyStore',
    },
    {
        route: MYSTORE_CREATE,
        title: 'Contact MyStore',
    },
    {
        route: CONTACT,
        title: 'Contact Unilever',
    },
];

export const PortalNavbarItems = [
    {
        route: PORTAL_DASHBOARD,
        title: 'User Dashboard',
    },
    {
        route: PORTAL_USER_PROFILE_EDIT,
        title: 'Update Profile',
    },
    {
        route: PORTAL_USER_PASSWORD_EDIT,
        title: 'Update Password',
    },
    {
        route: PORTAL_TILLSLIP_UPLOAD,
        title: 'Upload Till Slip',
    },
    {
        route: PORTAL_REFER_A_FRIEND,
        title: 'Refer A Friend',
    },
];

export const AdminNavbarItems = [
    {
        route: CMS_DASHBOARD,
        title: 'CMS Dashboard',
    },
    {
        route: CMS_SYSTEM_OVERVIEW,
        title: 'System Overview',
    },
    {
        route: CMS_AIRTIME_CREATE,
        title: 'Airtime',
    },
    {
        route: CMS_STOKVEL_INDEX,
        title: 'Stokvels',
    },
    {
        route: CMS_TESTIMONIAL_INDEX,
        title: 'Testimonials',
    },
    {
        route: CMS_TILLSLIP_INDEX,
        title: 'Till Slips',
    },
    {
        route: CMS_TRADER_INDEX,
        title: 'Traders',
    },
];
