// Packages
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Hero from '../../../components/Hero';
import Title from '../../../components/Title';
import Navbar from '../../../components/Navbar';

// Images
import HeroDesktop from '../../../assets/img/page-banners/banner-404-desktop.png';
import HeroMobile from '../../../assets/img/page-banners/banner-404-mobile.png';
import AboutImage from '../../../assets/img/home/about.png';

export default function PageNotFoundPage() {
    return (
        <>
            <Helmet>
                <title>Page Not Found | Unilever Shopper</title>
                <body className="stokvel-app" />
            </Helmet>

            <Navbar />

            <section id="404">
                <Hero
                    title="Page Not Found"
                    heroMobile={HeroMobile}
                    heroDesktop={HeroDesktop}
                />

                <section className="container my-5">
                    <div className="row">
                        <div className="col-12">
                            <Title title="Page Not Found" />

                            <p className="text-center">
                                Sorry, this page could not be found.
                            </p>

                            <div className="text-center">
                                <img
                                    src={AboutImage}
                                    alt=""
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}
