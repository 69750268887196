// Packages
import React from 'react';

export default function Covid() {
    return (
        <div className="bg-white text-black">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center py-3 small fw-light">
                        <p className="mb-0">
                            For the latest on COVID-19, visit the official
                            Government website:{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://sacoronavirus.co.za/"
                                className="text-black"
                            >
                                sacoronavirus.co.za
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
